import { useEffect, useRef, useLayoutEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import { Stack, Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

// Initialize Highcharts modules
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

const ColumnLineChart = (props) => {
  // const [isClicked, setisClicked] = useState(false);
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));
  // console.log(
  //   "props.baseLineData[0]: ",
  //   props.baseLineData[0]?.incremental_unit
  // );
  // console.log("props.baselineseries: ", props.baseLineSeries);

  const [divHeight, setDivHeight] = useState("");
  // console.log("divHeight column line chart: ", props.divHeight);

  const [isClicked, setisClicked] = useState(false);

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const divRef = useRef(null);
  const iconRef = useRef(null);

  useLayoutEffect(() => {
    const updateDivHeight = () => {
      if (divRef.current) {
        const newHeight = divRef.current.clientHeight;
        setDivHeight(newHeight);
        // console.log("new height: ", newHeight);
      }
    };

    updateDivHeight(); // Initial update
    window.addEventListener("resize", updateDivHeight);

    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, []);

  function colors(index) {
    if (index === 2) {
      return "#f97316";
    } else if (index === 0) {
      return "#d6ff41";
    } else if (index === 1) {
      return "#457474";
    }
  }

  function zIndex(index) {
    if (index === 0) {
      return 3;
    } else if (index === 2) {
      return 9;
    }
  }

  useEffect(() => {
    Highcharts.chart(divRef.current, {
      chart: {
        zoomType: "xy",
        // backgroundColor: "#1c2427",
        events: {
          load: function () {
            const chart = this;
            const icon = chart.renderer
              .html(
                props.baseLineData[0]?.notes !== "" && false  &&
                  '<div style="display: flex; justify-content: center; align-items: center; font-style: italic; border: 1px solid #d6ff41; background-color: black; color: #d6ff41; font-size: 0.75rem; width: 15px; height: 15px;  border-radius: 50%">i</div>',
                this.plotLeft,
                this.plotTop + -25
              )
              .attr({
                zIndex: 5,
              })
              .add();
            icon.element.onmouseover = (e) => {
              setTooltipVisible(true);
              const rect = e.target.getBoundingClientRect();
              setTooltipPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
              });
            };
            icon.element.onmouseout = () => setTooltipVisible(false);
            iconRef.current = icon.element;
          },
        },

        backgroundColor: "black",
        // height: props.divHeight,
        resetZoomButton: {
          theme: {
            fill: "",
            stroke: "#d6ff41",
            style: {
              color: "white",
            },
          },
        },
      },
      yAxis: {
        min: null,
        title: {
          text: props.baseLineData[0]?.ytitle,
          style: {
            color: "#FFFFFF", // Set color of y-axis ticks
          },
        },
        gridLineWidth: 0,
        labels: {
          style: {
            color: "#FFFFFF", // Set color of y-axis ticks
          },
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: "",
        align: "left",
        style: {
          color: "#FFFFFF", // Color for the chart title
        },
      },
      subtitle: {
        // Add your subtitle if needed
      },
      xAxis: {
        categories: props.baseLineData[0]?.xAxis.categories,
        // title: props.xtitle,
        crosshair: false,
        labels: {
          style: {
            color:
              localStorage.getItem("CurrentTheme") === "Dark"
                ? "#FFFFFF"
                : "#1C2427",
          },
        },
      },

      // tooltip: {
      //   shared: true,
      //   useHTML: true,
      //   style: {
      //     fontSize: "14px",
      //     fontWeight: "bold",
      //   },
      //   pointFormat:
      //     '<span style="color:black">{series.name}: <b>{point.y}</b></span><br/>',
      //   borderColor: "#ffffff",
      //   backgroundColor: "#ffffff",
      //   borderRadius: 8,
      // },

      tooltip: {
        shared: true,
        useHTML: true,
        followPointer: true,
        followTouchMove: true,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
        },
        formatter: function () {
          let tooltipHtml = `<b>${this.x}</b><br/>`;
          this.points.forEach((point) => {
            // console.log("point: ", point.series.userOptions.tooltip.valueSuffix);
            if (point.y !== 0) {
              tooltipHtml +=
                '<span style="color:black">' +
                point.series.name +
                ": <b>" +
                point.y +
                " " +
                point.series.userOptions.tooltip.valueSuffix +
                "</b></span><br/>";
            }
          });
          return tooltipHtml;
        },
        borderColor: "#ffffff",
        backgroundColor: "#ffffff",
        borderRadius: 8,
      },

      legend: {
        enabled: true,
        layout: "horizontal", // Set the layout to vertical
        align: "right", // Align the legend to the right
        verticalAlign: "top", // Center the legend vertically
      },
      plotOptions: {
        column: {
          borderColor: "none",
          color: "#d6ff41",
        },
        spline: {
          color: "blue",
        },
      },
      series: props.baseLineSeries?.map((series, index) => ({
        name: series.name,
        type: series.type,
        data: series.data,
        zIndex: zIndex(index),
        tooltip: series.tooltip,
        color: colors(index),
        stickyTracking: false,
      })),
    });
  }, [props.baseLineData, props.baseLineSeries, isClicked]);

  return (
    <div
      style={{
        // border:"2px solid red",
        height: "100%",
        // paddingTop:"px"
      }}
      onClick={() => {
        // setisClicked(!isClicked)
      }}
    >
      {(isMedium || isSmall) && (
        <div
          style={{ textAlign: "center", color: "white" }}
          onClick={() => {
            setisClicked(!isClicked);
          }}
        >
          {" "}
          {isClicked ? <ZoomInIcon /> : <ZoomOutIcon />}{" "}
        </div>
      )}
      <div
        style={{
          //   backgroundColor:'red',
          // border:"1px solid red",

          height: "100%",
          overflowx:
            isClicked && (isSmall || isMedium)
              ? "auto"
              : "" /* Enable horizontal scrollbar */,
          overflowy:
            isClicked && (isSmall || isMedium)
              ? "hidden"
              : "" /* Hide vertical scrollbar */,
          whitespace:
            isClicked && (isSmall || isMedium)
              ? "nowrap"
              : "" /* Prevent line breaks */,
          overflow: isSmall || isMedium ? "scroll" : "",
        }}
      >
        <div
          id="container"
          ref={divRef}
          style={{
            height: "100%",
            width: isClicked && (isSmall || isMedium) ? "700px" : "",
            overflow: isClicked && (isSmall || isMedium) ? "scroll" : "",
            // border:"2px solid green"
          }}
        ></div>
        {tooltipVisible && (
          <div
            style={{
              position: "absolute",
              backgroundColor: "#1c2427",
              padding: "10px",
              borderRadius: "5px",
              fontSize: "0.9rem",
              color: "white",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              top: tooltipPosition.top - 90,
              left: tooltipPosition.left,
              zIndex: 10,
              width: 300,
            }}
            dangerouslySetInnerHTML={{ __html: props.baseLineData[0]?.notes }}
          />
        )}
      </div>
    </div>
  );
};

export default ColumnLineChart;
