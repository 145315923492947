import { useState } from "react";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Box, Typography } from "@mui/material";
import ArrowUpwardSharpIcon from "@mui/icons-material/ArrowUpwardSharp";
import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";

const TreeNode = ({
  node,
  activeNodeId,
  setActiveNodeId,
  global,
  setAllChannelsFun,
  setOptSummary,
  setChartData
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const activeNodeColor = () => {
    if (node.data.id === activeNodeId) {
      // return "#D6FF41";
      return "#d6ff41";
    } else {
      return "#94a3b8";
    }
  };

  const getDataById = (id, data) => {
    for (const item of data) {
      if (item.data.id === id) {
        // console.log("chart dict", item.optchartdict);
        setAllChannelsFun(item.data);
        setChartData(item.optchartdict);
        setOptSummary(item.optsummarydict);
        return;
      }
      if (item.data.sublevel) {
        const result = getDataById(id, item.data.sublevel);
        if (result) return result;
      }
    }
  };

  const toggleExpand = (id) => {
    getDataById(id, global);
    setIsExpanded(!isExpanded);
    setActiveNodeId(id);
  };

  const renderSublevels = (sublevels) => {
    if (sublevels.length === 0) {
      return null;
    }

    return (
      <ul>
        {sublevels.map((subnode) => (
          <TreeNode
            key={subnode.data.id}
            node={subnode}
            activeNodeId={activeNodeId}
            setActiveNodeId={setActiveNodeId}
            global={global}
            setAllChannelsFun={setAllChannelsFun}
            setChartData={setChartData}
            setOptSummary={setOptSummary}
          />
        ))}
      </ul>
    );
  };

  const hasSublevels = node.data.sublevel && node.data.sublevel.length > 0;
  const icon = isExpanded ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />;
  const leafNodeIcon = null;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {hasSublevels ? (
          <span
            style={{ color: activeNodeColor() }}
            onClick={() => toggleExpand(node.data.id)}
          >
            {icon}
          </span>
        ) : (
          <span
            style={{ color: activeNodeColor() }}
            onClick={() => toggleExpand(node.data.id)}
          >
            {leafNodeIcon}
          </span>
        )}
        <Typography
          variant="h6"
          fontWeight={600}
          sx={{ color: activeNodeColor(), letterSpacing: 1 }}
          onClick={() => toggleExpand(node.data.id)}
        >
          {/* {node.data.name}&nbsp;{`(${node.data.growth_per}%)`} */}
          <div>
            {node.data.name}&nbsp;
            <span
              style={{
                color: node.data.growth_per > 0 ? "rgb(3, 255, 3)" : "red",
              }}
            >
              {`(${node.data.growth_per?.toFixed(1)}%`}
              {node.data.growth_per > 0 ? (
                <ArrowUpwardSharpIcon style={{ fontSize: 15 }} />
              ) : (
                <ArrowDownwardSharpIcon style={{ fontSize: 15 }} />
              )}
              {")"}
            </span>
          </div>
        </Typography>
      </Box>
      {isExpanded && renderSublevels(node.data.sublevel)}
    </>
  );
};

const Tree = ({ hierarchy, setAllChannelsFun, setOptSummary, setChartData }) => {
  const [activeNodeId, setActiveNodeId] = useState(hierarchy[0]?.data.id);
  const [global] = useState(hierarchy);

  return (
    <Box flexGrow={1}>
      {hierarchy?.map((node) => (
        <TreeNode
          key={node.data.id}
          node={node}
          activeNodeId={activeNodeId}
          setActiveNodeId={setActiveNodeId}
          global={global}
          setAllChannelsFun={setAllChannelsFun}
          setChartData={setChartData}
          setOptSummary={setOptSummary}
        />
      ))}
    </Box>
  );
};

export default Tree;
