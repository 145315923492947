import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import CustomEvents from "highcharts-custom-events";
// import HighchartsGroupedCategories from "highcharts-grouped-categories";
// HighchartsGroupedCategories(Highcharts)
// CustomEvents(Highcharts);

const BarChart = (props) => {
  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      backgroundColor: "#1c2427",
      showAxes: true,
      // height: '100%',
    },
    title: {
      text: props.charttitle,
      style: {
        color: "#D6FF41",
      },
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: props.categories,
      crosshair: true,
      title: {
        text: props.xtitle,
        style: {
          color: "#D6FF41",
        },
      },
      labels: {
        style: {
          color: "#FFFFFF",
        },
        // events: {
        //   click: function () {
        //     props.DrillUp(this)
        //   }
        // }
      },
    },
    yAxis: {
      min: 0,
      gridLineColor: '#7d9ca6',
      gridLineDashStyle: 'ShortDash',
      visible: true,
      lineWidth: 1,
      title: {
        text: props.ytitle,
        style: {
          color: "#D6FF41",
        },
      },
      labels: {
        style: {
          color: "#FFFFFF",
        },
      },
    },
    tooltip: {
      headerFormat: '<div style="padding:0;background-color:#ffffff;color:#000000;"><span style="font-size:10px;color:#ffffff;">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="padding:0">{series.name}: </td>' +
        '<td style="padding:0;"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: "</table></div>",
      shared: true,
      useHTML: true,
      borderColor: '#ffffff',
      backgroundColor: '#ffffff',
      borderRadius: 8
    },
    plotOptions: {
      // series: {
      //   point: {
      //     events: {
      //       click: function () {
      //         // console.log(this)
      //         props.DrillDown(this)
      //       }
      //     }
      //   }
      // },
      column: {
        stacking: "normal",
        // stacking: "",
        pointPadding: 0,
        borderWidth: 0,
        // events: {
        //   click: (e) => {
        //     console.log(e)
        //   }
        // }
      },
    },
    legend: {
      enabled: true,
      itemHoverStyle: {
        color: '#ffffff'
      },
      itemStyle: {
        color: '#ffffff'
      },
      symbolRadius: 0
    },
    series: props.series,
    // colors: ['#AA4643', '#4572A7', '#89A54E', '#80699B', '#3D96AE', '#DB843D', '#92A8CD', '#A47D7C', '#B5CA92'],
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          enabled: true,
          symbol: "download",
          symbolStroke: "#ffffff",
          theme: {
            fill: "#1c2427"
          },
          titleKey: "",
          // x:10,
          // y:-20
        }
      }
    }
  };
  return (
    <HighchartsReact highcharts={Highcharts} options={options} {...props} />
  );
};

export default BarChart;