import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Tooltip } from "@mui/material";

const Calendar = ({ startDate, endDate, calenderData, toggle, isSubmit, height, none }) => {
  // console.log("endDate in calender: ", endDate);
  // const currentDate = new Date();
  // console.log("none: ", none);

  const [date, setDate] = useState(endDate);
  // console.log("date: ", date);
  const [selectedDate, setSelectedDate] = useState(null);

  

  // Reload calendar when endDate changes
  useEffect(() => {
    if (isSubmit) {
      setDate(endDate);
    }
  }, [endDate, isSubmit]);

  // const navigateMonth = (change) => {
  //   setDate(new Date(date.getFullYear(), date.getMonth() + change, 1));
  // };

  const navigateMonth = (change) => {
    const newDate = new Date(date.getFullYear(), date.getMonth() + change, 1);
    // Ensure newDate is within the range
    if (newDate >= startDate && newDate <= endDate) {
      setDate(newDate);
    } else if (newDate < startDate) {
      // If newDate is before startDate, set the date to startDate
      setDate(new Date(startDate));
    } else if (newDate > endDate) {
      // If newDate is after endDate, set the date to endDate
      setDate(new Date(endDate));
    }
  };
  
  
  function normalizeToPercentage1(value, minVal, maxVal) {
    return (value / (maxVal - minVal)) * 35;
  }

  function normalizeToPercentage(value, minVal, maxVal) {
    return (value / (maxVal - minVal)) * 40;
  }

  // const selectDate = (day) => {
  //   const selected = new Date(date.getFullYear(), date.getMonth(), day);
  //   if (
  //     !isWithinRange(day) &&
  //     !(startDate <= selected && selected <= endDate)
  //   ) {
  //     setSelectedDate(selected);
  //   }
  // };

  const getEventData = (currentDate) => {
    if (!Array.isArray(calenderData?.datadict)) {
      return null;
    }

    const event = calenderData?.datadict.find(
      (event) => event.date === currentDate
    );
    return event ? event.data : null;
  };

  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  const daysInMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();
  const dayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  const isWithinRange = (day) => {
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const currentDay = new Date(currentYear, currentMonth, day);

    return (
      (startDate <= currentDay && currentDay <= endDate) ||
      (startDate.getDate() === day &&
        startDate.getMonth() === currentMonth &&
        startDate.getFullYear() === currentYear) ||
      (endDate.getDate() === day &&
        endDate.getMonth() === currentMonth &&
        endDate.getFullYear() === currentYear)
    );
  };

  return (
    <Stack
      // gap={2}
      sx={{
        // overflow: "hidden",
        // marginTop: "0.5rem",
        height: height,
        // border: "2px solid red",
        gap: 1
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#3E5056",
          // border: "1px solid red"
          // height: "9%"
        }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "baseline" }}>
          <Box
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => navigateMonth(-1)}
          >
            {"<<"}
          </Box>
          <Typography sx={{ fontSize: "0.85rem", color: "#d6ff41" }}>
            {date.toLocaleDateString("en-us", {
              month: "long",
              year: "numeric",
            })}
          </Typography>
          <Box
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => navigateMonth(1)}
          >
            {">>"}
          </Box>
        </Box>
      </Box>



       {/* main calender with days */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          // border: "1px solid red",
          // height: "90%",
          overflow: "hidden",
        }}
      >
        {dayLabels.map((day, index) => (
          <div
            key={index}
            style={{
              textAlign: "center",
              color: "white",
              fontSize: "0.85rem",
              marginTop: "0.5rem",
            }}
          >
            {day}
          </div>
        ))}
        {[...Array(firstDay)].map((_, index) => (
          <div key={`empty-${index}`} style={{ visibility: "hidden" }}></div>
        ))}
        {days.map((day, index) => {
          const currentDate = `${date.getFullYear()}-${String(
            date.getMonth() + 1
          ).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
          const eventData = getEventData(currentDate);
          // console.log("eventData: ", eventData);
          return (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "0.25rem",
                alignItems: "flex-end",
                textAlign: "center",
                height: none ? "86px" : "60px",
                // border: "1px solid red",
                margin: 1,
                fontSize: "0.75rem",
                color: "white",
                backgroundColor:
                  selectedDate && selectedDate.getDate() === day
                    ? ""
                    : "#3E5056",
                ...(isWithinRange(day) && {
                  backgroundColor: "#3E5056",
                  color: "white",
                }),
              }}
              // onClick={() => selectDate(day)}
            >
              {/* {day} */}
              <Typography sx={{ fontSize: "0.65rem" }}>{day}</Typography>
              <Stack
                // alignItems={"center"}
                justifyContent={"flex-end"}
                sx={{ height: "100%", width: "75%" }}
              >
                {eventData && (
                  <Box
                    sx={{
                      width: "100%",
                      // height: "60px",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      // border: "1px solid red",

                    }}
                  >
                    {toggle ? ( // incremental
                      <Tooltip 
                      enterTouchDelay={0} leaveTouchDelay={3000}
                        // title={`${eventData.disp_name1}: ${eventData.value} ${eventData.units}, ${eventData.disp_name2} \n ${eventData.baseval} ${eventData.units}`}
                        // sx={{border:"2px solid red"}}
                        title={
                          <>
                            <Typography
                              sx={{ fontSize: "0.75rem",
                              // border:"2px solid red"
                             }}
                            >{`${eventData.disp_name1}: ${eventData.value} ${eventData.units}`}</Typography>
                            <Typography
                              sx={{ fontSize: "0.75rem" }}
                            >{`Total: ${(
                              eventData.baseval + eventData.value
                            ).toFixed(2)} ${eventData.units}`}</Typography>
                            <Typography
                              sx={{ fontSize: "0.75rem" }}
                            >{`${eventData.disp_name2}: ${eventData.baseval} ${eventData.units}`}</Typography>
                          </>
                        }
                        arrow
                        placement="right-start"
                      >
                        {/* stack */}
                        {eventData.fillflag === 1 ? (
                          <Box
                            sx={{
                              width: "100%",
                              height: "100%",
                              backgroundColor: "#F6FFE5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "flex-end",
                              position: "relative",
                            }}
                          >
                            <Box
                              sx={{
                                height: "7px",
                                width: "7px",
                                backgroundColor: "#16F325",
                                position: "absolute",
                                top: 2,
                                left: 2,
                              }}
                            ></Box>
                            {/* stack bar started */}
                            <Stack
                              justifyContent={"flex-end"}
                              sx={{
                                width: "60%",
                                // height: "60px",
                                height: "100%",
                                // border: "3px solid tomato"
                              }}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  height: `${normalizeToPercentage(
                                    eventData.value,
                                    calenderData?.minval,
                                    calenderData?.maxval
                                  )}%`,
                                  backgroundColor: eventData.color1,
                                }}
                              ></Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  height: `${normalizeToPercentage(
                                    eventData.baseval,
                                    calenderData?.minval,
                                    calenderData?.maxval
                                  )}%`,
                                  backgroundColor: eventData.color2,
                                }}
                              ></Box>
                            </Stack>
                          </Box>
                        ) : (
                          <Stack
                            justifyContent={"flex-end"}
                            sx={{
                              width: "60%",
                              height: "60px",
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                height: `${normalizeToPercentage(
                                  eventData.value,
                                  calenderData?.minval,
                                  calenderData?.maxval
                                )}%`,
                                backgroundColor: eventData.color1,
                                
                              }}
                            ></Box>
                            <Box
                              sx={{
                                width: "100%",
                                height: `${normalizeToPercentage(
                                  eventData.baseval,
                                  calenderData?.minval,
                                  calenderData?.maxval
                                )}%`,
                                backgroundColor: eventData.color2,
                              }}
                            ></Box>
                          </Stack>
                        )}

                        {/* stack */}
                      </Tooltip>
                    ) : (
                      <Tooltip
                      enterTouchDelay={0} leaveTouchDelay={3000}

                        // title={`${eventData.disp_name1}: ${eventData.value} ${eventData.units}`}
                        title={
                          <>
                            <Typography
                              sx={{ fontSize: "0.75rem" }}
                            >{`${eventData.disp_name1}: ${eventData.value} ${eventData.units}`}</Typography>
                          </>
                        }
                        arrow
                        placement="right-start"
                      >
                        {eventData.fillflag === 1 ? (
                          <Box
                            sx={{
                              width: "100%",
                              // height: "60px",
                              height: "100%",
                              backgroundColor: "#F6FFE5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "flex-end",
                              // border: "1px solid red",
                              position: "relative",
                            }}
                          >
                            <Box
                              sx={{
                                height: "7px",
                                width: "7px",
                                backgroundColor: "#16F325",
                                position: "absolute",
                                top: 2,
                                left: 2,
                              }}
                            ></Box>
                            <Box //  absolute
                              sx={{
                                height: `${normalizeToPercentage1(
                                  eventData.value,
                                  calenderData?.minval,
                                  calenderData?.maxval
                                )}%`,
                                backgroundColor: eventData.color1,
                                width: "60%",
                                // border: "1px solid #3E5056",
                                // border: "1px solid blue",
                                position: "relative",
                              }}
                            ></Box>
                          </Box>
                        ) : (
                          <Box //  absolute
                            sx={{
                              height: `${normalizeToPercentage(
                                eventData.value,
                                calenderData?.minval,
                                calenderData?.maxval
                              )}%`,
                              backgroundColor: eventData.color1,
                              width: "60%",
                              // border: "1px solid #3E5056",
                              // border: "1px solid blue",
                              // position: "relative",
                            }}
                          ></Box>
                        )}
                      </Tooltip>
                    )}
                  </Box>
                )}
              </Stack>
            </div>
          );
        })}
      </div>



      {/* legends */}
      <Stack
        direction={"row"}
        gap={5}
        // justifyContent={"space-around"}
        sx={{ width: "100%"}}
      >
        {calenderData.legend?.map((legend, index) => {
          return (
            <Stack
              direction={"row"}
              gap={1}
              key={index}
              justifyContent={"flex-end"}
            >
              <Box
                sx={{
                  backgroundColor: legend.color,
                  padding: "0.5rem",
                  height: 10,
                  width: 10,
                }}
              ></Box>
              <Typography sx={{ fontSize: "0.75rem", color: "white" }}>
                {legend.name}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default React.memo(Calendar);
