import { Box, useMediaQuery, useTheme } from "@mui/material";
import Header from "./Header";
import Row from "./Row";
import Footer from "./Footer";

const Table = ({
  DATA,
  headerTabNames,
  Length,
  editMode,
  setEditMode,
  setRePayload,
  rePayload,
  fetchCall,
  refresh,
  setRefresh,
  defaultView,
  setChanged,
  setClickSort,
  setSortAlpha,
  toggleStates,
  handleToggleChange,
  clickSort
}) => {
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        overflowY: "scroll",
        overflowX:"scroll",
        // border:"2px solid red",
        width: (isSmall||isMedium)?"1000px": "100%",
        "&::-webkit-scrollbar": {
          width: "4px",
          
        },

        "&::-webkit-scrollbar-track": {
          background: "#1C2427",
        },

        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "gray",
          borderRadius: "10px",
        },
        position: "relative",
      }}
    >
      <Header
        headerTabNames={headerTabNames}
        Length={Length}
        editMode={editMode}
        setEditMode={setEditMode}
        defaultView={defaultView}
        setClickSort={setClickSort}
        setSortAlpha={setSortAlpha}
        handleToggleChange={handleToggleChange}
        toggleStates={toggleStates}
        clickSort={clickSort}

      />

      {DATA &&
        DATA.map((channel) => {
          return (
            <Row
              name={channel.name}
              data={channel.data}
              Length={Length}
              editMode={editMode}
              setRePayload={setRePayload}
              rePayload={rePayload}
              refresh={refresh}
              setRefresh={setRefresh}
              setChanged={setChanged}
              toggleStates={toggleStates}
            />
          );
        })}
      {/* <Footer
        fetchCall={fetchCall}
        setRePayload={setRePayload}
        refresh={refresh}
        setRefresh={setRefresh}
      /> */}
    </Box>
  );
};

export default Table;
