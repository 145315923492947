const getBrandIDandChannelID = (userInputKey, flatObject) => {
  let VALUE; // for user input key --> l-hco-gar-trad-tv
  let brandId; // flatObj[0] l-hco-gar-trad-tv --> l-hco-gar

  Object.keys(flatObject).forEach((item) => {
    if (item === userInputKey) {
      VALUE = flatObject[item];
      brandId = VALUE[0];
    }
  });

  const channelID = userInputKey.replace(`${brandId}-`, "");
//   console.log("Brand ID: ---> ", brandId);
//   console.log("Channel ID: ---> ", channelID);

  return [brandId, channelID];
};

export default getBrandIDandChannelID;
