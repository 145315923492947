import { useState, useEffect } from "react";
import Tree from "react-d3-tree";
import { useCenteredTree } from "./helpers";
import DetailsPopUp from "../../Components/DetailsPopUp";
import Topbar from "../../Components/Topbar";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "./custom-tree.css";
import { CssBaseline } from "@mui/material";

const RenderRectSvgNode = ({ nodeDatum, toggleNode, handleNodeClick }) => {
  return (
    <g>
      <rect
        width="280"
        height="116"
        fill="#282728"
        x="-110"
        y="-58"
        rx="5"
        ry="5"
        style={{
          cursor: nodeDatum.access === 0 ? "not-allowed" : "pointer",
        }}
        onClick={() => {
          handleNodeClick(nodeDatum);
        }}
      />
      <foreignObject
        x="-110"
        y="-49"
        width="100"
        height="100"
        style={{
          cursor: nodeDatum.access === 0 ? "not-allowed" : "pointer",
        }}
        onClick={() => handleNodeClick(nodeDatum)}
      >
        <div className="image-bg" xmlns="http://www.w3.org/1999/xhtml"></div>
      </foreignObject>

      {nodeDatum.img && (
        <image
          onClick={() => handleNodeClick(nodeDatum)}
          href={`${nodeDatum.img}?v=${new Date().getTime()}`} // Replace with the actual path to your image
          // href={pocky}
          width="103" // Set the desired width of the image
          height="90" // Set the desired height of the image
          x="-100" // Adjust the X position as needed
          y="-44" // Adjust the Y position as needed
          // stroke="black"
          // strokeWidth={3}
          alt="logo"
          loading="eager"
          style={{
            cursor: nodeDatum.access === 0 ? "not-allowed" : "pointer",
          }}
        />
      )}
      <foreignObject
        x="20"
        y="-50"
        width="118"
        height="100"
        style={{
          cursor: nodeDatum.access === 0 ? "not-allowed" : "pointer",
        }}
        onClick={() => handleNodeClick(nodeDatum)}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            wordWrap: "break-word",
            fontWeight: "bold",
            color: "white",
            width: "100%",
            height: "100%",
            // border: "1px solid red",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            cursor: nodeDatum.access === 0 ? "not-allowed" : "pointer",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              // wordWrap: "break-word",
              fontWeight: "bold",
              color: "white",
              width: "100%",
              // border: "1px solid yellow",
              // textAlign: "center",
              cursor: nodeDatum.access === 0 ? "not-allowed" : "pointer",
            }}
          >
            {nodeDatum.name}
          </div>
        </div>
      </foreignObject>
      <line
        onClick={() => handleNodeClick(nodeDatum)}
        x1="10"
        y1="-45"
        x2="10"
        y2="45"
        stroke="yellow"
        strokeWidth="1px"
        style={{
          cursor: nodeDatum.access === 0 ? "not-allowed" : "pointer",
        }}
      />

      {nodeDatum.children?.length > 0 && (
        <>
          <circle
            onClick={toggleNode}
            cx="150"
            cy="-1"
            r="11"
            stroke="black"
            fill="black"
          ></circle>
          {/* <text
            style={{ fontSize: "1.3rem" }}
            onClick={toggleNode}
            fill="white"
            strokeWidth="0"
            x="150"
            y="0"
            textAnchor="middle"
            alignmentBaseline="middle"
          >
            {nodeDatum.__rd3t.collapsed ? "+" : "-"}
          </text> */}
          {nodeDatum.__rd3t.collapsed ? (
            <svg
              width="21"
              height="21"
              x="140"
              y="-11"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              onClick={toggleNode}
            >
              <line
                onClick={toggleNode}
                x1="12"
                y1="5"
                x2="12"
                y2="19"
                stroke="white"
                stroke-width="2"
              />
              <line
                onClick={toggleNode}
                x1="5"
                y1="12"
                x2="19"
                y2="12"
                stroke="white"
                stroke-width="2"
              />
            </svg>
          ) : (
            <svg
              onClick={toggleNode}
              width="21"
              height="21"
              x="140"
              y="-11"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                onClick={toggleNode}
                x1="5"
                y1="12"
                x2="19"
                y2="12"
                stroke="white"
                stroke-width="2"
              />
            </svg>
          )}
        </>
      )}
    </g>
  );
};

const Portfolio = () => {
  const [dimensions, translate, containerRef] = useCenteredTree();
  const [chartJson, setChartJson] = useState({});
  const [Details, setDetails] = useState({});
  const [DetailsId, setDetailsId] = useState("");

  // console.log("DetailsId inside details pop: ", DetailsId);

  const [loader, setLoader] = useState(false);

  const nodeSize = { x: 380, y: 170 };

  const containerStyles = {
    width: "100vw",
    height: "100vh",
  };

  const handleNodeClick = (nodeDatum) => {
    setDetailsId("");
    setDetails({});

    if (parseInt(nodeDatum.access) === 1) {
      setDetailsId(nodeDatum.id);
      setDetails({
        id: nodeDatum.id,
        name: nodeDatum.name,
        hirarchy_level: nodeDatum.hirarchy_level,
        img: nodeDatum.img,
      });

      //Set BrandDetails data in localstorage
      localStorage.setItem(
        "BrandDetails",
        JSON.stringify({
          id: nodeDatum.id,
          name: nodeDatum.name,
          hirarchy_level: nodeDatum.hirarchy_level,
          img: nodeDatum.img,
        })
      );
    }
  };

  // console.log("chartJson: ", chartJson);

  // const URL = `get_hirarchy_data_nested/?v=${new Date().getTime()}`;

  useEffect(() => {
    setLoader(true);
    axios
      .post("get_hirarchy_data_nested/", {})
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setChartJson(response.data);
          localStorage.setItem("allBrands", JSON.stringify(response.data));
          setLoader(false);
        }
      })
      .catch((data) => {
        setLoader(false);
      });

    return () => {};
  }, []);

  return (
    <div style={{ backgroundColor: "black" }}>
      <CssBaseline />
      {loader ? <Loader /> : null}
      <ToastContainer />
      <Topbar BrandName="" />

      {DetailsId !== "" ? (
        <DetailsPopUp
          DetailsId={DetailsId}
          setDetailsId={setDetailsId}
          Details={Details}
          setDetails={setDetails}
        />
      ) : null}

      {/* Tree */}
      <div style={containerStyles} ref={containerRef}>
        <Tree
          data={chartJson}
          dimensions={dimensions}
          translate={translate}
          renderCustomNodeElement={(rd3tProps) => (
            <RenderRectSvgNode
              {...rd3tProps}
              handleNodeClick={handleNodeClick}
              setDetailsId={setDetailsId}
            />
          )}
          orientation="horizontal"
          initialDepth={1}
          separation={{ siblings: 1, nonSiblings: 1 }}
          enableLegacyTransitions={800}
          nodeSize={nodeSize}
          pathFunc={"step"}
          zoomable={true}
          pathClassFunc={() => "custom-path-link"}
        />
      </div>
    </div>
  );
};

export default Portfolio;
