import { useState, useEffect, useCallback } from "react";
import { Typography, Box, Stack, Divider, Button } from "@mui/material";
import Table from "./Table";
import Allocation from "./Allocation";
import Sum from "./Sum";
import SaveResultAlert from "./SaveResultAlert";
import { useParams } from "react-router";

// utility
import findAllChangedParents from "./utils/changedParents";
import getBrandIDandChannelID from "./utils/getBrandIDandChannelID";
import axios from "../../axios";

import findAllLevelChildren from "./utils/allLevelChildren";

const DeepDive = ({
  hierarchy,
  saveResult,
  setSaveResult,
  optimizationType,
  optimizationTypeValue,
  setResultName,
  saveResponse,
  resultName,
  getBrandName,
  getKpiName,
  getTimeframe,
  getTimeperiod,
  handler
}) => {
  const [allChannels, setAllChannels] = useState(hierarchy[0]?.data);
  // console.log("allChannels: ", allChannels);
  const [DATA, setDATA] = useState(hierarchy[0]?.data);
  const [allowEdit, setAllowEdit] = useState(false);

  const [totalSpend, setTotalSpend] = useState(0);
  const [channelsSpend, setChannelsSpends] = useState({});
  const [checked, setChecked] = useState(false);
  const [flatChannels, setFlatChannels] = useState({}); // all flat channels
  const [userInputKey, setUserInputKey] = useState("");
  const [myObj, setMyObj] = useState({}); // for storing parent max and sum of child
  const [randomFunExecuted, setRandomFunExecuted] = useState(false);
  const [reOptimizePayload, setReOptimizePayload] = useState([]);

  const [isOpen2, setIsOpen2] = useState(false);
  const [message2, setMessage2] = useState("");

  const [isExecuted, setIsExecuted] = useState(false);
  const [channelType, setChannelType] = useState("");
  const [reOptimized, setReOptimized] = useState(false);

  const [getInputChangeValueEditSpend, setGetInputChangeValueEditSpend] =
    useState("");

  const { resultId } = useParams();
  // console.log("resultId: ", resultId);
  // console.log("executed: ", isExecuted);
  // console.log("channelType: ", channelType);

  const Alert2 = ({ message }) => {
    return (
      <>
        {isOpen2 && (
          <Stack
            alignItems={"center"}
            spacing={4}
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 450,
              bgcolor: "bgColor",
              boxShadow: 24,
              p: "2rem",
              borderRadius: "1rem",
              zIndex: 9999999,
            }}
          >
            <Typography
              sx={{
                fontSize: "1.25rem",
                color: "white",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              {message}
            </Typography>
            <Button
              onClick={() => {
                setIsOpen2(false);
                setIsExecuted(true);
                setMessage2("");
                traverseInputChange(userInputKey, channelType, false);
                traverse(
                  userInputKey,
                  channelType,
                  userInputKey,
                  [0],
                  false,
                  false
                );
              }}
              sx={{
                padding: "0.5rem 1.5rem",
                backgroundColor: "themeColor",
                color: "bgColor",
                fontSize: "1rem",
                fontWeight: 700,
                "&:hover": {
                  backgroundColor: "themeColor",
                  color: "bgColor",
                },
              }}
            >
              RESET
            </Button>
          </Stack>
        )}
      </>
    );
  };


  // console.log("chartData: ", chartData);

  // console.log("summaryData: ", summaryData);
  // console.log("Payload: ", reOptimizePayload);

  const TOTAL_BUDGET = DATA.spend;

  // temp input key and value
  const [tempInputKeyValue, setTempInputKeyValue] = useState([]);

  // console.log("Edit spends value: ", editSpendsValue);
  // console.log("TOTAL_BUDGET: ", TOTAL_BUDGET);

  function payload() {
    Object.keys(flattened).forEach((key) => {
      const [brandID, channelID] = getBrandIDandChannelID(key, flattened);

      const payloadItem = {
        id: key,
        value: flattened[key][1],
        brandID: brandID,
        channelID: channelID,
      };

      // Check if the id already exists in reOptimizePayload
      const index = reOptimizePayload.findIndex((item) => item.id === key);

      if (flattened[key][3] === true) {
        // console.log("brandID for payload: ", brandID);
        if (index !== -1) {
          // If the id exists, update the value
          reOptimizePayload[index].value = payloadItem.value;
        } else {
          // If the id doesn't exist, add a new entry
          setReOptimizePayload([...reOptimizePayload, payloadItem]);
        }
      }

      if (flattened[key][3] === false) {
        if (index !== -1) {
          // If the id exists, remove the item from the array
          const updatedPayload = [...reOptimizePayload];
          updatedPayload.splice(index, 1);
          setReOptimizePayload(updatedPayload);
        }
        // You can choose to do nothing if the id doesn't exist and flattened[key][3] is false.
      }
    });
  }

  useEffect(() => {
    payload();
  }, [allChannels]);

  // const re_URL = "http://3.108.210.176:8000/api/optimise/";
  const re_URL = "http://15.207.123.147:8080/hierarchy/";

  const reOptimizeHandler = () => {
    axios
      .get(
        re_URL
        //    {
        //   brand: "loreal",
        //   kpi: "sales",
        //   changed: reOptimizePayload,
        //   timeperiod: "Annual",
        //   timeframe: "Annual",
        //   budget: 28.3,
        //   ddtag: "PrevYr",
        //   optimization_type: "budget",
        //   result_name: "",
        //   theme: "Dark",
        // }
      )
      .then((response) => {
        // Handle the response here
        // console.log("Response:", response.data);

        setAllChannels(response.data[0].data);
        setDATA(response.data[0].data);
        // setHierarchy(response.data);
        handler(response.data)
        setIsExecuted(false);
        setAllowEdit(false);
        setChecked(false);
        setFlatChannels({});
        setMyObj({});
        setReOptimizePayload([]);
        setUserInputKey("");
        setRandomFunExecuted(false);
        setIsExecuted(false);
        setChannelType("");
        setReOptimized(true);
      })
      .catch((error) => {
        // Handle any errors here
        console.error("Error:", error);
      });
  };

  const saveOptimisation = () => {
    // console.log("clicked!");
    if (resultName !== "") {
      const ParamData = {
        result_name: resultName,
        optimization_type: "budget",
        budget: 11.56,
        brand: getBrandName,
        kpi: getKpiName,
        timeframe: getTimeframe,
        timeperiod: getTimeperiod,
        result_data: saveResponse,
        ddtag: "PrevYr",
      };
      // setLoader(true);
      axios
        .post("save_optimisation/", ParamData)
        .then((response) => {
          if (response.data.error === 1) {
            // toast.error(response.data.erroMsg);
            // setLoader(false);
          } else {
            // setSaveResult(false)
            // setSaveResponse(response.data.data)
            console.log(response.data.data);
            // setLoader(false);
          }
        })
        .catch(() => {
          // setLoader(false);
        });
    }
  };

  // console.log("tempInputKeyValue: ", tempInputKeyValue);

  const setTempInputKeyValueFun = (value) => {
    setTempInputKeyValue(value);
  };

  // console.log("myobj for parent max and sumofchild: ", myObj);
  // console.log("All changed parent: ", changedParentForUserInputKey);
  // console.log("All changed children: ", changedChildrenForUserInputKey);
  // console.log("user input blur: ", userInputBlur)

  //  flat channels ................start...............

  const parent = (DATA, id) => {
    // console.log("parent running!!!!!!!!!!!!!");
    let flatObject = {};
    const flattenDATA = (channels, flatObject, id) => {
      const handleSubchannels = (subchannel, id) => {
        flatObject[subchannel.mykey] = [
          id, //brand
          subchannel.editSpends, //changed value
          subchannel.color, //color
          subchannel.changed, //changed flag true | false
          // subchannel.P_MAX,
          // subchannel.sumofchild,
          subchannel.min_val,
          subchannel.max_val,
        ];
        if (subchannel.subchannel && subchannel.subchannel.length > 0) {
          subchannel.subchannel.forEach((sub) => {
            handleSubchannels(sub, id);
          });
        }
      };
      Object.keys(channels).forEach((category) => {
        channels[category].forEach((channel) => {
          flatObject[channel.mykey] = [
            id,
            channel.editSpends,
            channel.color,
            channel.changed,
            // channel.P_MAX,
            // channel.sumofchild,
            channel.min_val,
            channel.max_val,
          ];
          if (channel.subchannel && channel.subchannel.length > 0) {
            channel.subchannel.forEach((subchannel) => {
              handleSubchannels(subchannel, id);
            });
          }
        });
      });
    };
    if (DATA.channels) {
      flattenDATA(DATA.channels, flatObject, id);
    }
    const handleInnerSublevel = (channels, flatObject, id) => {
      flattenDATA(channels, flatObject, id);
    };
    const handleSublevel = (sublevel) => {
      if (sublevel && sublevel.length > 0) {
        sublevel.forEach((sublevel) => {
          if (sublevel.data) {
            handleInnerSublevel(
              sublevel.data.channels,
              flatObject,
              sublevel.data.id
            );
          }
          if (sublevel.data.sublevel) {
            handleSublevel(sublevel.data.sublevel);
          }
        });
      }
    };

    handleSublevel(DATA.sublevel);
    return flatObject;
  };

  // flat channels ...............end...........

  const flattened = parent(DATA, DATA.id);

  const setKeyFun = (key) => {
    setUserInputKey(key);
  };

  const setCheckedFun = (value) => {
    setChecked(value);
  };

  const allowEditFun = useCallback((checked) => {
    setAllowEdit(checked);
  }, []);

  // allocaation channel bar sum
  useEffect(() => {
    const channelNames = Object.keys(allChannels.channels);

    const handler = () => {
      const channelsSpendsObj = {};

      channelNames.forEach((channelName) => {
        const sum = allChannels.channels[channelName]
          .map((item) => item.spends)
          .reduce((acc, curr) => acc + curr, 0);

        const color = allChannels.channels[channelName][0].color; // Assuming the color is the same for all items in a channel

        channelsSpendsObj[channelName] = [sum, color];
      });

      setChannelsSpends(channelsSpendsObj);
    };

    handler();
    setTotalSpend(allChannels.spend);
  }, [allChannels]);

  const traverse = useCallback(
    (channelID, type, newKey, newValue, changed, refresh) => {
      // console.log("use call back called!", refresh, changed, channelID, type, newKey, newValue)
      const updateChannel = (channel) => {
        channel[newKey] = newValue;
        let arr = channel[newKey];
        channel.editSpends = arr[arr.length - 1];
        channel.changed = changed;
        channel.refresh = refresh;
        // channel.P_MAX = P_MAX;
        // channel.sumofchild = sum;
      };
      const recursiveUpdate = (channelsArray) => {
        channelsArray.forEach((channel) => {
          if (channel.mykey === channelID) {
            updateChannel(channel);
          } else if (channel.subchannel && channel.subchannel.length > 0) {
            recursiveUpdate(channel.subchannel);
          }
        });
      };

      Object.entries(allChannels.channels).forEach((item) => {
        if (type === item[0]) {
          recursiveUpdate(item[1]);
        }
      });

      // Update the state outside the map and recursion (if needed)
      setAllChannels((prevChannels) => ({ ...prevChannels }));
    },
    [allChannels, setAllChannels, reOptimized]
  );

  const traverseInputChange = useCallback(
    (channelID, type, inputChange) => {
      // console.log("use call back called!", refresh, changed, channelID, type, newKey, newValue)
      const updateChannel = (channel) => {
        channel.inputChange = inputChange;
      };
      const recursiveUpdate = (channelsArray) => {
        channelsArray.forEach((channel) => {
          if (channel.mykey === channelID) {
            updateChannel(channel);
          } else if (channel.subchannel && channel.subchannel.length > 0) {
            recursiveUpdate(channel.subchannel);
          }
        });
      };

      Object.entries(allChannels.channels).forEach((item) => {
        if (type === item[0]) {
          recursiveUpdate(item[1]);
        }
      });

      // Update the state outside the map and recursion (if needed)
      setAllChannels((prevChannels) => ({ ...prevChannels }));
    },
    [allChannels, setAllChannels, reOptimized]
  );

  const traverseUserInputKey = (userInputKey, flatObject) => {
    let VALUE; // for user input key --> l-hco-gar-trad-tv
    let brandId; // flatObj[0] l-hco-gar-trad-tv --> l-hco-gar
    let brandList; // [l-hco, l]
    let channelList; // [trad-tv, trad]
    let changedParentForUserInputKey = []; //which parent is changed for a userinputkey
    let changedChildrenForUserInputKey = []; // which children is  changed for a userinputkey

    Object.keys(flatObject).forEach((item) => {
      if (item === userInputKey) {
        VALUE = flatObject[item];
        brandId = VALUE[0];
        brandList = brandId.split("-");
      }
    });

    const generateParentBrandNames = (brandId) => {
      const parts = brandId?.split("-");
      const result = [];

      for (let i = 1; i < parts?.length; i++) {
        const brandName = parts?.slice(0, parts.length - i).join("-");
        result.push(brandName);
      }

      return result;
    };

    brandList = generateParentBrandNames(brandId);
    // console.log("brandlist", brandList);

    const findChannelParent = (foundKey) => {
      let str = foundKey.replace(`${brandId}-`, "");
      const parts = str.split("-");
      const output = [];

      for (let i = parts.length - 1; i > 0; i--) {
        const subArray = parts.slice(0, i);
        output.push(subArray.join("-"));
      }

      return output;
    };

    channelList = findChannelParent(userInputKey);
    const channelID = userInputKey.replace(`${brandId}-`, "");
    channelList.unshift(channelID);
    brandList.unshift(brandId);
    // console.log(channelID, brandId)
    // console.log(brandList, "brand list");
    // console.log("channel list", channelList);
    let allParentsForUserInputKey = []; // all parents  for userinputkey
    let allChildrenForUserInputKey = []; // all childs for userinputkey

    // code for find the all parents for userinputkey....start........
    brandList?.forEach((brand) => {
      // console.log("brand", brand);
      channelList?.forEach((channel) => {
        // console.log("channel", channel);
        let str = `${brand}-${channel}`;
        Object.keys(flatObject).forEach((item) => {
          if (item === str && str !== userInputKey) {
            allParentsForUserInputKey.push(item);
          }
        });
      });
    });
    // code for find the all parents for userinputkey.........end........

    // code for find the allchildren for userinputkey ............start.....
    const findAllChidlrenForUserInputKey = (flatObject, brandId, channelID) => {
      let arr = [];
      // let filterArr = [];
      Object.keys(flatObject).forEach((item) => {
        if (item.includes(brandId)) {
          arr.push(item);
        }
      });

      arr.forEach((channel) => {
        if (channel.includes(channelID)) {
          // console.log(channel);
          if (channel !== userInputKey) {
            allChildrenForUserInputKey.push(channel);
          }
        }
      });
    };

    findAllChidlrenForUserInputKey(flatObject, brandId, channelID);

    // code for find the allchildren for userinputkey ............end.....

    // console.log("brand id", brandId);
    // console.log("brand list", brandList);
    // console.log("all parents for userinputkey", allParentsForUserInputKey); // parent list of input key
    // console.log("all chidlren for userinputkey", allChildrenForUserInputKey); // child list for input key

    // which parent(s) is/are changed for user input key!

    allParentsForUserInputKey?.forEach((parent) => {
      // console.log("ppppp", parent)
      Object.keys(flatObject).forEach((innerkey) => {
        if (innerkey === parent) {
          if (flatObject[innerkey][3] === true) {
            // console.log("changed parent", parent);
            changedParentForUserInputKey.push(parent);
          }
        }
      });
    });

    //which child is changed for input key!
    allChildrenForUserInputKey?.forEach((child) => {
      // console.log("childrennnnnnnn", child)
      Object.keys(flatObject).forEach((innerkey) => {
        if (innerkey === child) {
          if (flatObject[innerkey][3] === true) {
            // console.log("changed parent", parent);
            changedChildrenForUserInputKey.push(child);
          }
        }
      });
    });

    // Logic to update P_MAX................................................................
    // for each child which parent is/are changed
    allChildrenForUserInputKey?.forEach((eachChild) => {
      // console.log("each child: ", eachChild);
      let intersection = [];
      const findParentForEachChild = (eachChild, flatObject) => {
        let VALUE; // for each child key
        let brandId;
        let brandList;
        let channelList;
        let changedParentForEachChildKey = []; //which parent is changed for eachchildkey

        Object.keys(flatObject).forEach((item) => {
          if (item === eachChild) {
            VALUE = flatObject[item];
            brandId = VALUE[0];
            brandList = brandId.split("-");
          }
        });

        const generateParentBrandNames = (brandId) => {
          const parts = brandId?.split("-");
          const result = [];

          for (let i = 1; i < parts?.length; i++) {
            const brandName = parts?.slice(0, parts.length - i).join("-");
            result.push(brandName);
          }

          return result;
        };

        brandList = generateParentBrandNames(brandId);
        // console.log("brandlist", brandList);

        const findChannelParent = (foundKey) => {
          let str = foundKey.replace(`${brandId}-`, "");
          const parts = str.split("-");
          const output = [];

          for (let i = parts.length - 1; i > 0; i--) {
            const subArray = parts.slice(0, i);
            output.push(subArray.join("-"));
          }

          return output;
        };

        channelList = findChannelParent(eachChild);
        const channelID = eachChild.replace(`${brandId}-`, "");
        channelList.unshift(channelID);
        brandList.unshift(brandId);
        // console.log(channelID, brandId)
        // console.log(brandList, "brand list");
        // console.log("channel list", channelList);
        let allParentsForEachChildKey = []; // all parents  for eachchildkey

        // code for find the all parents for userinputkey....start........
        brandList?.forEach((brand) => {
          // console.log("brand", brand);
          channelList?.forEach((channel) => {
            // console.log("channel", channel);
            let str = `${brand}-${channel}`;
            Object.keys(flatObject).forEach((item) => {
              if (item === str && str !== eachChild) {
                allParentsForEachChildKey.push(item);
              }
            });
          });
        });

        // changed parent

        allParentsForEachChildKey?.forEach((parent) => {
          // console.log("ppppp", parent)
          Object.keys(flatObject).forEach((innerkey) => {
            if (innerkey === parent) {
              if (flatObject[innerkey][3] === true) {
                // console.log("changed parent", parent);
                changedParentForEachChildKey.push(parent);
              }
            }
          });
        });

        // code for find the all parents for userinputkey.........end........
        // console.log(
        //   "all changed ParentsForEachChildKeyKey: ",
        //   eachChild,
        //   changedParentForEachChildKey
        // );

        intersection = intersectArrays(
          changedChildrenForUserInputKey,
          changedParentForEachChildKey
        );

        // console.log("intersection: ", intersection);

        if (intersection.length === 0) {
          // console.log("p max debuggggggggggg: ", flattened[userInputKey]);
          let P_MAX = 0;
          if (flattened[userInputKey][3] === true) {
            P_MAX = flattened[userInputKey][1];
          } else if (changedParentForEachChildKey?.length === 0) {
            P_MAX = DATA.spend;
          } else {
            // P_MAX = DATA.spend;
            // taking the p max from nearest parent
            P_MAX = flattened[changedParentForEachChildKey[0]]?.[1];
            // console.log("P max inside reset: ", flattened[changedParentForEachChildKey[0]][1])
          }
          // console.log("P_MAX", P_MAX, myObj[eachChild][1], myObj[eachChild][2], myObj[eachChild][3] )
          // myObj[eachChild][0] = P_MAX;
          let updatedArray = [...myObj[eachChild]];
          updatedArray = [
            P_MAX,
            myObj[eachChild][1],
            myObj[eachChild][2],
            myObj[eachChild][3],
          ];
          // console.log("updated array - P_MAX", updatedArray)
          setMyObj((prev) => ({
            ...prev,
            [eachChild]: updatedArray,
          }));
        }
        // console.log(
        //   "changed parent for each child key: ",
        //   changedParentForEachChildKey
        // );
      };
      // find paren for each child function end

      findParentForEachChild(eachChild, flatObject);
      function intersectArrays(array1, array2) {
        return array1.filter((element) => array2.includes(element));
      }
    });

    // for each child which parent is/are changed........end.......

    // .....................................................................................................................
    // ...........................................................................................................................
    allParentsForUserInputKey?.forEach((parent) => {
      // console.log("parent", parent);
      let channelChanged = []; // channel changed for each parent!
      let intersection = [];
      const traverseKey = (parent, flatObject) => {
        let brandId;

        // console.log("My Parent: ", parent);
        // console.log("Flatdict: ", flatObject[parent]);
        brandId = flatObject[parent][0];
        let channelID = parent.replace(`${brandId}-`, "");
        // console.log("bbbbbbbb", brandId, channelID);
        const findChild = (flatObject, brandId, channelID) => {
          // console.log("Inside Find Child: ", channelChanged);
          let arr = [];
          // let filterArr = [];
          Object.keys(flatObject).forEach((item) => {
            if (item.includes(brandId)) {
              arr.push(item);
            }
          });
          // console.log("array prinnt", arr);

          arr.forEach((channel) => {
            if (channel.includes(channelID)) {
              // console.log(channel);
              if (channel !== parent && flatObject[channel][3] === true) {
                channelChanged.push(channel);
              }
            }
          });
          // console.log('Travese End Log', channelChanged)
        };

        findChild(flatObject, brandId, channelID);
        function intersectArrays(array1, array2) {
          return array1.filter((element) => array2.includes(element));
        }
        intersection = intersectArrays(
          changedParentForUserInputKey,
          channelChanged
        );

        // console.log("intersection for sum of child: ", parent, intersection);
        // console.log("channel changed: ", parent, channelChanged);
        // console.log(
        //   "parent changed and child changed",
        //   parent,
        //   changedParentForUserInputKey,
        //   channelChanged
        // );
        if (intersection.length === 0) {
          // console.log("enterrrrrrrrrrrrrrrrrr", intersection.length);
          let parentChildVal = myObj[parent][1];
          // console.log("parent child value: ", parent, parentChildVal);
          let inputKeyChangedVal = 0;
          if (flattened[userInputKey][3] === true) {
            inputKeyChangedVal = flattened[userInputKey][1];
          } else {
            inputKeyChangedVal = 0;
          }
          // console.log("input key changed value: ", inputKeyChangedVal);
          let inputKeyChildVal = myObj[userInputKey][1];
          // console.log("Input key child value: ", userInputKey, inputKeyChildVal)

          Object.keys(flatObject).forEach((item) => {
            if (item === parent) {
              // console.log(item, "found parent");
              let diff = 0;

              diff = inputKeyChangedVal - myObj[userInputKey][3];
              const arr = [...myObj[userInputKey]];
              arr[2] = diff;
              // myObj[userInputKey][2] = diff;
              setMyObj((prev) => ({
                ...prev,
                [userInputKey]: arr,
              }));

              const sumofchild = parentChildVal + diff - inputKeyChildVal;

              // console.log("diff: ", userInputKey, diff);
              // console.log("prev changed value: ", myObj[userInputKey][3]);
              // console.log(
              //   "sum of child params: ",
              //   parent,
              //   parentChildVal,
              //   diff,
              //   inputKeyChildVal
              // );
              // console.log("sum of child: ", parent, sumofchild);

              // myObj[userInputKey][3] = inputKeyChangedVal;
              const arr2 = [...myObj[userInputKey]];
              arr2[3] = inputKeyChangedVal;
              // console.log("arrr33333333: ", arr2[3])

              setMyObj((prev) => ({
                ...prev,
                [userInputKey]: [
                  // DATA.spend,
                  // sumofchild,
                  // myObj[parent][2],
                  // myObj[parent][3],
                  ...arr2,
                ],
              }));

              const arr3 = [...myObj[parent]];

              arr3[1] = sumofchild;
              setMyObj((prev) => ({
                ...prev,
                [parent]: [...arr3],
              }));
            }
          });
        }
      };

      traverseKey(parent, flatObject);
    });
  };

  // .........................................................
  //  this function is to coopy all keys from flatchannels to myobj
  const randomfun = () => {
    // console.log("random function called...........");
    for (const key in flatChannels) {
      // console.log("key in raondo: ", key);
      if (flatChannels.hasOwnProperty(key)) {
        myObj[key] = [DATA.spend, 0, 0, 0]; // [budget, sumofchild, diff, prevchangedvalue]
        setRandomFunExecuted(true);
      }
    }
  };

  useEffect(() => {
    if (flatChannels !== null && !randomFunExecuted) {
      // console.log("inside useffect: ", flatChannels);
      randomfun();
    }
  }, [flatChannels, randomFunExecuted]);

  useEffect(() => {
    // const flattened = parent(DATA, DATA.id);
    // console.log("useeffect called!........");
    setFlatChannels(flattened);
    traverseUserInputKey(userInputKey, flattened);
    const allLevelChildren = findAllLevelChildren(userInputKey, flattened);
    console.log("allLevelChildren: ", allLevelChildren);
    // abc();
  }, [userInputKey, allChannels, reOptimized]);

  // console.log("flat channels", flatChannels);

  // code to fill user bar

  const getColorSum = (obj) => {
    const output = {};

    for (const key in obj) {
      // console.log("key in user bar fill: ", key);
      const changedParent = findAllChangedParents(key, flattened);
      // console.log("changedParent: ", changedParent);
      // const [color, value] = obj[key];
      const color = obj[key][2];
      const value = obj[key][1];

      if (changedParent && changedParent.length > 0) {
        // Don't add the value if a parent is changed
        continue;
      }

      if (output[color]) {
        // Check if value is undefined and set it to 0
        output[color] += value !== undefined ? value : 0;
      } else {
        // Check if value is undefined and set it to 0
        output[color] = value !== undefined ? value : 0;
      }
    }

    return output;
  };

  const userBarFillObject = getColorSum(flattened);
  // console.log("user bar sum object: ", userBarFillObject);

  // code to fill user bar end...............
  console.log("flat channels", flattened);

  // validations.....................start...............

  useEffect(() => {
    const userBarFillValues = Object.values(userBarFillObject);

    // console.log("userBarFillValues :", userBarFillValues);
    const all_keys_sum = userBarFillValues.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    if (all_keys_sum > TOTAL_BUDGET) {
      // check for total budget
      setIsExecuted(true);
      setIsOpen2(true);
      setMessage2(
        `Total spend amount ${all_keys_sum} should not be more than ${TOTAL_BUDGET}`
      );
    } else if (
      // check for p_max
      userInputKey !== "" &&
      flattened[userInputKey][1] > myObj[userInputKey][0]
    ) {
      setIsOpen2(true);
      setMessage2(
        `The entered value (${flattened[userInputKey][1]}) of ${userInputKey} is greater than its parent (${myObj[userInputKey][0]})`
      );
    } else {
      Object.keys(myObj).forEach((key) => {
        if (
          // check if parent value is less than sum of children
          flattened[key][1] !== 0 &&
          myObj[key][1] !== 0 &&
          flattened[key][1] < myObj[key][1] &&
          !isExecuted
        ) {
          // console.log("key.............: ", flattened[key][1], myObj[key][1]);
          setIsOpen2(true);
          setIsExecuted(true);
          setMessage2(
            `${key} sub-channels (${myObj[key][1]}) should be equal to ${key} spends (${flattened[key][1]})`
          );
        }
      });
    }
  }, [allChannels, flattened]);

  // validations......................end................

  return (
    <Box sx={{ height: "100%" }}>
      <Stack height={"100%"} justifyContent={"space-between"}>
        {/* item 1 */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: 700,
            color: "themeColor",
            height: "5%",
          }}
        >
          Growth Projections
        </Typography>
        {/* end item 1 */}

        {/* item 2 */}

        <Stack
          direction={"row"}
          height={"94%"}
          justifyContent={"space-between"}
          // spacing={"3px"}
          // sx={{opacity: 0.3}}
        >
          <Stack direction={"row"} sx={{ width: "60%" }}>
            {/* <Sidebar hierarchy={hierarchy} setAllChannelsFun={setAllChannels} /> */}
            <Divider orientation="vertical" />
            <Table
              hierarchy={hierarchy}
              traverse={traverse}
              allChannels={allChannels}
              allowEditFun={allowEditFun}
              setCheckedFun={setCheckedFun}
              DATA={DATA}
              flattened={flattened}
              setKeyFun={setKeyFun}
              myObj={myObj}
              setTempInputKeyValueFun={setTempInputKeyValueFun}
              TOTAL_BUDGET={TOTAL_BUDGET}
              userBarFillObject={userBarFillObject}
              reOptimizeHandler={reOptimizeHandler}
              setIsExecuted={setIsExecuted}
              setChannelType={setChannelType}
              traverseInputChange={traverseInputChange}
              setGetInputChangeValueEditSpend={setGetInputChangeValueEditSpend}
              setSaveResult={setSaveResult}
            />
          </Stack>
          {/* second half */}
          <Box
            sx={{
              display: "flex",
              width: "39.60%",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Allocation
              totalSpend={totalSpend}
              channelsSpend={channelsSpend}
              userBarFillObject={userBarFillObject}
              allowEdit={allowEdit}
              checked={checked}
              DATA={DATA}
            />
            <Divider orientation="vertical" />
            <Sum
              hierarchy={hierarchy}
              checked={checked}
              setAllChannelsFun={setAllChannels}
            />
          </Box>
        </Stack>

        {/* end item 2 */}
      </Stack>
      <Alert2 message={message2} />
      {saveResult && (
        <SaveResultAlert
          setSaveResult={setSaveResult}
          optimizationType={optimizationType}
          optimizationTypeValue={optimizationTypeValue}
          setResultName={setResultName}
          saveResponse={saveResponse}
          saveOptimisation={saveOptimisation}
        />
      )}
    </Box>
  );
};

export default DeepDive;
