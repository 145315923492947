// import { Stack } from "@mui/material";
import React from "react";
import TopSection from "./TopSection";
import BottomSection from "./BottomSection";
import { Stack,useTheme,useMediaQuery } from "@mui/material";
const MainSection = (props) => {
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));
  return (
    <Stack
      direction={"column"}
      sx={{
        width: isMedium||isSmall?"100%": "64%",
        borderRadius: "20px",
        // border: "10px solid blue",
        height: "100%",
      }}
      gap={"0.5%"}
    >
      <TopSection carouselData={props.carouselData} />
      <BottomSection
        funnelData={props.funnelData}
        stackSeries={props.stackSeries}
        stackCategories={props.stackCategories}
        stackNonclickList={props.stackNonclickList}
        DrillDownTrends={props.DrillDownTrends}
        NotAllow={props.NotAllow}
        DrillAllTrends={props.DrillAllTrends}
        ResetAllTrends={props.ResetAllTrends}
        DrillDownAllTrends={props.DrillDownAllTrends}
        DrillUpAllTrends={props.DrillUpAllTrends}
        campData={props.campData}
        DrillAllCamp={props.DrillAllCamp}
        ResetAllCamp={props.ResetAllCamp}
        DrillUpAllCamp={props.DrillUpAllCamp}
        DrillDownCamp={props.DrillDownCamp}
        DrillDownAllCamp={props.DrillDownAllCamp}
        tabNames={props.tabNames}
        baseLineData={props.baseLineData}
        baseLineSeries={props.baseLineSeries}
        matchId={props.matchId}
        startdate={props.startdate}
        enddate={props.enddate}
        city={props.city}
        time={props.time}
        defaultKpiFilter={props.defaultKpiFilter}
        team={props.team}
        setActiveTab={props.setActiveTab}
        activeTab={props.activeTab}
        lineData={props.lineData}
        DrillUpCamp={props.DrillUpCamp}
        funnelChartData={props.funnelChartData}
        matchStackData={props.matchStackData}
        DrillDownMatch={props.DrillDownMatch}
        DrillAllMatch={props.DrillAllMatch}
        ResetAllMatch={props.ResetAllMatch}
        DrillDownAllMatch={props.DrillDownAllMatch}
        DrillUpAllMatch={props.DrillUpAllMatch}
      />
    </Stack>
  );
};

export default React.memo(MainSection);
