import React from "react";

const EditDateResponse = (props) => {
  return (
    <>
      <div
        className="modal fade bs-example-modal-center show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className = "float-right">
            <button
                type="button"
                className="btn-close float-right"
                data-bs-dismiss="modal"
                style={{ float: "right"
                }}
                onClick={() => props.setEditDateResponse("")}
                aria-label="Close"
              ></button>
            </div>
             
            <div className="modal-body modal-body-custom">
              <div className="text-center">
              </div>
              <div className="row">
                <h3 className="theme-color">{props.EditDateResponse}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditDateResponse;
