import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const BoxItem = ({ header, spends, footnote }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background:
          "radial-gradient(66.07% 63.32% at 50% -45.72%, rgba(220, 255, 91, 0.7) 0%, rgba(28, 36, 39, 0) 100%)",
        border: "1px solid #d6ff41",
        borderRadius: "10px",
        color: "white",
        textAlign: "center",
        width: "45%",
        height: "40%",
        padding: "0 1rem",
      }}
    >
      <Box
        sx={{
          paddingTop: "1rem",
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-evenly",
          gap: "0.5rem",
          alignItems: "center",
          width: "100%",
          height: "100%",
          // border: "1px solid red",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: "4px",
          },

          "&::-webkit-scrollbar-track": {
            background: "#1C2427",
          },

          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "gray",
            borderRadius: "10px",
          },
        }}
      >
        <Typography variant="h6">{header}</Typography>
        <Typography variant="h6" fontWeight={700}>
          {spends}
        </Typography>
        <Typography variant="h6">{footnote}</Typography>
      </Box>
    </Box>
  );
};

export default BoxItem;
