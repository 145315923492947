import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setToken } from "../redux/actions/TokenAction";


const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const CurrentTheme = localStorage.getItem('CurrentTheme')
    const ColorCode = localStorage.getItem('ColorCode')

    localStorage.clear();
    dispatch(setToken(""));

    // Deleting light theme link element
    // if (document.getElementById("Light")) {
    //   document.getElementById("Light").remove();
    //   if (document.getElementById("Lavender")) {
    //     document.getElementById("Lavender").remove();
    //   }

    //   if (document.getElementById("Sapphire")) {
    //     document.getElementById("Sapphire").remove();
    //   }

    //   if (document.getElementById("Teal")) {
    //     document.getElementById("Teal").remove();
    //   }

    //   if (document.getElementById("Cyan")) {
    //     document.getElementById("Cyan").remove();
    //   }

    //   if (document.getElementById("Goldenrod")) {
    //     document.getElementById("Goldenrod").remove();
    //   }
    // }

    if(CurrentTheme !== null){
      localStorage.setItem('CurrentTheme', CurrentTheme)
      localStorage.setItem('ColorCode', ColorCode)
    }else{
      localStorage.setItem('CurrentTheme', "Dark")
    }
    
    window.Change_Theme();
    navigate("/login");

  })
  return (
    <div></div>
  );
};

export default Logout;
