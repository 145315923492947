// import { useEffect } from "react";

// const WindowDimensions = () => {
//   const updateWindowDimensions = () => {
//     // window.location.reload();
//     const allowedPages = ["/", "/login"];

//     if (!allowedPages.includes(window.location.pathname)) {
//       window.location.reload();
//     }

//   };

//   useEffect(() => {
//     window.addEventListener("resize", updateWindowDimensions);

//     return () => {
//       window.removeEventListener("resize", updateWindowDimensions);
//     };
//   }, []);

//   return <></>;
// };

// export default WindowDimensions;


import { useEffect } from "react";

const WindowDimensions = () => {
  const isDesktop = () => {
    return window.innerWidth > 768; // Adjust the threshold as needed
  };

  const updateWindowDimensions = () => {
    if (isDesktop()) {
      const allowedPages = ["/", "/login"];
      if (!allowedPages.includes(window.location.pathname)) {
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  return <></>;
};

export default WindowDimensions;
