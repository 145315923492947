import { useState } from "react";
import { Stack, useTheme, useMediaQuery, Typography, Box } from "@mui/material";
import BaselineChart from "./BaselineChart";
import ToggleButton from "react-toggle-button";
import InfoIcon from "../../images/icons8-info.svg";

const Body = (props) => {
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  // const [isTooltip, setIsTooltip] = useState(true);

  return (
    <Stack
      onClick={() => props.setDropDownClick(false)}
      sx={{
        height: "93%",
        padding: "0 1rem 1rem 1rem",
        backgroundColor: "#1c2427",
        borderRadius: "20px",
        position: "relative",
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        sx={{ padding: "0.5rem 0" }}
      >
        {true ? (
          <Box
            onMouseEnter={() => props.setIsTooltip(true)}
            onMouseLeave={() => props.setIsTooltip(false)}
          >
            <p className="m-0 px-2">
              <svg
                src={InfoIcon}
                id="notes"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                class="bi bi-info-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
            </p>
          </Box>
        ) : null}

        <Typography
          sx={{ fontSize: "1.15rem", color: "#d6ff41", margin: "auto" }}
        >
          {(props.activeTabName === "baseshift"
            ? "Base Shift"
            : "Brand Carryover") + ": "}
          {props.defaulLabelKpiFilter2}
        </Typography>
        {/* toggle */}
        {props.activeTabName === "brandcarryover" && (
          <Stack
            direction={"row"}
            gap={1}
            justifyContent={"flex-end"}
            alignItems={"center"}
            position={"absolute"}
            right={20}
          >
            <Typography
              sx={{
                color: !props.toggle ? "#d6ff41" : "white",
                fontSize: "0.85rem",
              }}
            >
              Absolute
            </Typography>
            <ToggleButton
              value={props.toggle}
              inactiveLabel=""
              activeLabel=""
              onToggle={(value) => {
                props.setToggle(!value);
                props.setFetchCall(true);
                props.setIsSubmit(true);
              }}
              colors={{
                activeThumb: {
                  base: "#d6ff41",
                },
                inactiveThumb: {
                  base: "#d6ff41",
                },
                active: {
                  base: "#3E5056",
                  // hover: "rgb(177, 191, 215)",
                },
                inactive: {
                  base: "#3E5056",
                  // hover: "rgb(95,96,98)",
                },
              }}
            />
            <Typography
              sx={{
                color: props.toggle ? "#d6ff41" : "white",
                fontSize: "0.85rem",
                // opacity: props.toggle ? 1 : 0.3,
              }}
            >
              Percentage
            </Typography>
          </Stack>
        )}
        {/* toggle */}
      </Stack>
      <BaselineChart
        baseLineData={props.baseLineData}
        baseLineSeries={props.baseLineSeries}
      />
      {props.isTooltip && props.baseLineData[0] && (
        <div
          style={{
            position: "absolute",
            backgroundColor: "#1c2427",
            padding: "10px",
            borderRadius: "10px",
            fontSize: "0.9rem",
            color: "white",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            top: 50,
            left: 23,
            zIndex: 10,
            width: 300,
          }}
          dangerouslySetInnerHTML={{ __html: props.baseLineData[0]?.notes }}
        />
      )}
    </Stack>
  );
};

export default Body;
