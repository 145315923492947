import { styled } from "@mui/material/styles";

export const Select = styled("select")(({ theme }) => ({
  outline: "none",
  padding: 0,
  // fontSize: theme.typography.h5.fontSize,
}));

export const Option = styled("option")(({ theme }) => ({
  // fontSize: theme.typography.h5.fontSize,
}));

export const Checkbox = styled("input")(({ theme }) => ({
  type: "checkbox",
}));
