import { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  Box,
  Tooltip,
  Stack,
  Typography,
  SvgIcon,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// icons mui
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

import Card from "./Card";

// charts

import ColumnChart from "../../../ChartsV2/ColumnChart";
import LineChart from "../../../ChartsV2/LineChart";
import StackChart from "../../../ChartsV2/StackChart";
import WaterfallChart from "../../../ChartsV2/WaterfallChart";

const Select = styled("select")(({ theme }) => ({
  outline: "none",
  // fontSize: theme.typography.h5.fontSize,
}));

const Option = styled("option")(({ theme }) => ({
  // fontSize: theme.typography.h5.fontSize,
}));

const PopUp = ({ onClose, children, popRef }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 999,
      }}
    >
      <Box
        ref={popRef}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          width: "50vw",
          height: "60vh",
          // background: "red",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 99999999999,
          transform: "translate(-50%, -50%)",
        }}
        onClick={onClose}
      >
        <Box
          sx={{
            background: "#1c2427",
            padding: "1rem",
            borderRadius: "8px",
            width: "50vw",
            height: "100%",
            border: "1px solid gray",
            position: "relative",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <CloseIcon
            onClick={onClose}
            sx={{
              position: "absolute",
              fontSize: "2rem",
              padding: "0.3rem",
              right: -15,
              top: -10,
              color: "yellow",
              zIndex: 9999,
              backgroundColor: "#1c2427",
              border: "1px solid gray",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          />
          {children}
        </Box>
      </Box>
    </Box>
  );
};

const Sum = ({ hierarchy, checked, setDeepDive }) => {
  const [summaryOpen, setSummaryOpen] = useState(true);
  const [chartsOpen, setChartsOpen] = useState(false);

  // chart data
  const [chartData, setChartData] = useState([]);
  const [chartType, setChartType] = useState("");
  const [channelMovementActive, setChannelMovementActive] = useState(false);
  const [lineFilter, setLineFilter] = useState("");
  const [lineChartData, setLineChartData] = useState({});
  const [showPopUp, setShowPopUp] = useState(false);
  const divRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);
  const [popHeight, setPopHeight] = useState(0);
  const [waterfallHeight, setWaterfallHeight] = useState(divHeight);

  const popRef = useRef(null);

  useEffect(() => {
    const updatePopHeight = () => {
      if (popRef.current) {
        const newHeight = popRef.current.clientHeight;
        setPopHeight(newHeight);
      }
    };

    updatePopHeight(); // Initial update
    window.addEventListener("resize", updatePopHeight);

    return () => {
      window.removeEventListener("resize", updatePopHeight);
    };
  }, [showPopUp, setShowPopUp]);

  useEffect(() => {
    const update = () => {
      const Height = divHeight - (divHeight * 10) / 100;
      // console.log(Height);
      setWaterfallHeight(Height);
    };

    update();
  }, [divHeight]);

  // console.log("divH", divHeight);

  useLayoutEffect(() => {
    const updateDivHeight = () => {
      if (divRef.current) {
        const newHeight = divRef.current.clientHeight;
        setDivHeight(newHeight);
      }
    };

    updateDivHeight(); // Initial update
    window.addEventListener("resize", updateDivHeight);

    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, [summaryOpen, chartsOpen]);
  // console.log("type chart", chartType);

  const handleSummaryOpen = () => {
    setSummaryOpen(!summaryOpen);
    setChartsOpen(!chartsOpen);
  };

  const handleChartsOpen = () => {
    setChartsOpen(!chartsOpen);
    setSummaryOpen(!summaryOpen);
  };

  useEffect(() => {
    if (hierarchy?.length > 0) {
      setChartData(hierarchy[0]?.caraouselchartdict);
    }
  }, [hierarchy]);

  useEffect(() => {
    if (chartData.length > 0) {
      setChartType(chartData[0]?.type);
    }
  }, [chartData]);

  useEffect(() => {
    const selectedChart = chartData.find((chart) => chart.type === "line");

    if (selectedChart) {
      setLineChartData(selectedChart);
      setLineFilter(selectedChart.data[0].filter);
    }
  }, [chartData]);

  // .............................

  const handleChartChange = (e) => {
    setChartType(e.target.value);
  };

  useEffect(() => {
    if (chartType === "line") {
      setChannelMovementActive(true);
    } else {
      setChannelMovementActive(false);
    }
  }, [chartType]);

  const renderLineChartForPopUp = () => {
    if (lineChartData.data) {
      const selectedChart = lineChartData.data.find(
        (chart) => chart.filter === lineFilter
      );

      return (
        <Box>
          <LineChart
            data={selectedChart}
            ChartHeight={popHeight - 40}
            ChartWidth={"100%"}
          />
        </Box>
      );
    }
  };
  const renderLineChart = () => {
    if (lineChartData.data) {
      const selectedChart = lineChartData.data.find(
        (chart) => chart.filter === lineFilter
      );

      return (
        <Box>
          <LineChart
            data={selectedChart}
            ChartHeight={divHeight}
            ChartWidth={"100%"}
          />
        </Box>
      );
    }
  };

  const channelMovementFilters = () => {
    const selectedChart = chartData.find((chart) => chart.type === "line");

    return (
      <>
        {selectedChart.data.map((data, index) => {
          return (
            <Option key={index} value={data.filter}>
              {data.filter}
            </Option>
          );
        })}
      </>
    );
  };

  const handleChartClick = () => {
    setShowPopUp(true); // Open the pop-up when clicking on the chart
  };

  const handlePopUpClose = () => {
    setShowPopUp(false); // Close the pop-up when clicking on it
  };

  const chartRenderForPopUp = () => {
    if (chartData?.length > 0) {
      const selectedChart = chartData.find((chart) => chart.type === chartType);
      if (selectedChart) {
        switch (selectedChart.type) {
          case "waterfall":
            return (
              <WaterfallChart
                data={selectedChart}
                ChartHeight={popHeight - 40}
                ChartWidth={"100%"}
              />
            );
          case "column":
            return (
              <ColumnChart
                data={selectedChart}
                ChartHeight={popHeight - 40}
                ChartWidth={"100%"}
              />
            );
          case "stack":
            return (
              <StackChart
                data={selectedChart}
                ChartHeight={popHeight - 40}
                ChartWidth={"100%"}
              />
            );
          case "line":
            return renderLineChartForPopUp();
          default:
            return null;
        }
      }
    }

    return <Box component={"span"}>Error occurred :(</Box>;
  };

  const chartRender = () => {
    if (chartData?.length > 0) {
      const selectedChart = chartData.find((chart) => chart.type === chartType);
      if (selectedChart) {
        switch (selectedChart.type) {
          case "waterfall":
            return (
              <WaterfallChart
                data={selectedChart}
                ChartHeight={waterfallHeight}
                ChartWidth={"100%"}
              />
            );
          case "column":
            return (
              <ColumnChart
                data={selectedChart}
                ChartHeight={divHeight}
                ChartWidth={"100%"}
              />
            );
          case "stack":
            return (
              <StackChart
                data={selectedChart}
                ChartHeight={divHeight}
                ChartWidth={"100%"}
              />
            );
          case "line":
            return renderLineChart();
          default:
            return null;
        }
      }
    }

    return <Box component={"span"}>Error occurred :(</Box>;
  };

  const iconSummary = summaryOpen ? (
    <SvgIcon
      component={RemoveTwoToneIcon}
      sx={{
        cursor: "pointer",
        color: "white",
        fontSize: "24px",
        fontWeight: 700,
      }}
    />
  ) : (
    <SvgIcon
      component={AddTwoToneIcon}
      sx={{
        cursor: "pointer",
        color: "white",
        fontSize: "24px",
        fontWeight: 700,
      }}
    />
  );
  const iconCharts = chartsOpen ? (
    <SvgIcon
      component={RemoveTwoToneIcon}
      sx={{
        cursor: "pointer",
        color: "white",
        fontSize: "24px",
        fontWeight: 700,
      }}
    />
  ) : (
    <SvgIcon
      component={AddTwoToneIcon}
      sx={{
        cursor: "pointer",
        color: "white",
        fontSize: "24px",
        fontWeight: 700,
      }}
    />
  );

  return (
    <Stack
      justifyContent={"space-between"}
      sx={{
        borderRadius: "1rem",
        height: "100%",
        overflow: "hidden",
        // border: "2px solid red",
      }}
    >
      {/* first part */}
      <Stack
        onClick={handleSummaryOpen}
        p="1rem"
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ cursor: "pointer", height: "10%", backgroundColor: "bgColor" }}
      >
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          Summary
        </Typography>
        <Box
          sx={{
            color: "white",
          }}
        >
          {iconSummary}
        </Box>
      </Stack>
      {/* first part */}

      {/* 2nd part */}
      {summaryOpen && (
        <Stack
          p={4}
          justifyContent={"center"}
          sx={{
            height: "79%",
            width: "100%",
            backgroundColor: "bgColor",
            // border: "2px solid yellow",
          }}
        >
          <Box
            sx={{
              // display: "grid",
              // gridTemplateColumns: "40% 40%",
              // gridTemplateRows: "40% 40%",
              // justifyContent: "space-evenly",
              paddingRight: "0.5rem",
              rowGap: "1rem",
              width: "100%",
              height: "100%",
              // border: "1px solid yellow",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "4px",
              },

              "&::-webkit-scrollbar-track": {
                background: "#1C2427",
              },

              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "gray",
                borderRadius: "10px",
              },
            }}
          >
            {hierarchy.map((item) =>
              item.caraouselsummarydict.map((card, index) => (
                <Card
                  key={index}
                  header={card.header}
                  spends={card.value}
                  footnote={card.footnote}
                />
              ))
            )}

            {/* <Box sx={{border: "1px solid red", width: "45%", height: "40%"}}></Box>
            <Box sx={{border: "1px solid red", width: "45%", height: "40%"}}></Box>
            <Box sx={{border: "1px solid red", width: "45%", height: "40%"}}></Box>
            <Box sx={{border: "1px solid red", width: "45%", height: "40%"}}></Box>
            <Box sx={{border: "1px solid red", width: "45%", height: "40%"}}></Box>
            <Box sx={{border: "1px solid red", width: "45%", height: "40%"}}></Box>
            <Box sx={{border: "1px solid red", width: "45%", height: "40%"}}></Box> */}
          </Box>
          <Button
            sx={{
              width: "20%",
              margin: "auto",
              padding: "0.5 1rem",
              border: "1px solid #d6ff41",
              color: "#d6ff41",
              "&:hover": {
                backgroundColor: "#d6ff41",
                color: "black",
              },
              textTransform: "capitalize",
              fontSize: ".75rem",
            }}
            onClick={() => setDeepDive(true)}
          >
            Deep Dive
          </Button>
        </Stack>
      )}
      {/* 2nd part */}

      {/* 3rd part */}
      <Stack
        onClick={handleChartsOpen}
        p="1rem"
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ cursor: "pointer", height: "10%", backgroundColor: "bgColor" }}
      >
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          Charts
        </Typography>
        <Box
          sx={{
            color: "white",
            cursor: "pointer",
          }}
        >
          {iconCharts}
        </Box>
      </Stack>
      {/* 3rd part */}

      {/* 4th part */}
      {chartsOpen && (
        <Stack
          p="1rem"
          sx={{ height: "79%", width: "100%", backgroundColor: "bgColor" }}
        >
          {/* charts */}
          <Box sx={{ height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                // border: "4px solid blue",
                height: "10%",
              }}
            >
              <Box
                sx={{
                  minWidth: "40%",
                  // border: "1px solid yellow",
                }}
              >
                <Select
                  value={chartType}
                  onChange={handleChartChange}
                  sx={{
                    width: "100%",
                    color: "white",
                    backgroundColor: "#1c2427",
                    padding: "0.2rem 0",
                    border: "1px solid #3e5056",
                    fontSize: ".75rem",
                    borderRadius: "5px",
                    height: "100%",
                  }}
                >
                  {chartData.map((chart, index) => {
                    return (
                      <Option key={index} value={chart.type}>
                        {chart.name}
                      </Option>
                    );
                  })}
                </Select>
              </Box>
              {channelMovementActive ? (
                <Box sx={{ width: "40%" }}>
                  <Select
                    sx={{
                      width: "100%",
                      color: "white",
                      backgroundColor: "#1c2427",
                      padding: "0.2rem 0",
                      borderRadius: "5px",
                      fontSize: ".75rem",
                      height: "100%",
                    }}
                    onChange={(e) => setLineFilter(e.target.value)}
                  >
                    {channelMovementFilters()}
                  </Select>
                </Box>
              ) : null}
              <Tooltip
                title={
                  <Typography sx={{ fontSize: ".8rem", color: "white" }}>
                    Full Screen
                  </Typography>
                }
                placement="right"
                arrow
              >
                <SvgIcon
                  component={FullscreenIcon}
                  sx={{
                    cursor: "pointer",
                    color: "white",
                    width: "10%",
                    fontSize: "30px",
                  }}
                  onClick={handleChartClick}
                />
              </Tooltip>
            </Box>

            {chartData?.length > 0 ? (
              <Box ref={divRef} sx={{ height: "90%" }}>
                {chartRender()}
              </Box>
            ) : (
              <Box component={"span"}>Error occurred :(</Box>
            )}
          </Box>
          <Button
            sx={{
              width: "20%",
              margin: "auto",
              padding: "0.5 1rem",
              border: "1px solid #d6ff41",
              color: "#d6ff41",
              "&:hover": {
                backgroundColor: "#d6ff41",
                color: "black",
              },
              textTransform: "capitalize",
              fontSize: ".75rem",
            }}
            onClick={() => setDeepDive(true)}
          >
            Deep Dive
          </Button>
        </Stack>
      )}
      {/* 4th part */}
      {showPopUp && chartData?.length > 0 && (
        <PopUp popRef={popRef} onClose={handlePopUpClose}>
          {chartRenderForPopUp()}
        </PopUp>
      )}
    </Stack>
  );
};

export default Sum;
