import React, { useRef, useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const Option = (props) => {
    return (
        <div className="custom-select-option">
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const ActiveHeirarchyAndCampaignFilter = (props) => {

    const DivRef = useRef(null);
    const Analyse = JSON.parse(localStorage.getItem("Analyse"));
    const [UrlTab, setUrlTab] = useState("")

    useEffect(() => {
        const url = window.location.href;
        const text = url.split('/');
        const tab = text[text.length - 1];
        setUrlTab(tab);
    }, [Analyse])

    const handleFilterClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setFilterTabShow(false)
        }
    }

    return (
        <div className="col-xl-12" onClick={(e) => {
            handleFilterClick(e)
        }}>
            <div className="card-body filter-bg p-0">
                <div className="card custom-summary filter-active card-min-height" ref={DivRef} style={{ width: props.OnlyHeirarchyFilter ? "20%" : ""}}>
                    <div className="card-body p-0">
                        <div className="">
                            <div className="custom-header mb-1">
                                <span className="header-title">
                                    <h3 className="data-spends my-2 px-2">Analytics Filter</h3>
                                    <h3 className="m-0" onClick={() => props.setFilterTabShow(false)}><i className="fa fa-times cursor-pointer" aria-hidden="true"></i> &nbsp;</h3>
                                </span>
                            </div>
                            <div className="custom-body" style={{ overflowX: 'hidden', overflowY: "scroll", height: '100%' }}>

                                <div className="p-0" style={{ height: 'auto' }}>
                                    <div className="row">

                                        {/* SELECTED HEIRARCHY FILTERS */}
                                        <DragDropContext onDragEnd={props.onDragEnd}>
                                            <Droppable droppableId={'active'}>
                                                {(provided) => (
                                                    <div className={props.OnlyHeirarchyFilter ? "col-sm-12 cm-drop" : "col-sm-6 cm-drop"} {...provided.droppableProps} ref={provided.innerRef}>


                                                        <h6 className="m-2" htmlFor="chart1"> <b>Heirarchy Filters</b></h6>
                                                        {parseInt(props.ShowComp) === 1 && props.SelectedChart === "overview" ?
                                                            <>
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input login-checkbox m-2"
                                                                    name="check"
                                                                    checked={parseInt(props.ShowCompValue) === 1 ? true : false}
                                                                    onChange={(e) => {
                                                                        if (parseInt(props.ShowCompValue) === 1) {
                                                                            props.setShowCompValue(0)

                                                                        } else {
                                                                            props.setShowCompValue(1)
                                                                        }
                                                                        props.setFetchCall(true)
                                                                    }
                                                                    }
                                                                />
                                                                <label>Show competition</label>
                                                            </>
                                                            : null}
                                                        {props.Fixed.length > 0 ?
                                                            props.Fixed.map((item, i) => {
                                                                return (
                                                                    <div key={i}>
                                                                        <label className="m-2" htmlFor="chart1">{item.id} : {item.value} </label><br></br>
                                                                    </div>
                                                                )
                                                            })
                                                            : null
                                                        }
                                                        {
                                                            props.ActiveFilters.map((item, index) => {
                                                                return (
                                                                    <Draggable key={item.name} draggableId={item.name} index={index}>
                                                                        {(provided) => (
                                                                            <span ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="btn mb-3 cm-drop-btn">
                                                                                <div className="drop-header">
                                                                                    <p className="m-0 text-left">{item.name}</p>
                                                                                    {/* <p className="m-0"><i className="fa fa-minus" aria-hidden="true"></i></p> */}
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-sm-12 ">
                                                                                        <div className="drop-body text-left">
                                                                                            {/* <label className="m-0" htmlFor="chart1"> Chart 1</label> */}
                                                                                            <ReactSelect
                                                                                                options={item.filters}
                                                                                                isMulti
                                                                                                closeMenuOnSelect={false}
                                                                                                hideSelectedOptions={false}
                                                                                                components={{
                                                                                                    Option
                                                                                                }}
                                                                                                onChange={(selected) => {
                                                                                                    if (selected !== null && selected.length > 0) {
                                                                                                        if (selected[selected.length - 1].value === 'all') {
                                                                                                            if (UrlTab === "grain_contri") {
                                                                                                                return props.onChangeFilter(selected[selected.length - 1], index, "Selected", [])
                                                                                                            } else {
                                                                                                                return props.onChangeFilter(selected[selected.length - 1], index)
                                                                                                            }
                                                                                                        } else {
                                                                                                            for (var i = 0; i < selected.length; i++) {
                                                                                                                if (selected[i].value === 'all') {
                                                                                                                    selected.splice(i, 1)
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    if (UrlTab === "grain_contri") {
                                                                                                        return props.onChangeFilter(selected, index, "Selected", [])
                                                                                                    } else {
                                                                                                        return props.onChangeFilter(selected, index)
                                                                                                    }
                                                                                                }}
                                                                                                allowSelectAll={true}
                                                                                                value={item.Selected ? item.Selected : null}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </span>
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )}
                                            </Droppable>

                                        </DragDropContext>

                                        {/* SELECTED CHANNEL FILTERS */}

                                        {!props.OnlyHeirarchyFilter ?
                                            <DragDropContext onDragEnd={props.onCompDragEnd}>

                                                <Droppable droppableId={'active'}>
                                                    {(provided) => (
                                                        <div className="col-sm-6 cm-drop" {...provided.droppableProps} ref={provided.innerRef}>
                                                            <h6 className="m-2" htmlFor="chart1"> <b>Channel Filters</b></h6>
                                                            {
                                                                props.CompActiveFilters.map((item, index) => {
                                                                    return (
                                                                        <Draggable key={item.name} draggableId={item.name} index={index}>
                                                                            {(provided) => (
                                                                                <span ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="btn mb-3 cm-drop-btn">
                                                                                    <div className="drop-header">
                                                                                        <p className="m-0 text-left">{item.name}</p>
                                                                                        {/* <p className="m-0"><i className="fa fa-minus" aria-hidden="true"></i></p> */}
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12">
                                                                                            <div className="drop-body text-left">
                                                                                                {/* <label className="m-0" htmlFor="chart1"> Chart 1</label> */}
                                                                                                <ReactSelect
                                                                                                    options={item.filters}
                                                                                                    isMulti
                                                                                                    closeMenuOnSelect={false}
                                                                                                    hideSelectedOptions={false}
                                                                                                    components={{
                                                                                                        Option
                                                                                                    }}
                                                                                                    onChange={(selected) => {
                                                                                                        if (selected !== null && selected.length > 0) {
                                                                                                            if (selected[selected.length - 1].value === 'All') {
                                                                                                                if (UrlTab === "grain_contri") {
                                                                                                                    return props.onCompChangeFilter(selected[selected.length - 1], index, "Selected")
                                                                                                                } else {
                                                                                                                    return props.onCompChangeFilter(selected[selected.length - 1], index)
                                                                                                                }
                                                                                                            } else {
                                                                                                                for (var i = 0; i < selected.length; i++) {
                                                                                                                    if (selected[i].value === 'All') {
                                                                                                                        selected.splice(i, 1)
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                        if (UrlTab === "grain_contri") {
                                                                                                            return props.onCompChangeFilter(selected, index, "Selected")
                                                                                                        } else {
                                                                                                            return props.onCompChangeFilter(selected, index)
                                                                                                        }
                                                                                                    }}
                                                                                                    allowSelectAll={true}
                                                                                                    value={item.Selected ? item.Selected : null}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </span>
                                                                            )}
                                                                        </Draggable>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )}
                                                </Droppable>

                                            </DragDropContext>
                                            : null}
                                    </div>

                                </div>

                            </div>
                            {/* <div className="custom-footer mb-1 text-center">
											<button className="btn simulator-btn-info mx-2" type="type">Apply</button>
										</div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActiveHeirarchyAndCampaignFilter