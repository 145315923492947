import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import * as HC_exporting from "highcharts/modules/exporting";
HC_exporting(Highcharts);

const LineChart = (props) => {
  let graphData = [];
  for (let index = 0; index < props.data.chartdata?.length; index++) {
    const element = props.data.chartdata[index];
    const obj = {
      type: "line",
      name: element.name,
      marker: element.marker,
      data: element.data,
      showInLegend: true,
      color: element.color,
      dataLabels: {
        enabled: props.data.show_label,
        allowOverlap: true,
        formatter: function (e) {
          if ("marker" in this.point) {
            return (
              "<div style='color:#ffffff;font-family:Inter;font-size:10px;background-color:#000000;'>" +
              this.x +
              "</div>"
            );
          } else {
            return "";
          }
        },
        useHTML: true,
      },
    };
    graphData.push(obj);
  }

  const options = {
    title: {
      text: props.data.charttitle,
      style: {
        color: "#D6FF41",
        fontSize: 14,
      },
    },
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: "#1c2427",
      type: "line",
      height: props.ChartHeight,
      showAxes: true,
    },
    xAxis: {
      visible: true,
      lineWidth: 1,
      title: {
        enabled: true,
        text: props.data.xtitle,
        style: {
          color: "#D6FF41",
        },
      },
      labels: {
        style: {
          color: "#FFFFFF",
        },
      },
      categories: props.data.labels,
    },
    yAxis: {
      visible: true,
      lineWidth: 1,
      title: {
        enabled: true,
        text: props.data.ytitle,
        style: {
          color: "#D6FF41",
        },
      },
      labels: {
        formatter: function () {
          return this.value + " %";
        },
        style: {
          color: "#FFFFFF",
        },
      },
    },
    tooltip: {
      enabled: false,
      headerFormat:
        '<div style="padding:0;background-color:#ffffff;color:#000000;"><span style="font-size:10px;">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="padding:0">{series.name}: </td>' +
        '<td style="padding:0;"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: "</table></div>",
      shared: true,
      useHTML: true,
      borderColor: "#ffffff",
      backgroundColor: "#ffffff",
      borderRadius: 8,
    },
    legend: {
      enabled: true,
      itemStyle: {
        color: "#fff",
      },
      itemHoverStyle: {
        color: "#fff",
      },
    },
    series: graphData,
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          enabled: true,
          className: "highcharts-contextbutton",
          symbol: "menu",
          symbolStroke: "#ffffff",
          theme: {
            fill: "#1c2427",
          },
          titleKey: "",
          menuItems: ["viewFullscreen"],
        },
      },
    },
  };

  return (
    <>
      {graphData?.length === props.data.chartdata?.length ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : null}
    </>
  );
};

export default LineChart;
