import React, { useState, useEffect } from "react";
// import {
//   Stack,
//   Typography,
//   Box,
//   Button,
//   SvgIcon,
//   useMediaQuery,
//   useTheme,
// } from "@mui/material";
// import { Stack, Typography, Box, Button, SvgIcon } from "@mui/material";
import DateInput from "./DateInput";
import { Select, Option } from "./CustomSelect";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import { Stack, Box, useTheme, useMediaQuery,CssBaseline,Typography,SvgIcon, Button } from "@mui/material";
const Filters = (props) => {
  const [none, setNone] = useState(false);

  const displayNoneHandler = () => {
    setNone(false);
  };

  const [validEndDate, setValidEndDate] = useState(
    new Date(props.defaultStartDate) > new Date(props.defaultEndDate)
      ? props.defaultStartDate
      : props.defaultEndDate
  );

  useEffect(() => {
    setValidEndDate(
      new Date(props.defaultStartDate) > new Date(props.defaultEndDate)
        ? props.defaultStartDate
        : props.defaultEndDate
    );
    props.setDefaultEndDate(
      new Date(props.defaultStartDate) > new Date(props.defaultEndDate)
        ? props.defaultStartDate
        : props.defaultEndDate
    );
  }, [props.defaultStartDate, props.defaultEndDate]);

  const iconSummary = !none ? (
    <SvgIcon
      component={RemoveTwoToneIcon}
      sx={{
        cursor: "pointer",
        color: "white",
        fontSize: "24px",
        fontWeight: 700,
      }}
    />
  ) : (
    <SvgIcon
      component={AddTwoToneIcon}
      sx={{
        cursor: "pointer",
        color: "white",
        fontSize: "24px",
        fontWeight: 700,
      }}
    />
  );

  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        //  height: "25%",
        // border: "2px solid blue",
        //  height:"100%"
        height: isSmall || isMedium ? "400px" : "25%",
      }}
    >
      <Stack
        onClick={displayNoneHandler}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          cursor: "pointer",
          backgroundColor: "bgColor",
          borderBottom: "1px solid black",
          height: "20%",
          // border: "2px solid green",
        }}
      >
        <Typography sx={{ color: "white", fontSize: "0.9rem" }}>
          Apply Filters
        </Typography>
        {/* <Box
          sx={{
            color: "#d6ff41",
          }}
        >
          {iconSummary}
        </Box> */}
      </Stack>

      {/* apply filters start....... */}
      <Stack
        sx={{
          display: none ? "none" : "flex",
          height: "80%",
          justifyContent: "space-around",
        }}
      >
        {/* timeperiod start */}
        <Stack
          direction={"row"}
          gap={1}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={
            {
              // padding: "0.5rem 0",
            }
          }
        >
          <Typography sx={{ color: "themeColor", fontSize: "0.75rem" }}>
            Time Period:{" "}
          </Typography>

          <Typography sx={{ color: "white", fontSize: "0.75rem" }}>
            From:{" "}
          </Typography>

          <DateInput
            type={"date"}
            value={props.defaultStartDate}
            onChange={props.setDefaultStartDate}
            min={props.startDate}
            max={props.endDate}
            disabledFlagSubmit={props.setDisabledFlag}
            setPayload={props.setPayload}
            setDisabledFlag={props.setDisabledFlag}
            setIsSubmit={props.setIsSubmit}
            setTeampayload={props.setTeampayload}
            setCityPayload={props.setCityPayload}
            setDaypartpayload={props.setDaypartpayload}
            setMatchIdPayload={props.setMatchIdPayload}
            setWeekflagpayload={props.setWeekflagpayload}
          />
          <Typography sx={{ color: "white", fontSize: "0.75rem" }}>
            To:{" "}
          </Typography>

          <DateInput
            type={"date"}
            // value={props.defaultEndDate}
            value={validEndDate}
            onChange={props.setDefaultEndDate}
            min={props.defaultStartDate}
            max={props.endDate}
            disabledFlagSubmit={props.setDisabledFlag}
            setPayload={props.setPayload}
            setDisabledFlag={props.setDisabledFlag}
            setIsSubmit={props.setIsSubmit}
            setTeampayload={props.setTeampayload}
            setCityPayload={props.setCityPayload}
            setDaypartpayload={props.setDaypartpayload}
            setMatchIdPayload={props.setMatchIdPayload}
            setWeekflagpayload={props.setWeekflagpayload}
          />
        </Stack>
        {/* time period selection end...... */}

        {/* match selection start */}
        <Stack
          sx={{
            height: "50%",
            justifyContent: "space-around",
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              direction: "row",
              flexWrap: "wrap",
              gap: 1,
              pointerEvents:
                new Date(props.defaultEndDate) < new Date("2024-03-22")
                  ? "none"
                  : "all",
              opacity:
                new Date(props.defaultEndDate) < new Date("2024-03-22")
                  ? 0.3
                  : 1,
            }}
          >
            <Typography sx={{ color: "themeColor", fontSize: "0.75rem" }}>
              Match Filters:{" "}
            </Typography>
            <Stack direction={"row"} gap={1}>
              <Select
                value={props.defaultTeam}
                onChange={(e) => {
                  props.setDefaultTeam(e.target.value);
                  props.setTeampayload({ team: e.target.value });
                  props.setDisabledFlag(false);
                  props.setIsSubmit(false);
                }}
                selected={props.defaultTeam}
                sx={{
                  backgroundColor: "bgColor",
                  color: "white",
                  fontSize: "0.75rem",
                  borderRadius: "5px",
                  padding: "0.1rem 0.15rem",
                  border: "1px solid #3E5056",
                }}
              >
                {props.allTeams &&
                  props.allTeams.map((team) => {
                    return (
                      <Option key={team.value} value={team.value}>
                        {team.label}
                      </Option>
                    );
                  })}
              </Select>
            </Stack>
            {/* </Box> */}

            {/* match selection end ...................................*/}

            {/* team vs team selection start */}
            <Box
              direction={"column"}
              gap={1}
              sx={{ backgroundColor: "bgColor" }}
            >
              <Stack>
                <Stack direction={"row"}>
                  <Select
                    value={props.defaultMatchId}
                    onChange={(e) => {
                      props.setDefultMatchId(e.target.value);
                      props.setDisabledFlag(false);
                      props.setIsSubmit(false);
                      props.setMatchIdPayload({
                        matchid: e.target.value,
                      });
                    }}
                    selected={props.defaultMatchId}
                    sx={{
                      backgroundColor: "bgColor",
                      color: "white",
                      fontSize: "0.75rem",
                      borderRadius: "5px",
                      padding: "0.1rem 0.15rem",
                      border: "1px solid #3E5056",
                    }}
                  >
                    {props.allMatchId &&
                      props.allMatchId.map((allMatchId) => {
                        return (
                          <Option
                            key={allMatchId.value}
                            value={allMatchId.value}
                          >
                            {allMatchId.label}
                          </Option>
                        );
                      })}
                  </Select>
                </Stack>
              </Stack>
            </Box>

            {/* team vs team selection end */}

            {/* location selection start */}
            <Box
              direction={"column"}
              gap={1}
              sx={{ backgroundColor: "bgColor" }}
            >
              <Stack direction={"row"} gap={1}>
                <Select
                  value={props.defaultCity}
                  onChange={(e) => {
                    props.setDefaultCity(e.target.value);
                    props.setCityPayload({ city: e.target.value });
                    props.setDisabledFlag(false);
                    props.setIsSubmit(false);
                  }}
                  selected={props.defaultCity}
                  sx={{
                    backgroundColor: "bgColor",
                    color: "white",
                    fontSize: "0.75rem",
                    borderRadius: "5px",
                    padding: "0.1rem 0.15rem",
                    border: "1px solid #3E5056",
                  }}
                >
                  {props.allCities &&
                    [...new Set(props.allCities)].map((day) => (
                      <Option key={day} value={day}>
                        {day}
                      </Option>
                    ))}
                </Select>
              </Stack>
            </Box>
            {/* location selection end */}

            {/* days selection start */}
            <Box
              direction={"column"}
              gap={1}
              sx={{ backgroundColor: "bgColor" }}
            >
              <Stack direction={"row"} gap={1}>
                <Select
                  value={props.defaultWeekFlag}
                  onChange={(e) => {
                    props.setDefaultWeekFlag(e.target.value);
                    props.setWeekflagpayload({ wd_flag: e.target.value });
                    props.setDisabledFlag(false);
                    props.setIsSubmit(false);
                  }}
                  selected={props.defaultWeekFlag}
                  sx={{
                    backgroundColor: "bgColor",
                    color: "white",
                    fontSize: "0.75rem",
                    borderRadius: "5px",
                    padding: "0.1rem 0.15rem",
                    border: "1px solid #3E5056",
                  }}
                >
                  {props.allWeekFlag &&
                    [...new Set(props.allWeekFlag)].map((day) => (
                      <Option key={day} value={day}>
                        {day}
                      </Option>
                    ))}
                </Select>
              </Stack>
            </Box>
            {/* days selection end */}

            {/* match timing selection start */}
            <Box
              direction={"column"}
              gap={1}
              sx={{ backgroundColor: "bgColor" }}
            >
              <Stack direction={"row"} gap={1}>
                <Select
                  value={props.defaultDaypart}
                  onChange={(e) => {
                    props.setDefaultDaypart(e.target.value);
                    props.setDaypartpayload({ daypart: e.target.value });
                    props.setDisabledFlag(false);
                    props.setIsSubmit(false);
                  }}
                  selected={props.defaultDaypart}
                  sx={{
                    backgroundColor: "bgColor",
                    color: "white",
                    fontSize: "0.75rem",
                    borderRadius: "5px",
                    padding: "0.1rem 0.15rem",
                    border: "1px solid #3E5056",
                  }}
                >
                  {props.allDaypart &&
                    [...new Set(props.allDaypart)].map((time) => (
                      <Option key={time} value={time}>
                        {time}
                      </Option>
                    ))}
                </Select>
              </Stack>
            </Box>
            {/* match timing selection end */}
          </Box>
          {/* match selection end */}

          {/* channel filters ################## */}
          <Box
            sx={{
              display: "flex",
              direction: "row",
              alignItems: "center",
              gap: 1,
              pointerEvents:
                new Date(props.defaultEndDate) < new Date("2024-03-22")
                  ? "none"
                  : "all",
              opacity:
                new Date(props.defaultEndDate) < new Date("2024-03-22")
                  ? 0.3
                  : 1,
            }}
          >
            <Typography sx={{ color: "themeColor", fontSize: "0.75rem" }}>
              Channel Filters:{" "}
            </Typography>
            {/* <Typography sx={{ color: "themeColor", fontSize: "0.75rem" }}>
              {props.channelFilters[0]?.display_name}
            </Typography>
            <Stack direction={"row"} gap={1}>
              <Select
                value={props.defaultH0Value}
                onChange={(e) => {
                  props.setDefaultH0Value(e.target.value);
                  props.setDisabledFlag(false);
                  props.setIsSubmit(false);
                  props.setChartDataCamp(
                    props.addToFilter(props.chartDataCamp, {
                      h0: e.target.value,
                    })
                  );
                }}
                selected={props.defaultH0Value}
                sx={{
                  backgroundColor: "bgColor",
                  color: "white",
                  fontSize: "0.75rem",
                  borderRadius: "5px",
                  padding: "0.1rem 0.15rem",
                  border: "1px solid #3E5056",
                }}
              >
                {props.channelFilters[0]?.values &&
                  props.channelFilters[0]?.values.map((team) => {
                    return (
                      <>
                        <Option key={team.value} value={team.value}>
                          {team.label}
                        </Option>
                      </>
                    );
                  })}
              </Select>
            </Stack> */}
            {/* <MultiSelect /> */}
            {/* <Typography sx={{ color: "themeColor", fontSize: "0.75rem" }}>
              {props.channelFilters[1]?.display_name}
            </Typography> */}
            <Stack direction={"row"} gap={1}>
              <Select
                value={props.defaultH1Value}
                onChange={(e) => {
                  props.setDefaultH1Value(e.target.value);
                  props.setDisabledFlag(false);
                  props.setIsSubmit(false);
                  props.setChartDataCamp(
                    props.addToFilter(props.chartDataCamp, {
                      h1: e.target.value,
                    })
                  );
                }}
                selected={props.defaultH1Value}
                sx={{
                  backgroundColor: "bgColor",
                  color: "white",
                  fontSize: "0.75rem",
                  borderRadius: "5px",
                  padding: "0.1rem 0.15rem",
                  border: "1px solid #3E5056",
                }}
              >
                {props.channelFilters[1]?.values &&
                  props.channelFilters[1]?.values.map((team) => {
                    return (
                      <Option key={team.value} value={team.value}>
                        {team.label}
                      </Option>
                    );
                  })}
              </Select>
            </Stack>
          </Box>

          {/* channel filters ###################### */}
        </Stack>

        {/* buttons reset and submit */}
        <Stack
          direction={isSmall || isMedium ? "column" : "row"}
          justifyContent={"space-between"}
          sx={{
            //  border: "2px solid blue",
             height: (isSmall||isMedium)?"25%":"20%"
             }}

        >
          {/* kpi selection start */}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <Typography sx={{ color: "themeColor", fontSize: "0.75rem" }}>
                Business KPI:{" "}
              </Typography>

              <Select
                value={props.defaultKpiFilter}
                onChange={(e) => {
                  props.setDefaultKpiFilter(e.target.value);
                  props.setDisabledFlag(true);
                  props.setFetchCallCamp(true);
                  props.setIsSubmit(true);
                }}
                selected={props.defaultKpiFilter}
                sx={{
                  backgroundColor: "bgColor",
                  color: "white",
                  fontSize: "0.75rem",
                  borderRadius: "5px",
                  padding: "0.1rem 0.15rem",
                  border: "1px solid #3E5056",
                }}
              >
                {props.allKpi &&
                  props.allKpi.map((kpi) => {
                    return (
                      <Option key={kpi.value} value={kpi.value}>
                        {kpi.label}
                      </Option>
                    );
                  })}
              </Select>
            </Stack>

            {/* kpi selection end */}
            {/* ........................... */}
          </Box>
          {/* kpi end */}
          <Stack
            direction={"row"}
            gap={1}
            // style={{ border: "2px solid yellow" }}
          >
            <Button
              onClick={() => {
                props.setPayload({});
                props.setIsSubmit(false);
                props.setDisabledFlag(false);
                props.setTeampayload("");
                props.setCityPayload("");
                props.setDaypartpayload("");
                props.setMatchIdPayload("");
                props.setWeekflagpayload("");
              }}
              sx={{
                // backgroundColor: "#d6ff41",
                border: "1px solid #d6ff41",
                fontSize: "0.75rem",
                width: "100px",
                borderRadius: "5px",
                textAlign: "center",
                cursor: "pointer",
                color: "#d6ff41",
                pointerEvents:
                  new Date(props.defaultEndDate) < new Date("2024-03-22")
                    ? "none"
                    : "all",
                opacity:
                  new Date(props.defaultEndDate) < new Date("2024-03-22")
                    ? 0.3
                    : 1,
                padding: "0.1rem 0",
                "&:hover": {
                  color: "black",
                  backgroundColor: "#d6ff41", // Change color on hover
                },
              }}
            >
              match reset
            </Button>
            <Button
              onClick={() => {
                props.setFetchCallCamp(true);
                props.setDisabledFlag(true);
                props.setIsSubmit(true);
              }}
              disabled={props.disabledFlag}
              sx={{
                // backgroundColor: props.disabledFlag ? "" : "#d6ff41",
                fontSize: "0.75rem",
                width: "100px",
                borderRadius: "5px",
                textAlign: "center",
                cursor: "pointer",
                padding: "0.1rem 0",
                color: "#d6ff41",
                border: !props.disabledFlag
                  ? "1px solid #d6ff41"
                  : "1px solid #3E5056",
                "&:hover": {
                  color: "black",
                  backgroundColor: "#d6ff41", // Change color on hover
                },
              }}
            >
              submit
            </Button>
          </Stack>
        </Stack>
        {/* button reset and submit end......... */}
      </Stack>
      {/* apply filters end......... */}
    </Box>
  );
};

export default Filters;
