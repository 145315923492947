import React from 'react'

const FooterOptimise = (props) => {
    return (
        <tfoot>
            <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                {/* <th></th> */}
                <th colSpan="3">
                    <div className='d-flex justify-content-sm-between'>
                        {JSON.stringify(props.NewFixedData) === JSON.stringify(props.NewData) ? (
                            <button className="btn edit-spends-btn-info custom-input-box" type="button" style={{ margin: 0 }}>
                                Re-Optimise
                            </button>
                        ) : (
                            <button
                                onClick={() => {props.Optimise()
                                    props.setRe(prev => prev + 1);
                                }}
                                className="btn channel-allowcation-btn-info custom-input-box"
                                type="button"
                                style={{ padding: '5px 10px' }}
                            >
                                Re-Optimise
                            </button>
                        )}
                        <button
                            className="btn channel-allowcation-btn-info f-right custom-input-box"
                            type="button"
                            // onClick={() => props.AllowEdit ?  props.setSaveResult(true) : ""}
                            onClick={() => props.setSaveResult(true)}
                            style={{ padding: '5px 10px' }}
                        >
                            Save Result
                        </button>
                        <button className="btn channel-allowcation-btn-info  custom-input-box" onClick={props.DownloadOptimizer}>Download Data</button>
                    </div>
                </th>
            </tr>
        </tfoot>
    )
}

export default FooterOptimise