import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Bar } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const StackChart = (props) => {
  console.log('props::: ', props);
  const data = {
    labels: ['Spends', 'Growth'],
    datasets: props.data.chartdata
  };

  const options = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    scales: {
      y: {
        stacked: true,
        title: {
          display: true,
          text: props.data.ytitle,
          color: localStorage.getItem("CurrentTheme") === "Dark" ?
            "#D6FF41" :
            localStorage.getItem("ColorCode") === "Lavender" ?
              "#9384bf" :
              localStorage.getItem("ColorCode") === "Sapphire" ?
                "#6b75af" :
                localStorage.getItem("ColorCode") === "Teal" ?
                  "#57898c" :
                  localStorage.getItem("ColorCode") === "Cyan" ?
                    "#21afc2" :
                    localStorage.getItem("ColorCode") === "Goldenrod" ?
                      "#dca25b" : ""
        },
        ticks: {
          color: "#ffffff",
          fontSize: 12
        },
        gridLines: {
          display: false
        }
      },
      x: {
        stacked: true,
        title: {
          display: true,
          text: props.data.xtitle,
          color: localStorage.getItem("CurrentTheme") === "Dark" ?
            "#D6FF41" :
            localStorage.getItem("ColorCode") === "Lavender" ?
              "#9384bf" :
              localStorage.getItem("ColorCode") === "Sapphire" ?
                "#6b75af" :
                localStorage.getItem("ColorCode") === "Teal" ?
                  "#57898c" :
                  localStorage.getItem("ColorCode") === "Cyan" ?
                    "#21afc2" :
                    localStorage.getItem("ColorCode") === "Goldenrod" ?
                      "#dca25b" : ""
        },
        ticks: {
          color: localStorage.getItem("CurrentTheme") === "Dark"?  '#ffffff' : "1c2427",
          fontSize: 12,
          // stepSize: 10,
          beginAtZero: true,
          callback: function (label, index, labels) {
            return label + "%"
          }
        },
        grid: {
          color: localStorage.getItem("CurrentTheme") === "Dark"?  '#ffffff' : "1c2427",
          display: true,
          borderDash: [1],
          tickLength: 0
        }
      }
    },
    plugins: {
      title: {
        display: true,
        text: props.data.charttitle,
        color: localStorage.getItem("CurrentTheme") === "Dark" ?
          "#D6FF41" :
          localStorage.getItem("ColorCode") === "Lavender" ?
            "#9384bf" :
            localStorage.getItem("ColorCode") === "Sapphire" ?
              "#6b75af" :
              localStorage.getItem("ColorCode") === "Teal" ?
                "#57898c" :
                localStorage.getItem("ColorCode") === "Cyan" ?
                  "#21afc2" :
                  localStorage.getItem("ColorCode") === "Goldenrod" ?
                    "#dca25b" : ""
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem, data) {
            return tooltipItem.value + '%';
          }
        }
      },
      datalabels: {
        color: '#ffffff',
        labels: {
          title: {
            font: {
              weight: 'bold'
            }
          }
        }
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: localStorage.getItem("CurrentTheme") === "Dark" ? "#ffffff" : "#1c2427",
          fontSize: 18
        }
      }
    }
  };

  return (
    <Bar options={options} data={data} height={props.ChartHeight / 2} />
  )
}

export default StackChart