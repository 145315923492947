import React from "react";
import Download from '../../images/download-icon.png'

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import * as HC_exporting from "highcharts/modules/exporting";
HC_exporting(Highcharts);

class LineChart extends React.Component {

  render() {
    let graphData = [];
    for (let index = 0; index < this.props.series.length; index++) {
      const element = this.props.series[index];
      const obj = {
        type: element.name === "" ? "line" : "scatter",
        name: element.name,
        marker: element.marker,
        data: element.data,
        showInLegend: false,
        color: "#D6FF41",
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          formatter: function (e) {
            // console.log(this.point.options)
            if ("marker" in this.point) {
              // console.log(this.point.options)
              return (
                "<div style='color:#ffffff;font-family:Inter;'>" +
                this.point.marker.name +
                "<br>" +
                this.x +
                "</div>"
              );
            } else {
              return "";
            }
          },
          useHTML: true
        },
      };
      graphData.push(obj);
    }

    const options = {
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      chart: {
        backgroundColor: "#1c2427",
        type: "line",
        height: ((9 / 16) * 100) - 5 + "%",
        // height: "65%",
        showAxes: true,
      },
      xAxis: {
        visible: true,
        lineWidth: 1,
        title: {
          enabled: true,
          text: this.props.xunits,
          style: {
            color: "#D6FF41",
          },
        },
        labels: {
          style: {
            color: "#FFFFFF",
          },
        },
        plotBands: [
          {
            from: this.props.plotBands.from,
            to: this.props.plotBands.to,
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "rgb(214,255,65,0.2)"],
                [0.5, "rgba(214,255,65,0.2)"],
                [1, "rgba(214,255,65)"],
              ],
            },
          },
        ],
        categories: this.props.labels,
      },
      yAxis: {
        visible: true,
        lineWidth: 1,
        title: {
          enabled: true,
          text: this.props.yunits,
          style: {
            color: "#D6FF41",
          },
        },
        labels: {
          formatter: function () {
            return this.value + " %";
          },
          style: {
            color: "#FFFFFF",
          },
        },
      },
      tooltip: {
        headerFormat: '<div style="padding:0;background-color:#ffffff;color:#000000;"><span style="font-size:10px;">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="padding:0">{series.name}: </td>' +
          '<td style="padding:0;"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: "</table></div>",
        shared: true,
        useHTML: true,
        borderColor: '#ffffff',
        backgroundColor: '#ffffff',
        borderRadius: 8
      },
      plotOptions: {
        series: {
          lineColor: "#D6FF41",
        },
      },
      series: graphData,
      exporting: {
        enabled: false,
        csv: {

        },
        buttons: {
          contextButton: {
            className: "highcharts-contextbutton",
            enabled: true,
            symbol: 'download',
            symbolStroke: "#ffffff",
            theme: {
              fill: '#1c2427',
            },
            titleKey: ""
          }
        }
      }
    };

    return (
      <>
        <div className="row align-items-center justify-content-sm-between pb-24">
          <div className="col-sm-11">
            <p className="card-title-lable">{this.props.ChartTitle}</p>
          </div>
          <div className="col-sm-1 d-flex align-items-center justify-content-sm-end">
            <img src={Download} alt="" style={{ float: "right", cursor: 'pointer' }} title="Download" onClick={() => { this.props.DownloadData() }} />
            {/* <i
                  className="fas fa-download px-2 mx-2"
                  style={{
                    float: "right",
                    fontSize: "25px",
                    color: "#fff",
                  }}
                ></i> */}
          </div>
        </div>
        <div className="row align-items-center justify-content-sm-between ">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </>
    );
  }

};

export default LineChart;
