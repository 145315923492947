import { Stack, Button } from "@mui/material";

const Footer = ({ fetchCall, setRePayload, setRefresh, refresh, changed }) => {
  // console.log("changed: ", changed);

  return (
    <Stack
      direction={"row"}
      justifyContent={"center"}
      gap={4}
      sx={{
        backgroundColor: "bgColor",
        padding: "1rem 0",
        height: "10%",
      }}
    >
      <Button
        disabled={changed.length > 0 ? false : true}
        onClick={() => {
          fetchCall(true);
          setRefresh(false);
        }}
        sx={{
          backgroundColor:
            changed.length > 0 ? "themeColor" : "inputBorderColor",
          color: "black",
          "&:hover": {
            backgroundColor: "themeColor",
            color: "black",
          },
        }}
      >
        re-optimize
      </Button>
      <Button
        disabled={changed.length > 0 ? false : true}
        onClick={() => {
          setRePayload({});
          setRefresh(false);
        }}
        sx={{
          backgroundColor: "inputBorderColor",
          color: "white",
          "&:hover": {
            backgroundColor: "inputBorderColor",
            color: "white",
          },
        }}
      >
        reset all
      </Button>
    </Stack>
  );
};

export default Footer;
