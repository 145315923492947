import React, { useState } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const EditDatePopup = (props) => {
  const [startDate, setStartDate] = useState();
  const MyContainer = ({ className, children }) => {
    return (
      <div style={{ padding: "16px", color: "#fff" }}>
        <CalendarContainer className={className}>
          <div style={{ background: "#ffff", color: "#ffff" }}></div>
          <div style={{ position: "relative", background: "#0000" }}>
            {children}
          </div>
        </CalendarContainer>
      </div>
    );
  };

  var date = new Date();
  date.setDate(date.getDate() + 1);

  const setDate = (date) => {
    setStartDate(date);
    props.setEditDate(date);
  };

  const saveData = ()  => {
    props.SaveEditDate();
    // props.setEditDateFlag(!props.EditDateFlag)
  }

  return (
    <>
      <div
        className="modal fade bs-example-modal-center show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Deactivation Date</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={() => props.setEditDateFlag(!props.EditDateFlag)}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body modal-body-custom">
              <div className="text-center">
                {/* <h2 className="modal-title-custom">
                  Are you sure you want to delete this user?
                </h2>
                <p className="m-0 modal-subtitle-custom">
                  This action cannot be undone
                </p> */}
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={startDate}
                    onChange={(date) => setDate(date)}
                    calendarContainer={MyContainer}
                    minDate={date}
                    placeholderText="Select Date"
                  />
                </div>
                <div className="col-sm-7">
                  <span className="error">{props.EditDateError}</span>
                </div>
                <div className="row align-items-center mt-4 ">
                  <div className="col-12 text-center">
                    <button
                      onClick={() => saveData()}
                      className="btn btn-modal-primary"
                    >
                      Edit Date
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditDatePopup;
