import React from "react";
// import Download from '../../images/download-icon.png'

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import * as HC_exporting from "highcharts/modules/exporting";
HC_exporting(Highcharts);

class LineChart extends React.Component {

  render() {
    let graphData = [];
    for (let index = 0; index < this.props.data.chartdata.length; index++) {
      const element = this.props.data.chartdata[index];
      const obj = {
        type: "line",
        name: element.name,
        marker: element.marker,
        data: element.data,
        showInLegend: true,
        color: element.color,
        dataLabels: {
          enabled: this.props.data.show_label,
          allowOverlap: true,
          formatter: function (e) {
            if ("marker" in this.point) {
              return (

                localStorage.getItem('CurrentTheme') === "Dark" ? 
                "<div style='color:#ffffff;font-family:Inter;font-size:10px;background-color:#000000;'>" +
                // this.point.marker.name +
                // "<br>" +
                this.x +
                "</div>" : 
                "<div style='color:#000000;font-family:Inter;font-size:10px;background-color:#ffffff;'>" +
                // this.point.marker.name +
                // "<br>" +
                this.x +
                "</div>"
              );
            } else {
              return "";
            }
          },
          useHTML: true
        },
      };
      graphData.push(obj);
    }

    const options = {
      title: {
        text: this.props.data.charttitle,
        style: {
          color: "#D6FF41",
          fontSize: 14
        },
      },
      credits: {
        enabled: false,
      },
      chart: {
        backgroundColor: 'rgba(0,0,0,0)',
        type: "line",
        height: this.props.ChartHeight,
        showAxes: true,
      },
      xAxis: {
        visible: true,
        lineWidth: 1,
        title: {
          enabled: true,
          text: this.props.data.xtitle,
          style: {
            color: "#D6FF41",
          },
        },
        labels: {
          style: {
            color: localStorage.getItem('CurrentTheme') === "Dark" ? "#FFFFFF" : "#1C2427",
          },
        },
        // plotBands: [
        //   {
        //     from: this.props.plotBands.from,
        //     to: this.props.plotBands.to,
        //     color: {
        //       linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        //       stops: [
        //         [0, "rgb(214,255,65,0.2)"],
        //         [0.5, "rgba(214,255,65,0.2)"],
        //         [1, "rgba(214,255,65)"],
        //       ],
        //     },
        //   },
        // ],
        categories: this.props.data.labels,
      },
      yAxis: {
        visible: true,
        lineWidth: 1,
        title: {
          enabled: true,
          text: this.props.data.ytitle,
          style: {
            color: "#D6FF41",
          },
        },
        labels: {
          formatter: function () {
            return this.value + " %";
          },
          style: {
            color: localStorage.getItem('CurrentTheme') === "Dark" ? "#FFFFFF" : "#1C2427",
          },
        },
      },
      tooltip: {
        enabled: false,
        headerFormat:localStorage.getItem('CurrentTheme') === "Dark" ? '<div style="padding:0;background-color:#ffffff;color:#000000;"><span style="font-size:10px;">{point.key}</span><table>'
        :
        '<div style="padding:0;background-color:#ffffff;color:#1C2427;"><span style="font-size:10px;">{point.key}</span><table>'
        ,
        pointFormat:
          '<tr><td style="padding:0">{series.name}: </td>' +
          '<td style="padding:0;"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: "</table></div>",
        shared: true,
        useHTML: true,
        borderColor: '#ffffff',
        backgroundColor: '#ffffff',
        borderRadius: 8
      },
      legend: {
        enabled: true,
        itemStyle: {
          color: localStorage.getItem('CurrentTheme') === "Dark" ? "#fff" : "#1C2427",
        },
        itemHoverStyle: {
          color: localStorage.getItem('CurrentTheme') === "Dark" ? "#fff" : "#1C2427",
        }
      },
      series: graphData,
      exporting: {
        enabled: false,
        buttons: {
          contextButton: {
            enabled: true,
            className: "highcharts-contextbutton",
            symbol: 'menu',
            symbolStroke: "#ffffff",
            theme: {
              fill: '#1c2427',
            },
            titleKey: "",
            menuItems: ["viewFullscreen"]
          }
        }
      }
    };

    return (
      <>
        {graphData.length === this.props.data.chartdata.length ? <HighchartsReact highcharts={Highcharts} options={options} /> : null}
      </>
    );
  }

};

export default LineChart;