import React, { useState, useEffect, useRef } from "react";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";
import KPISunburst from "./KPISunburst";
import LegendsTable from "./Legends";
import axios from "../../axios";
import axiosDownload from "../../axiosDownload";
import Loader from "../../Components/Loader";
import { useLocation, Link } from "react-router-dom";
import Download from '../../images/download-icon.png'

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AllHeirarchyFilter from "../AllHeirarchyFilter";
import ActiveHeirarchyAndCampaignFilter from "../ActiveHeirarchyAndCampaignFilter";
import { Tooltip } from 'react-tooltip'
import InfoIcon from '../../images/icons8-info.svg'

import LightThemeHexCode from "../LightThemeHexCode";
import useDownloadChart from "../../hooks/useDownloadChart";
import axiosChartDownload from "../../axiosChartDownload";
import domtoimage from "dom-to-image";
import downloadFlag from "../downloadFlag";


const Main = () => {
  const location = useLocation();
  const refGraphDiv = useRef(null);
  const DivRef = useRef(null);

  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  const [loader, setLoader] = useState(false);
  const [KpiSunburstData, setKpiSunburstData] = useState([]);
  const [Summary, setSummary] = useState([]);
  const [Notes, setNotes] = useState('');
  const [Legends, setLegends] = useState([]);
  const [TableKPI, setTableKPI] = useState({});
  const [AllData, setAllData] = useState({});
  // console.log("AllData: ", AllData);
  const [FooterNotes, setFooterNotes] = useState('');

  const [FetchCall, setFetchCall] = useState(false);
  const AnalyticFilter = JSON.parse(localStorage.getItem("AnalyticFilter"));
  const [AnalyticFilterFlag, setAnalyticFilterFlag] = useState(true);

  //Analytical filters state
  const [AllFilters, setAllFilters] = useState([]);
  const [ActiveFilters, setActiveFilters] = useState([]);
  const Fixed = localStorage.getItem("AnalyticFilter") ? AnalyticFilter.Fixed : [];
  const [Chartdata, setChartdata] = useState({});
  // console.log("Chartdata: ", Chartdata);

  const [Downloadchart, setDownloadchart] = useState(false)
  const [FilterTabShow, setFilterTabShow] = useState(false);
  const [ShowSummary, setShowSummary] = useState(0)
  const [DivWidth, setDivWidth] = useState("")
  const [DivHeight, setDivHeight] = useState("")
  const [ChartHeight, setChartHeight] = useState('')
  const [ResetFilterFlag, setResetFilterFlag] = useState(false)


  const [isDownloadingChart, setIsDownloadingChart] = useState(false);
  const [chartContainerID, setChartContainerID] = useState("");
  const [downloadedImageName, setDownloadedImageName] = useState("");
  const [downloadingChartTitle, setDownloadingChartTitle] = useState("");

  const userName = localStorage.getItem("NameSkewb");

  useDownloadChart(
    downloadedImageName,
    downloadingChartTitle,
    isDownloadingChart,
    chartContainerID,
    Fixed,
    ActiveFilters,
    setLoader,
    Analyse.Brand,
    "",
    "",
    "",
    setIsDownloadingChart,
    Analyse.KPI
  );

  useEffect(() => {
    const url = window.location.href;
    const text = url.split('/');
    const tab = text[text.length - 1];
    for (var i = 0; i < Analyse.Menubar.length; i++) {
      if (Analyse.Menubar[i].key === tab) {
        setNotes(Analyse.Menubar[i].notes);
      }
    }
  }, [Analyse])

  useEffect(() => {
    if (AnalyticFilterFlag && AnalyticFilter) {
      setAllFilters(AnalyticFilter.AllFilters)
      setActiveFilters(AnalyticFilter.ActiveFilters)
      setAnalyticFilterFlag(false)
    }
  }, [AnalyticFilter, AnalyticFilterFlag])

  useEffect(() => {
    if (FetchCall && JSON.stringify(Chartdata) !== "{}" && !AnalyticFilterFlag) {
      setLoader(true);
      axios
        .post("get_kpi_prioritization/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          chartdata: Chartdata,
          theme: localStorage.getItem("CurrentTheme"),
          LightThemeColorCode: [localStorage.getItem("ColorCode"), LightThemeHexCode[localStorage.getItem("ColorCode")]],

        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setAllData(response.data.data);
            setKpiSunburstData(response.data.data.kpi);
            setSummary(response.data.data.summary);
            if (response.data.data.show_summary !== undefined) {
              setShowSummary(response.data.data.show_summary)
            }
            setLegends(response.data.data.Legends);
            setFooterNotes(response.data.notes);
            setTableKPI(response.data.data.table);
            setLoader(false);
            setFetchCall(false)
          }
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [Analyse.Brand, Analyse.KPI, Chartdata, FetchCall, AnalyticFilterFlag]);

  const DownloadData = () => {
    setLoader(true);
    axiosDownload
      .post("kpi_prioritization/", { data: AllData, brand: Analyse.Brand, kpi: Analyse.KPI, chartdata: Chartdata  })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);

      }).catch((data) => {
        setLoader(false);
      });
  }

  const downloadPDF = async () => {
    setLoader(true);
    const chartContainers = [
      document.getElementById("kpi_chart"),
    ];
    if (chartContainers.every((container) => container !== null)) {
      try {
        // setLoader(true);
        const dataUrls = await Promise.all(
          chartContainers.map((container) => domtoimage.toPng(container))
        );
        // setLoader(true);
        const response = await axiosDownload.post("kpi_prioritization/", {
          data: AllData,
          theme: localStorage.getItem("CurrentTheme"),
          download_type: "PDF",
          brand: Analyse.Brand, kpi: Analyse.KPI, chartdata: Chartdata
        });
        // setLoader(false);
        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post("download_pdf/create/", {
          images: dataUrls.map((dataUrl) => dataUrl.split(",")[1]),
          document: {
            table1_data: response.data.table1_data,
            footer_content: "Private & Confidential",
            table_heading1: "KPI Sensitivity",
            image_heading1: "KPI Prioritiazation",
            username: userName,
            timeframe: "",
            start_date: "",
            end_date: "",
            titlename: "KPI Prioritiazation",
            category: "",
            brand: Analyse.Brand,
            kpi: Analyse.KPI,
            chartdata: Chartdata,
            tabname: "kpi_prioritization",
            
          },
        });
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    }
  };

  // When Activefilter change : prepare chartdata for input of get api call
  useEffect(() => {
    if (!AnalyticFilterFlag) {
      const chartdata = {};
      if (Fixed.length !== 0) {
        for (var r = 0; r < Fixed.length; r++) {
          const Filter = [];
          Filter.push(Fixed[r].value)
          chartdata[Fixed[r].id] = {
            selection: [],
            Filter: Filter,
          };
        }
      }

      if (ActiveFilters.length !== 0) {
        for (var i = 0; i < ActiveFilters.length; i++) {
          const selection = [];
          if ("Selected" in ActiveFilters[i]) {
            if (ActiveFilters[i]["Selected"].length > 0) {
              setResetFilterFlag(true)
            }
            for (var j = 0; j < ActiveFilters[i].Selected.length; j++) {
              var temp_arr = ActiveFilters[i].Selected[j].value.split("-");
              selection.push(temp_arr[temp_arr.length - 1]);
            }
          }

          chartdata[ActiveFilters[i].name] = {
            selection: typeof Chartdata[ActiveFilters[i].name] !== 'undefined' ? Chartdata[ActiveFilters[i].name].selection : [],
            Filter: selection,
          };
        }
      }
      setChartdata(chartdata);
      setFetchCall(true);
    }
  }, [ActiveFilters, AnalyticFilterFlag]);

  //HEIRARCHY FILTERS 
  const GetOption = (current_level, selection, destination_index) => {
    setLoader(true);
    axios
      .post("get_hirarchy_dropdown_options/", {
        hirarchy: Analyse.Brand,
        current_level: current_level,
        old_selection: selection !== "all" ? selection : ""
      })
      .then((response) => {
        const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
        var sort_arr = [...AllFilters];
        for (var i = 0; i < sort_arr.length; i++) {
          if (parseInt(sort_arr[i].level) === current_level) {
            var obj = sort_arr[i];
            obj['filters'] = response.data.data.options;
            obj['Selected'] = [];

            const temp_index = sort_arr.indexOf(sort_arr[i]);
            if (temp_index > -1) { // only splice array when item is found
              sort_arr.splice(temp_index, 1); // 2nd parameter means remove one item only
            }

            ActiveFiltersTemp.splice(
              destination_index,
              0,
              obj
            );
          }
        }
        let data = [...ActiveFiltersTemp];
        for (let k = 0; k < ActiveFiltersTemp.length; k++) {
          if (destination_index < k) {
            data[k]['filters'] = [];
            data[k]['Selected'] = [];
          }
        }
        setActiveFilters(data);

        //Set analytical filters data in localstorage
        const AnalyticFilterTemp = {
          AllFilters: AllFilters,
          Fixed: Fixed,
          ActiveFilters: data
        };

        localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));

        setLoader(false);
      }).catch((data) => {
        setLoader(false);
      });
  }

  const AddHeirarchyFilter = (index) => {

    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFilters));
    let selection_value = [];
    var i = ((ActiveFiltersCompTemp.length) - 1);
    while (i >= 0) {
      if (ActiveFilters[i]['Selected'].length !== 0) {
        for (var j = 0; j < ActiveFilters[i]['Selected'].length; j++) {
          selection_value.push(ActiveFilters[i]['Selected'][j].value === "all" ? "" : ActiveFilters[i]['Selected'][j].value);
        }
      }
      i--;
    }

    const selection = selection_value.toString().replaceAll(",", ";");
    GetOption(parseInt(AllFilters[index].level), selection, ActiveFiltersCompTemp.length)

    let filter = AllFilters[index];
    filter.Selected = [];

    // setActiveFilters(ActiveFiltersCompTemp);
    setFilterTabShow(true);
  };

  const onChangeFilter = (selected, index, data) => {

    const ActiveFiltersTemp = data ? data : JSON.parse(JSON.stringify(ActiveFilters));

    ActiveFiltersTemp[index].Selected = selected;

    let next_level_arr = [];
    for (var j = 0; j < ActiveFiltersTemp.length; j++) {
      if (j > index) {
        next_level_arr.push(ActiveFiltersTemp[j].level);
      }
    }

    const next_level = next_level_arr.toString().replaceAll(",", ";");

    setLoader(true);

    var selection_value = [];
    for (var i = 0; i < selected.length; i++) {
      selection_value.push(selected[i].value);
    }

    const selection = selection_value.toString().replaceAll(",", ";");
    axios
      .post("hirarchy_dropdown_child_options/", {
        hirarchy: Analyse.Brand,
        "selection": selection !== "all" ? selection : "",
        "next_level": next_level
      })

      .then((response) => {
        var data = [...ActiveFiltersTemp]
        for (var i = 0; i < ActiveFiltersTemp.length; i++) {

          if (response.data.data[data[i].level]) {
            data[i]['Selected'] = []
            data[i]['filters'] = response.data.data[data[i].level];
          }
        }
        setActiveFilters(data);

        //Set analytical filters data in localstorage
        const AnalyticFilterTemp = {
          AllFilters: AllFilters,
          Fixed: Fixed,
          ActiveFilters: data
        };

        localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));

        setLoader(false);
      }).catch((data) => {
        setLoader(false);
      });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    if (
      result.destination.droppableId === "active" &&
      result.source.droppableId === "inactive"
    ) {

      let selection_value = [];

      var i = ((result.destination.index) - 1);
      while (i >= 0) {
        if (ActiveFilters[i]['Selected'].length !== 0) {
          for (var j = 0; j < ActiveFilters[i]['Selected'].length; j++) {
            selection_value.push(ActiveFilters[i]['Selected'][j].value === "all" ? "" : ActiveFilters[i]['Selected'][j].value);
          }
        }
        i--;
      }
      const selection = selection_value.toString().replaceAll(",", ";");
      GetOption(parseInt(result.draggableId), selection, result.destination.index)
    }

    if (
      result.destination.droppableId === "inactive" &&
      result.source.droppableId === "active"
    ) {
      const AllFiltersTemp = JSON.parse(JSON.stringify(AllFilters));
      AllFiltersTemp.splice(
        result.destination.index,
        0,
        ActiveFilters[result.source.index]
      );
      setAllFilters(AllFiltersTemp);

      const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
      ActiveFiltersTemp.splice(result.source.index, 1);
      setActiveFilters(ActiveFiltersTemp);

      // //Set analytical filters data in localstorage
      // const AnalyticFilter = JSON.parse(localStorage.getItem("AnalyticFilter"));
      // const AnalyticFilterTemp = {
      //   AllFilters: AllFiltersTemp,
      //   Fixed: AnalyticFilter.Fixed,
      //   ActiveFilters: ActiveFiltersTemp
      // };
      // localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
    }

    if (
      result.destination.droppableId === "active" &&
      result.source.droppableId === "active"
    ) {
      const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));

      ActiveFiltersTemp.splice(result.source.index, 1);
      ActiveFiltersTemp.splice(
        result.destination.index,
        0,
        ActiveFilters[result.source.index]
      );

      onChangeFilter(ActiveFiltersTemp[result.destination.index].Selected, result.destination.index, ActiveFiltersTemp);

    }

    if (
      result.destination.droppableId === "inactive" &&
      result.source.droppableId === "inactive"
    ) {
      const AllFiltersTemp = JSON.parse(JSON.stringify(AllFilters));
      AllFiltersTemp.splice(result.source.index, 1);
      AllFiltersTemp.splice(
        result.destination.index,
        0,
        AllFilters[result.source.index]
      );
      setAllFilters(AllFiltersTemp);
    }

  };

  const RemoveHeirarchyFilter = (item) => {

    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: ActiveFiltersCompTemp
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));

  };

  const IsInAllHeirarchyFilters = (item) => {
    for (let i = 0; i < ActiveFilters.length; i++) {
      if (ActiveFilters[i].name === item.name) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight)
    }
  }, [FetchCall, ShowSummary, KpiSunburstData])

  useEffect(() => {
    if (DivRef.current !== null) {
      setDivWidth(DivRef.current.clientWidth)
      setDivHeight(DivRef.current.clientHeight)
    }
  }, [FetchCall, ShowSummary, KpiSunburstData])

  const ResetFilters = () => {
    var active_filter = []
    for (let i = 0; i < ActiveFilters.length; i++) {
      var obj = ActiveFilters[i];
      obj['Selected'] = [];
      active_filter.push(obj)
    }
    setResetFilterFlag(false)
    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: active_filter
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
    setActiveFilters(active_filter)
  }
  return (
    <>
      {loader ? <Loader /> : null}

      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid main-content-w pt-0">
            <div className="row align-items-center" >
              <div className="col-sm-6">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">
                    {/* Data Landscape */}
                    {
                      (Analyse && Analyse.Menubar) ?
                        Analyse.Menubar.map((item) => {
                          return ('/' + item.key === location.pathname) ? item.value : null
                        })
                        : null
                    }
                  </h1>
                </div>
              </div>
              {/* change 2 class add */}
              <div className="row pr-0 mt-1" style={{ alignItems: "center" }}>
                <AllHeirarchyFilter OnlyHeirarchyFilter={true} Fixed={Fixed} ActiveFilters={ActiveFilters} AllFilters={AllFilters} AddHeirarchyFilter={AddHeirarchyFilter} RemoveHeirarchyFilter={RemoveHeirarchyFilter} IsInAllHeirarchyFilters={IsInAllHeirarchyFilters} Chartdata={[Chartdata]} />
                <div className="col-xl-2 pr-0">
                  <div className="card my-1">
                    {/* change 1 class + css */}
                    <div className="card-body cursor-pointer d-flex align-items-center justify-content-center custom-selected-filter" onClick={() => {
                      setFilterTabShow(true);
                    }} >
                      <h6 className="m-0 text-center primary-text"><i className="fa fa-filter" aria-hidden="true"></i> Selected Filter</h6>
                      {ResetFilterFlag ?
                        <h6 className="m-0 text-center primary-text cursor-pointer custom-reset" >
                          <p className="mx-2 m-0" title="Reset Filter" onClick={() => {
                            ResetFilters();
                          }}> &#8635; </p>
                        </h6>
                        : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid main-content-w pt-1">
            <div className="row">
              {
                FilterTabShow ?
                  <ActiveHeirarchyAndCampaignFilter OnlyHeirarchyFilter={true} onChangeFilter={onChangeFilter} onDragEnd={onDragEnd} ActiveFilters={ActiveFilters} setFilterTabShow={setFilterTabShow} Fixed={Fixed} />
                  : null
              }
              <div className={parseInt(ShowSummary) === 1 ? "col-xl-4" : "col-xl-6"}>
                <div className="card card-min-height align-items-sm-center" ref={DivRef} >
                  <div className="row w-100">
                    <div className="col-sm-12 brand-card ef-drivers card-min-height">
                      <div className="row align-items-center justify-content-sm-between pb-24">
                        <div className="col-sm-10">
                          <div className="page-title-right"></div>
                        </div>

                        <div className="col-sm-2 d-flex align-items-center justify-content-sm-end">
                          {
                            Notes !== "" ?
                              <>
                                <p className="m-0 px-2">
                                  <svg src={InfoIcon} id="notes" xmlns="http://www.w3.org/2000/svg" width="25" height="25" className="bi bi-info-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                  </svg>
                                </p>

                                <Tooltip anchorSelect="#notes" clickable>
                                  {/* <div className="api-notes">
                                    {Notes}
                                  </div> */}
                                  <div
                                    className="api-notes"
                                    dangerouslySetInnerHTML={{ __html: Notes }}
                                  >
                                  </div>
                                </Tooltip>
                              </> : null
                          }
                          { (downloadFlag.chart || downloadFlag.data || downloadFlag.pdf) &&<span className="flex-shrink-0 dropdown">
                            <Link
                              to="#"
                              className="d-flex align-items-center text-decoration-none dropdown-toggle text-white"
                              id="dropdownUser2"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <p className="m-0 px-2">
                                <svg src={Download} title="Download" xmlns="http://www.w3.org/2000/svg" width="25" height="25" className="bi bi-download" viewBox="0 0 16 16">
                                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                </svg>
                              </p>
                            </Link>
                            <ul
                              className="dropdown-menu text-small shadow"
                              aria-labelledby="dropdownUser2"
                            >
                              {downloadFlag.chart && <li onClick={(e) => { }}>
                                <Link className="dropdown-item" onClick={() => {

                                  if (KpiSunburstData.length > 0) {
                                    // setDownloadchart(true)
                                    // setLoader(true)
                                    setChartContainerID("kpi_chart");
                                    setIsDownloadingChart(true);
                                    setDownloadingChartTitle("KPI Prioritization");
                                    setDownloadedImageName("KPI Prioritization");
                                  } else {
                                    toast.error("Chartdata is not available")
                                  }
                                }}>
                                  Download Chart
                                </Link>
                              </li>}

                              {downloadFlag.data && <li onClick={(e) => { }}>
                                <Link className="dropdown-item" onClick={() => DownloadData()} >Download Data</Link>
                              </li>}
                              {downloadFlag.pdf && <li onClick={(e) => { }}>
                                <Link className="dropdown-item" onClick={() => downloadPDF()} >Download PDF</Link>
                              </li>}
                            </ul>
                          </span>}
                        </div>
                      </div>
                      <div className="row align-items-center justify-content-sm-between ">
                        <div className="col-sm-12">
                          <div className="kpi-chart-height" ref={refGraphDiv} >
                            <KPISunburst ActiveFilters={ActiveFilters} Brand={Analyse.brand} Fixed={Fixed} setLoader={setLoader} setDownloadchart={setDownloadchart} Downloadchart={Downloadchart}  KpiSunburstData={KpiSunburstData} height={ChartHeight} width={DivWidth} />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row" style={{ height: "45px", overflowY: "auto", overflowX:"hidden" }}>
                            {
                              Legends.map((item, index) => {
                                return <div key={index} className="col-md-3 mb-1 charts-legends" style={{ color: localStorage.getItem('CurrentTheme') === "Dark" ? "#ffffff" : "1c2427", whiteSpace: 'nowrap' }}>
                                  <span style={{ height: '16px', width: '16px', display: 'inline-block', marginRight: '5px', backgroundColor: item.Color }}></span>
                                  {item.Name}
                                </div>
                              })
                            }
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>

              <div className={parseInt(ShowSummary) === 1 ? "col-xl-5" : "col-xl-6"} >
                <div className="card card-min-height" style={{ height: DivHeight + "px" }}>
                  <div className="card-body brand-card">
                    {
                      JSON.stringify(TableKPI) !== '{}' ?
                        <>
                          <p className="card-title-lable">{TableKPI.charttitle}</p>
                          <div className="row my-1 flex-nowrap">
                            {/* <div className="custom-col-sm-1">
                              <p className="m-0 vertical-text"><i className="ri-arrow-up-line my-2"></i> {TableKPI.ytitle}</p>
                            </div> */}
                            <div className="col-sm-12 px-0">
                              <div className="gr-table-x-title-div ">
                                {/* <p className="m-0 gr-table-x-title">{TableKPI.xtitle} <i className="ri-arrow-right-line mx-2"></i></p> */}
                              </div>
                              <div className="table-responsive gains-risk-table" style={{ maxHeight: parseInt(ChartHeight) + 25 + "px", overflowY: "scroll" }}>
                                <table className="table table-bordered mb-0">
                                  <thead>
                                    <tr>
                                      <th className="caption">Sr No.</th>
                                      {TableKPI.columns.map((item) => {
                                        return <th key={item}>{item}</th>;
                                      })}
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {/* <tr>
                                      <td className="channel-name">channel 1</td>
                                      <td>val1</td>
                                      <td>val2</td>
                                    </tr> */}
                                    {TableKPI.rows.map((item) => {
                                      return (
                                        <tr key={item.ChannelName}>
                                          <td className="channel-name">{item.ChannelName}</td>
                                          {item.ChannelData.map((data, index) => {
                                            return (
                                              <td
                                                key={index}
                                                style={{ color: data.Color }}
                                              >
                                                {data.Value}
                                              </td>
                                            );
                                          })}
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="row my-3 d-flex justify-content-sm-end">
                            <div className="col-sm-10 d-flex align-items-center justify-content-sm-end">
                              {
                                TableKPI.Legends.map((item, index) => {
                                  return <LegendsTable key={index} Name={item.Name} Color={item.Color} />
                                })
                              }
                            </div>
                          </div>
                        </>
                        : null
                    }
                  </div>
                </div>
              </div>

              {parseInt(ShowSummary) === 1 ?
                <div className="col-xl-3">
                  <div className="card card-min-height" style={{ height: DivHeight + "px" }}>
                    <div className="card-body brand-card">
                      <div className="row align-items-center justify-content-sm-between">
                        <h3 className="data-spends my-2">Summary</h3>
                        <div className="col-sm-12">
                          <div className="spends-list my-2">
                            <ol>
                              {Summary.map((item, index) => {
                                return (
                                  <li key={index} className="spends-list-li">
                                    <p>{item}</p>
                                  </li>
                                );
                              })}
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : null
              }

            </div>

            <div className="row custom-note">
              <div className="col-sm-1">
                {
                  FooterNotes !== "" ?
                    <>
                      <i
                        className="fas fa-mail-bulk chart_info_icon footer-notes-icon"
                        id="clickable"
                      ></i>
                      <Tooltip anchorSelect="#clickable" clickable>
                        {/* <div className="footer-notes">
                          {FooterNotes}
                        </div> */}
                      <div
                        className="footer-notes"
                        dangerouslySetInnerHTML={{ __html: FooterNotes }}
                        >
                      </div>
                      </Tooltip>
                    </>
                    : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
