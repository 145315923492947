import React, { useRef, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsSunburst from "highcharts/modules/sunburst";
// import axiosChartDownload from "../../axiosChartDownload";
// import domtoimage from "dom-to-image";
// import { toast } from "react-toastify";
HighchartsSunburst(Highcharts);

const KPISunburst = (props) => {
  const refChart = useRef()

  // useEffect(() => {
  //   if (props.Downloadchart) {
  //     const chartContainer = document.getElementById("kpi_chart");
  //     domtoimage
  //       .toPng(chartContainer)
  //       .then(function (dataUrl) {
  //         var string = "";
  //         for (var i = 0; i < props.Fixed.length; i++) {
  //           string += props.Fixed[i].id;
  //           if (props.ActiveFilters.length > 0) {
  //             string += ",";
  //           }
  //         }

  //         for (var j = 0; j < props.ActiveFilters.length; j++) {
  //           string += props.ActiveFilters[j].name;
  //           if (props.ActiveFilters.length - 1 !== j) {
  //             string += ","
  //           }
  //         }

  //         props.setLoader(true);
  //         axiosChartDownload.post("download_chart/create-image/",
  //           {
  //             "title": "Kpi Prioritization",
  //             "filter": props.Brand,
  //             "hierarchy": string,
  //             "timeframe": "",
  //             "start_date": "",
  //             "end_date": "",
  //             "base64_image": dataUrl.split(",")[1],
  //             theme: localStorage.getItem("CurrentTheme")
  //           }

  //         ).then((response) => {
  //           if (response.data.error === 1) {
  //             toast.error(response.data.erroMsg);
  //             props.setLoader(false);
  //           } else {
  //             var link = document.createElement("a");
  //             link.target = "_blank";
  //             link.href = response.data.data.combined_graph_url;
  //             document.body.appendChild(link);
  //             link.click();
  //             document.body.removeChild(link);
  //             props.setLoader(false);
  //           }
  //         }).catch((data) => {
  //           props.setLoader(false);
  //         });
  //       })
  //       .catch(function (error) {
  //         props.setLoader(false)
  //         console.error("Error while generating chart image:", error);
  //       });
  //     props.setDownloadchart(false)
  //   }
  // }, [props])


  let RootNade = ''
  let options = {
    chart: {
      // backgroundColor: localStorage.getItem("CurrentTheme") === "Dark" ? "#1c2427" :"",
      backgroundColor: 'rgba(0,0,0,0)',
      height: props.height,
      width: props.width
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    series: [
      {
        type: "sunburst",
        data: props.KpiSunburstData,
        allowDrillToNode: true,
        cursor: "pointer",
        point: {
          events: {
            click: function (e) {
              // var series = refChart.current.chart.series[0]
              // series.onClickDrillToNode({ point: e.point });
              var series = refChart.current.chart.series[0]
              if (RootNade === e.point.id) {
                RootNade = e.point.parent
                series.setRootNode(e.point.node.parentNode.id, true, { trigger: 'click' })
              } else {
                RootNade = e.point.id
                series.setRootNode(e.point.id, true, { trigger: 'click' })
              }
            }
          }
        },
        dataLabels: {
          format: '{point.name} ({point.value})',
          borderWidth: 0,
          filter: { property: "innerArcLength", operator: ">", value: 16 },
          style: { textShadow: false, textOutline: null, color: "contrast" },
        },
        levels: [
          {
            level: 1,
            levelIsConstant: false,
            dataLabels: {
              filter: { property: "outerArcLength", operator: ">", value: 64 },
            },
            colorByPoint: true,
          },
          { level: 2, colorVariation: { key: "brightness", to: -0.3 } },
          { level: 3, colorVariation: { key: "brightness", to: -0.3 } },
        ],
        // showInLegend:true,
      },
    ],
    tooltip: {
      headerFormat: '<div style="padding:0;background-color:#ffffff;color:#000000;">',
      pointFormat: '{point.name}:{point.value:.1f}',
      footerFormat: "</div>",
      shared: true,
      useHTML: true,
      borderColor: '#ffffff',
      backgroundColor: '#ffffff',
      borderRadius: 8
    },
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          enabled: true,
          symbol: "download",
          symbolStroke: "#ffffff",
          theme: {
            fill: "#1c2427"
          },
          titleKey: ""
        }
      }
    }
  };

  return (
    <div id="kpi_chart"><HighchartsReact ref={refChart} highcharts={Highcharts} options={options} /></div>
  );
};

export default KPISunburst;
