import React from "react";
import img from "./funnelImg3.png";
import { useTheme, useMediaQuery } from "@mui/material";

const Funnel = ({ funnelData }) => {
  // console.log("funnelData: ", funnelData);
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflowX: "scroll",
      }}
    >
      {JSON.stringify(funnelData) !== "{}" && (
        <div
          style={{
            // height: "100%",/
            height: isSmall || isMedium ? "500px" : "100%",
            width: "1000px",
            // border: "2px solid red",
            position: "relative",
            backgroundImage: `url(${img})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            overflow: "auto", // Enable scrollbars when necessary
            margin: "auto",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: isSmall || isMedium ? "135px" : "130px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <div
              style={{
                width: "500px",
                display: "flex",
                justifyContent: "space-between",
                // paddingLeft: "20px",
                // border:"2px solid red"
                marginLeft: isSmall || isMedium ? "25px" : "",
              }}
            >
              <div
                style={{
                  color:
                    funnelData?.Web[0]?.incremental_value[0] === "-"
                      ? "red"
                      : "white",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                <div>Total: {funnelData?.Web[0]?.total_value}</div>
                <div>{`Inc:(${funnelData?.Web[0]?.incremental_value})`}</div>
              </div>
              <div
                style={{
                  color:
                    funnelData?.App[0]?.incremental_value[0] === "-"
                      ? "red"
                      : "#3F2500",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                <div>Total: {funnelData?.App[0]?.total_value}</div>
                <div>{`Inc:(${funnelData?.App[0]?.incremental_value})`}</div>
              </div>
            </div>
            <div
              style={{
                width: "500px",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "28px",
                marginLeft: isSmall || isMedium ? "25px" : "",
                // border:"2px solid red"
                textAlign: "center",
              }}
            >
              <div
                style={{
                  color:
                    funnelData?.Web[1]?.incremental_value[0] === "-"
                      ? "red"
                      : "#3F2500",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                <div>Total: {funnelData?.Web[1]?.total_value}</div>
                <div>{`Inc:(${funnelData?.Web[1]?.incremental_value})`}</div>
              </div>
              <div
                style={{
                  color:
                    funnelData?.App[1]?.incremental_value[0] === "-"
                      ? "red"
                      : "#3F2500",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                <div>Total:{funnelData?.App[1]?.total_value}</div>
                <div>{`Inc:(${funnelData?.App[1]?.incremental_value})`}</div>
              </div>
            </div>
            <div
              style={{
                width: "500px",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "38px",
                paddingLeft: "20px",
                fontSize: "90%",
                marginLeft: isSmall || isMedium ? "25px" : "",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  color:
                    funnelData?.Web[2]?.incremental_value[0] === "-"
                      ? "red"
                      : "#3F2500",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                <div>Total:{funnelData?.Web[2]?.total_value}</div>
                <div>
                  {" "}
                  <div>{`Inc:(${funnelData?.Web[2]?.incremental_value})`}</div>
                </div>
              </div>
              <div
                style={{
                  color:
                    funnelData?.App[2]?.incremental_value[0] === "-"
                      ? "red"
                      : "#3F2500",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                <div>Total:{funnelData?.App[2]?.total_value}</div>
                <div>{`Inc:(${funnelData?.App[2]?.incremental_value})`}</div>
              </div>
            </div>
            <div
              style={{
                width: "502px",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "50px",
                paddingLeft: isSmall || isMedium ? "48px" : "30px",
                fontSize: "80%",
                // border:"2px solid red"
                marginLeft: isSmall || isMedium ? "21px" : "-7px",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  color:
                    funnelData?.Web[3]?.incremental_value[0] === "-"
                      ? "red"
                      : "#3F2500",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                <div>Total:{funnelData?.Web[3]?.total_value}</div>
                <div>{`Inc:${funnelData?.Web[3]?.incremental_value}`}</div>
              </div>
              <div
                style={{
                  // marginLeft: isSmall || isMedium ? "300px" : "150px",
                  color:
                    funnelData?.App[3]?.incremental_value[0] === "-"
                      ? "red"
                      : "#3F2500",
                  fontWeight: 800,
                  // border:"2px solid red",
                  textAlign: "center",
                }}
              >
                <div>Total:{funnelData?.App[3]?.total_value}</div>
                <div>{`Inc:${funnelData?.App[3]?.incremental_value}`}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Funnel;
