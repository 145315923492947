import axios from "axios";
import BaseUrl from "./BaseUrl";

const instance = axios.create({
  baseURL: BaseUrl + "/api/",
  headers: {
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("TokenSkewb")}`;
      },
    },
    // "Cache-Control": "no-cache",
  },
});

export default instance;