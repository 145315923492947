import React, { useState, useEffect } from "react";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";
import axios from "../../axios";
import DeleteResultPopup from "./DeleteResultPopup";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Main = () => {
    const Analyse = JSON.parse(localStorage.getItem("Analyse"));
    const [SavedResults, setSavedResults] = useState([]);
    const [DeletedId, setDeletedId] = useState("");
    const [Search, setSearch] = useState("");
    const [loader, setLoader] = useState(false);
    const [GetData, setGetData] = useState(true);

    useEffect(() => {
        if (GetData) {
            setLoader(true);
            axios
                .post("get_saved_optimisations/", {
                    brand: Analyse.Brand,
                    kpi: Analyse.KPI,
                })
                .then((response) => {
                    if (response.data.error === 1) {
                        toast.error(response.data.erroMsg);
                    } else {
                        setSavedResults(response.data.data);
                        setGetData(false)
                    }
                    setLoader(false);
                });
        }
    }, [Analyse.Brand, Analyse.KPI, GetData]);

    const DeleteId = (id) => {
        setDeletedId(id);
    };

    const CanceledId = () => {
        setDeletedId("");
    }
    const DeleteRecord = (id) => {
        setLoader(true);
        axios.post("deleted_optimisation/", { result_id: DeletedId }).then((response) => {
            if (response.data.error === 1) {
                toast.error(response.data.erroMsg);
            } else {
                setDeletedId(response.data.data);
                setDeletedId("");
                setGetData(true)
            }
            setLoader(false);
        })
    }

    return (
        <div>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <Topbar BrandName={Analyse.Brand || ""} />
            <Menubar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid main-content-w pt-0">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                                    <h1 className="mb-sm-0 theme-color main-title">
                                        Saved Results
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="container-fluid main-content-w pt-1"
                    >
                        <div className="row mt-1">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="row justify-content-between mx-2">
                                        <div className="col-sm-12">
                                            <div className="page-title-box d-sm-flex align-items-center flex-row-reverse p-0">
                                                <div className="page-title-right">
                                                    <form className="app-search d-none d-lg-block">
                                                        <div className="position-relative">
                                                            <input
                                                                type="text"
                                                                className="form-control main-search custom-input-box"
                                                                placeholder="Search..."
                                                                value={Search}
                                                                onChange={(e) => setSearch(e.target.value)}
                                                            />
                                                            {/* <span className="ri-search-line"></span> */}
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between mx-2">
                                        <div className="col-sm-12">
                                            <div className="table-responsive saved-result-table">
                                                <table className="table table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Serial No</th>
                                                            <th>Name</th>
                                                            {/* New Column */}
                                                            <th>Brand</th> 
                                                             {/*New Column  */}
                                                            <th>KPI</th>
                                                            <th>Date &amp; Time</th>
                                                            <th>User</th>
                                                            <th>Optimised for</th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {SavedResults.map((item) => {
                                                            return (item.result_name.toLowerCase().includes(Search.toLowerCase())) ? (
                                                                <tr key={item.result_id}>
                                                                    <td>{item.result_id}</td>
                                                                    <td>{item.result_name}</td>
                                                                    <td>{item.brand}</td>
                                                                    <td>{item.kpi}</td>
                                                                    <td>{item.result_date}</td>
                                                                    <td>{item.username}</td>
                                                                    <td style={{ textTransform: 'capitalize' }}>
                                                                        {item.optimization_type} - {item.budget}{item.optimization_type === 'budget' ? '' : '%'}
                                                                    </td>
                                                                    <td>
                                                                        <Link to={"/optimizer/" + item.result_id} className="btn btn-result-open">
                                                                            Open
                                                                        </Link>
                                                                    </td>
                                                                    <td className="delete-result-icon">
                                                                        <i
                                                                            className="ri-delete-bin-7-line"
                                                                            onClick={() => DeleteId(item.result_id)}
                                                                        ></i>
                                                                        {DeletedId !== "" ? <DeleteResultPopup CanceledId={CanceledId} DeleteRecord={DeleteRecord} /> : null}
                                                                    </td>
                                                                </tr>
                                                            ) : null;
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;
