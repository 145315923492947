// import { Stack, Typography } from "@mui/material";
import StackChart from "./Stack2";
import ToggleButton from "react-toggle-button";
import { Stack, Typography, useTheme, useMediaQuery } from "@mui/material";

import { useState, useEffect, useRef, useLayoutEffect } from "react";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

const StackContainer = (props) => {

  const [isClicked,setisClicked] = useState(false)
  const [chartKey, setChartKey] = useState(0); // State to force chart reload
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    // This effect will trigger whenever props.stackCategories or props.stackSeries changes
    // It will force reload the chart by updating the chartKey state
    setChartKey((prevKey) => prevKey + 1);
  }, [props.stackCategories, props.stackSeries]);

  const [divHeight, setDivHeight] = useState("");
  // console.log("divHeight stack chart: ", divHeight);

  const divRef = useRef(null);

  useLayoutEffect(() => {
    const updateDivHeight = () => {
      if (divRef.current) {
        const newHeight = divRef.current.clientHeight;
        setDivHeight(newHeight);
        // console.log("new height: ", newHeight);
      }
    };

    updateDivHeight(); // Initial update
    window.addEventListener("resize", updateDivHeight);

    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, []);

  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  return (
    <Stack gap={1} ref={divRef} style={{ height: "100%", 
    // border:"2px solid green"
     }}>
      <div
        style={{
          display: "flex",
          // flexDirection:isSmall||isMedium?"column":"row",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          paddingTop: "2rem",
          // border:"2px solid blue",
          // paddingLeft:"10%"
        }}
      >
        <div className="row align-items-center sorting-btn-ui" style={{
          // border:"2px solid green",
          paddingRight:isSmall||isMedium?"2%":""
          // display:"flex",
          // justifyContent:"flex-start"
        }}>
          <div style={{
            // border:"2px solid red"
            }} >
            <div style={{display:"flex"}}>
            <>
              <li onClick={props.DrillUpAllTrends} title="Drill Up">
                <i className="fa fa-arrow-up" aria-hidden="true"></i>
              </li>
              <li onClick={props.DrillDownAllTrends} title="Drill Down">
                <i className="fa fa-arrow-down" aria-hidden="true"></i>
              </li>
              <li onClick={props.DrillAllTrends} title="Expand All">
                <i className="fa fa-sitemap" aria-hidden="true"></i>
              </li>
              <li onClick={props.ResetAllTrends} title="Reset">
                <i className="fa" aria-hidden="true">
                  &#8635;
                </i>
              </li>
              
            </>
           {
            (isSmall||isMedium)&& <div 
            style={{marginLeft:"0px", color:"white"}}
             onClick={()=>{
              setisClicked(!isClicked)
            }} > {isClicked?<ZoomInIcon/>:<ZoomOutIcon />} </div>
           }
            </div>
            
          </div>
        </div>
        <Stack
          direction={"row"}
          gap={1}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              color: !toggle ? "#d6ff41" : "white",
              fontSize: "0.85rem",
              // opacity: !props.toggle ? 1 : 0.3,
            }}
          >
            Absolute
          </Typography>
          <ToggleButton
            value={toggle}
            inactiveLabel=""
            activeLabel=""
            onToggle={(value) => {
              setToggle(!value);
            }}
            colors={{
              activeThumb: {
                base: "#d6ff41",
              },
              inactiveThumb: {
                base: "#d6ff41",
              },
              active: {
                base: "#3E5056",
                // hover: "rgb(177, 191, 215)",
              },
              inactive: {
                base: "#3E5056",
                // hover: "rgb(95,96,98)",
              },
            }}
          />
          <Typography
            sx={{
              color: toggle ? "#d6ff41" : "white",
              fontSize: "0.85rem",
              // opacity: props.toggle ? 1 : 0.3,
            }}
          >
            100%
          </Typography>
        </Stack>{" "}
      </div>
      <div
        style={{
          //   backgroundColor:'red',
          // border:"1px solid blue",
          height: "100%",
          overflowx:
            isSmall || isMedium ? "auto" : "" /* Enable horizontal scrollbar */,
          overflowy:
            isSmall || isMedium ? "hidden" : "" /* Hide vertical scrollbar */,
          whitespace:
            isSmall || isMedium ? "nowrap" : "" /* Prevent line breaks */,
          overflow: isSmall || isMedium ? "scroll" : "",
          
        }}
      >
        <StackChart
          // key={chartKey}
          stackCategories={props.stackCategories}
          stackSeries={props.stackSeries}
          DrillDown={props.DrillDownTrends}
          NotAllow={props.NotAllow}
          divHeight={divHeight}
          toggle={toggle}
          isClicked={isClicked}
        />
      </div>
    </Stack>
  );
};

export default StackContainer;
