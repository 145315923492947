import React, { useState, useEffect } from "react";
import Legends from "./Legends";

const RowItem = ({ item, showOpenLevel }) => {
  const [expand, setExpand] = useState(item?.hirarchy_level <= showOpenLevel);
  const isLeaf = item?.children.length === 0;

  const handleExpand = () => {
    setExpand(!expand);
  };

  useEffect(() => {
    setExpand(item?.hirarchy_level <= showOpenLevel);
  }, [showOpenLevel, item]);


  return (
    <>
      <tr>
        <td className="channel-name">
          <div
            style={{
              cursor: isLeaf ? "default" : "pointer",
              marginLeft:
                parseInt(item?.hirarchy_level) !== 0
                  ? parseInt(item?.hirarchy_level) * 7 + "px"
                  : "0px",
            }}
            onClick={isLeaf ? null : handleExpand}
          >
            {item?.name}
            {!isLeaf &&
              (expand ? (
                <i className="fas fa-minus ml-1 custom-table-toggle-icon"></i>
              ) : (
                <i className="fas fa-plus ml-1 custom-table-toggle-icon"></i>
              ))}
          </div>
        </td>
        {item?.values.map((data, dataIndex) => (
          <td key={dataIndex} style={{color: data.color}}>{data?.value}</td>
        ))}
      </tr>
      {expand &&
        item.children &&
        item.children.map((childItem) => (
          <RowItem
            key={childItem.id}
            item={childItem}
            showOpenLevel={showOpenLevel}
          />
        ))}
    </>
  );
};


const ComparativeROI = (props) => {
  // console.log("Xtitle: ", props.Xtitle);
  // console.log("Ytitle: ", props.Ytitle);
  // console.log("ChannelYears: ", props.ChannelYears);
  // console.log("ChannelData: ", props.ChannelData);

  

  return (
    <>
      <div className="row my-1 flex-nowrap" style={{ paddingRight: "40px" }}>
        <div className="custom-col-sm-1" style={{ width:"40px" }}>
          <p className="m-0 vertical-text"><i className="ri-arrow-up-line my-2"></i> {props.Ytitle}</p>
        </div>
        <div className="col-sm-12 px-0">
          {/* icons.....start */}
          <div className="row align-items-center sorting-btn-ui">
              <div className="col-sm-3" style={{display: "flex"}}>
                 {/* <ul> */}
                   <div onClick={() => props.setShowOpenLevel(Infinity)} title="Expand All">
                     <i className="fa fa-sitemap" aria-hidden="true"></i>
                   </div>
                   <div onClick={() => props.setShowOpenLevel(0)} title="Reset">
                     <i className="fa" aria-hidden="true">
                       &#8635;
                     </i>
                   </div>
                 {/* </ul> */}
              </div>
          </div>
          {/* icons.......end */}
          <div className="gr-table-x-title-div ">
            <p className="m-0 gr-table-x-title">{props.Xtitle} <i className="ri-arrow-right-line mx-2"></i></p>
          </div>
          <div className="table-responsive gains-risk-table" style={{ maxHeight: props.height-60+"px", overflowY: "scroll" }}>
            <table className="table table-bordered mb-0">

              <thead>
                <tr>
                  <th className="caption"></th>
                  {props.ChannelYears.map((item, index) => {
                    return <th key={index+item}>{item}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
              {props.ChannelData?.map(item => {
                  return <RowItem key={item.id} item ={item} showOpenLevel={props.showOpenLevel} />
               })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row my-3 d-flex justify-content-sm-end">
        <div className="col-sm-10 d-flex align-items-center justify-content-sm-end">
          { props.Legends.length > 0 ? props.Legends.map((item, index) => {
            return <Legends key={item + "-" + index} item={item} />;
          }) : null}
        </div>
      </div>
    </>
  );
};

export default ComparativeROI;
