import React from "react";

const AlertBox = (props) => {
    return (
        <>
            <div
                className="modal fade bs-example-modal-center show"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="mySmallModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        {/* <div className="modal-header">
                            <h5 className="modal-title">Center modal</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> */}
                        <div className="modal-body modal-body-custom">
                            <div className="text-center">
                                <h2 className="modal-title-custom">{props.AlertMessage}</h2>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button className="btn btn-modal-primary" onClick={() => props.setAlertMessage('')}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AlertBox;
