// import React, { useState, useLayoutEffect, useRef } from "react";
// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
// import { Stack, Box ,Typography, useTheme, useMediaQuery} from "@mui/material";
// const FunnelChart = (props) => {
//   //   console.log("data: ", props.multiLineData);
//   const themes = useTheme();
//   const isMedium = useMediaQuery(themes.breakpoints.down("md"));
//   const isSmall = useMediaQuery(themes.breakpoints.down("sm"));
//   const options = {
//     chart: {
//       zoomType: "xy",
//       type: "spline",
//       height: 500,
//       backgroundColor: "black",
//       // backgroundColor: "#1c2427", // Set your desired background color her
//       resetZoomButton: {
//         theme: {
//           fill: "red",
//           stroke: "#d6ff41",
//           style: {
//             color: "white",
//           },
//         },
//       },
//     },
//     title: {
//       text: null,
//     },

//     xAxis: {
//       labels: {
//         style: {
//           color: "#FFFFFF", // Set color for x-axis labels
//         },
//       },
//       categories: props.funnelChartData?.data?.chartdata?.xAxis?.Category,
//     },
//     yAxis: {
//       gridLineWidth: 0,
//       title: {
//         text: null, // Set Y-axis title to null
//       },
//       labels: {
//         style: {
//           color: "#FFFFFF", // Set color for Y-axis labels
//         },
//       },
//     },
//     credits: {
//       enabled: false,
//     },
//     exporting: {
//       enabled: false,
//     },
//     legend: {
//       enabled: true,
//       layout: "horizontal", // Set the layout to vertical
//       align: "right", // Align the legend to the right
//       verticalAlign: "top", // Center the legend vertically
//       itemStyle: {
//         color: "#FFFFFF", // Set the color of legend items
//       },
//       labelFormatter: function () {
//         return this.userOptions.display_name || this.name;
//       },
//     },
//     tooltip: {
//       formatter: function () {
//         return (
//           "<b>" +
//           (this.point.series.userOptions.display_name ||
//             this.point.series.name) +
//           "</b>: " +
//           this.y
//         );
//       },
//     },
//     series: props.funnelChartData?.data?.series,
//   };

//   return  <div style={{
//     // backgroundColor:'red',
//   // border:"1px solid red",
//   // height: isSmall||isMedium?"400px":" ",
//   overflowx: (isSmall||isMedium)?"auto":"", /* Enable horizontal scrollbar */
//   overflowy:(isSmall||isMedium)?"hidden":"", /* Hide vertical scrollbar */
//   whitespace:(isSmall||isMedium)?"nowrap":"", /* Prevent line breaks */
//   overflow:(isSmall||isMedium)?"scroll":""

// }}>
//     <div style={{
//       //  height: "100%",
//        width: (isMedium||isSmall)?"700px":"",
//        overflow:(isMedium||isSmall)?"scroll":"",
//     }}>
//     <HighchartsReact highcharts={Highcharts} options={options} />
//     </div>
//   </div>;
// };

// export default FunnelChart;

import React, { useState, useLayoutEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Stack, Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Flag } from "@mui/icons-material";

const FunnelChart = (props) => {
  //   console.log("data: ", props.multiLineData);
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  // const [isClicked, setisClicked] = useState(false);
console.log("iscllicked", props.isClicked)
  const options = {
    rangeSelector: {
      selected: 1,
    },

    chart: {
      zoomType: "xy",
      type: "spline",
      height: 500,
      backgroundColor: "black",
      // backgroundColor: "#1c2427", // Set your desired background color her
      resetZoomButton: {
        theme: {
          fill: "red",
          stroke: "#d6ff41",
          style: {
            color: "white",
          },
        },
      },
    },
    title: {
      text: null,
    },

    xAxis: {
      labels: {
        style: {
          color: "#FFFFFF", // Set color for x-axis labels
        },
      },
      categories: props.funnelChartData?.data?.chartdata?.xAxis?.Category,
    },
    yAxis: {
      min:0,
      gridLineWidth: 0,
      title: {
        text: null, // Set Y-axis title to null
      },
      labels: {
        style: {
          color: "#FFFFFF", // Set color for Y-axis labels
        },
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: true,
      layout: "horizontal", // Set the layout to vertical
      align: "right", // Align the legend to the right
      verticalAlign: "top", // Center the legend vertically
      itemStyle: {
        color: "#FFFFFF", // Set the color of legend items
      },
      labelFormatter: function () {
        return this.userOptions.display_name || this.name;
      },
    },
    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          (this.point.series.userOptions.display_name ||
            this.point.series.name) +
          "</b>: " +
          this.y
        );
      },
    },
    series: props.funnelChartData?.data?.series,
  };

  return (
    <div
      style={{
        height: isMedium || isSmall ? "100%" : "",
        //  border:"2px solid red"
      }}
      onClick={() => {
        // setisClicked(!isClicked);
      }}
    >
      {!props.isClicked&&(isSmall || isMedium) ? (
        <div>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      ) : (
        <div
          style={{
            // backgroundColor:'red',
            // border:"1px solid red",
            // height: isSmall || isMedium ? "400px" : "",
            overflowX:
              isSmall || isMedium
                ? "auto"
                : "" /* Enable horizontal scrollbar */,
            overflowY:
              isSmall || isMedium ? "hidden" : "" /* Hide vertical scrollbar */,
            whiteSpace:
              isSmall || isMedium ? "nowrap" : "" /* Prevent line breaks */,
            overflow: isSmall || isMedium ? "scroll" : "",
          }}
        >
          <div
            style={{
              //  height: "100%",
              width: isMedium || isSmall ? "700px" : "",
              overflow: isMedium || isSmall ? "scroll" : "",
            }}
          >
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
        </div>
      )}
    </div>
  );
};

export default FunnelChart;
