import Typography from "@mui/material/Typography";
// import { Box, Stack } from "@mui/material";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Stack, Box, useTheme, useMediaQuery } from "@mui/material";
const Card = ({
  header1,
  value,
  header2,
  percent,
  height,
  width,
  absval,
  header3,
  basevalue,
  marginLeft,
}) => {
  const convertToNumber = (stringValue) => {
    return parseFloat(stringValue?.replace("%", ""));
  };

  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background:
          "radial-gradient(66.07% 63.32% at 50% -45.72%, rgba(220, 255, 91, 0.7) 0%, rgba(28, 36, 39, 0) 100%)",
        border: "1px solid #d6ff41",
        borderRadius: "10px",
        color: "white",
        textAlign: "center",
        width: isSmall || isMedium ? "100%" : width,
        height: isSmall || isMedium ? "100%":height,
        // padding: "0 1rem",
        // border:"10px solid green"
      }}
    >
      <Box
        sx={{
          // paddingTop: "1rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          gap: "0.05rem",
          alignItems: "center",
          width: "100%",
          height: "100%",
          // border: "1px solid red",
        }}
      >
        <Typography
          sx={{ fontSize: isSmall || isMedium ? "1.2rem" : "0.85rem" }}
        >
          {header1}
        </Typography>
        <Typography
          sx={{
            fontSize: isSmall || isMedium ? "0.8rem" : "0.85rem",
            fontWeight: 700,
          }}
        >
          {value}
        </Typography>
        <Typography
          sx={{ fontSize: isSmall || isMedium ? "0.7rem" : "0.75rem" }}
        >
          {header2}
        </Typography>
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ marginLeft: marginLeft }}
        >
          <Typography
            sx={{
              fontSize: isSmall || isMedium ? "1rem" : "0.75rem",
              color: convertToNumber(percent) > 0 ? "#d6ff41" : "#fb923c",
            }}
          >
            {percent}
          </Typography>
          {convertToNumber(percent) > 0 ? (
            <ArrowDropUpIcon sx={{ color: "#d6ff41" }} />
          ) : (
            <ArrowDropDownIcon sx={{ color: "#fb923c" }} />
          )}
        </Stack>
        <Typography
          sx={{
            fontSize: isSmall || isMedium ? "1rem" : "0.75rem",
            color: convertToNumber(percent) > 0 ? "#d6ff41" : "#fb923c",
          }}
        >
          {absval}
        </Typography>
        <Typography sx={{ fontSize: isSmall || isMedium ? "1rem" : "0.75rem" }}>
          {header3}
        </Typography>
        <Typography
          sx={{
            fontSize: isSmall || isMedium ? "0.8rem" : "0.75rem",
            fontWeight: 700,
          }}
        >
          {basevalue}
        </Typography>
      </Box>
    </Box>
  );
};

export default Card;
