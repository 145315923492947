import React, { useState } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import MultiSelect from "./MultiSelect";

const SaveUser = (props) => {
  const date = new Date();
  date.setDate(date.getDate() + 1);

  const [startDate, setStartDate] = useState(date);

  const MyContainer = ({ className, children }) => {
    return (
      <div style={{ padding: "16px", color: "#fff" }}>
        <CalendarContainer className={className}>
          <div style={{ background: "#ffff", color: "#ffff" }}></div>
          <div style={{ position: "relative", background: "#0000" }}>
            {children}
          </div>
        </CalendarContainer>
      </div>
    );
  };

  const setDate = (date) => {
    props.setDeactivationDate(date);
    setStartDate(date);
  };

  return (
    <>
      <div
        className="modal fade bs-save-result-modal-center show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel "
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered" style={{ display: "block" }}>
          <div className="modal-content ">
            <div className="modal-body model-result-popup ">
              <div className="row">
                <div
                  className="col-sm-12"
                  onClick={() => props.setShowForm(false)}
                >
                  <i
                    className="ri-close-fill model-close f-right"
                    data-bs-dismiss="modal "
                    aria-label="Close "
                  ></i>
                </div>
              </div>
              {props.SaveResponse === "" ? (
                <>
                  <div className="row align-items-center ">
                    <div className="col-sm-5 ">
                      <h3 className="model-result-popup-h3 ">First Name :</h3>
                    </div>
                    <div className="col-sm-7 ">
                      <input
                        className="form-control text-transform-none"
                        type="text "
                        placeholder="First name"
                        value={props.FirstName}
                        onChange={(e) => props.setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-5"></div>
                    <div className="col-sm-7">
                      <span className="error">{props.FirstNameError}</span>
                    </div>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-sm-5 ">
                      <h3 className="model-result-popup-h3 ">Last Name :</h3>
                    </div>
                    <div className="col-sm-7 ">
                      <input
                        className="form-control text-transform-none"
                        type="text "
                        placeholder="Last name"
                        value={props.LastName}
                        onChange={(e) => props.setLastName(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-5"></div>
                    <div className="col-sm-7">
                      <span className="error">{props.LastNameError}</span>
                    </div>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-sm-5 ">
                      <h3 className="model-result-popup-h3 ">User Name :</h3>
                    </div>
                    <div className="col-sm-7 ">
                      <input
                        className="form-control text-transform-none"
                        type="text "
                        placeholder="User name"
                        value={props.UserName}
                        onChange={(e) => props.setUserName(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-5"></div>
                    <div className="col-sm-7">
                      <span className="error">{props.UserNameError}</span>
                    </div>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-sm-5 ">
                      <h3 className="model-result-popup-h3 ">Password :</h3>
                    </div>
                    <div className="col-sm-7 ">
                      <input
                        className="form-control text-transform-none"
                        type="password"
                        placeholder="Password"
                        autoComplete="new-password"
                        value={props.Password}
                        onChange={(e) => props.setPassword(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-5"></div>
                    <div className="col-sm-7">
                      <span className="error">{props.PasswordError}</span>
                    </div>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-sm-5 ">
                      <h3 className="model-result-popup-h3 ">
                        Confirm Password :
                      </h3>
                    </div>
                    <div className="col-sm-7 ">
                      <input
                        className="form-control text-transform-none"
                        type="password"
                        placeholder="Confirm Password"
                        value={props.CPassword}
                        onChange={(e) => props.setCPassword(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-5"></div>
                    <div className="col-sm-7">
                      <span className="error">{props.CPasswordError}</span>
                    </div>
                  </div>
                  
                  <div className="row align-items-center ">
                    <div className="col-sm-5 ">
                      <h3 className="model-result-popup-h3 ">
                        Deactivation Date :
                      </h3>
                    </div>
                    <div className="col-sm-7 ">
                      
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        selected={startDate}
                        onChange={(date) => setDate(date)}
                        calendarContainer={MyContainer}
                        minDate={date}
                      />
                    </div>
                    <div className="col-sm-5"></div>
                    <div className="col-sm-7">
                      <span className="error">
                        {props.DeactivationDateError}
                      </span>
                    </div>
                  </div>

                  {/* new code */}
                  <div className="row align-items-center ">
                    <div className="col-sm-5 ">
                      <h3 className="model-result-popup-h3 ">Tab Access :</h3>
                    </div>
                    <div className="col-sm-7 ">
                      <MultiSelect setTabAccess={props.setTabAccess} selectedOptionsTabs={props.selectedOptionsTabs} AddUserMultiTabs={props.AddUserMultiTabs} tabNames={props.tabNames} />
                    </div>
                  </div>


                  <div className="row align-items-center ">
                    <div className="col-sm-5 ">
                      <h3 className="model-result-popup-h3 ">Super User :</h3>
                    </div>
                    <div className="col-sm-7 ">
                      <input
                        type="checkbox"
                        checked={props.isSuperUser}
                        onChange={(e) => props.setIsSuperUser(e.target.checked)}
                      />
                    </div>
                  </div>
                  {/* new code */}
                  <div className="row align-items-center ">
                    <div className="col-12 text-center">
                      <button
                        onClick={() => props.SaveUserData()}
                        className="btn btn-modal-primary"
                      >
                        Save User
                      </button>
                    </div>
                  </div>                  
                </>
              ) : (
                <>
                  <h1 style={{ textAlign: "center", color: "lime" }}>
                    {props.SaveResponse}
                  </h1>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SaveUser;
