import { Box } from "@mui/material";
import React from "react";
import Tabs from "./Tabs";

const BottomSection = (props) => {
  return (
    <Box
      sx={{
        padding: "0.5rem",
        backgroundColor: "bgColor",
        // backgroundColor: "black",
        borderRadius: "0 0 20px 20px",
        height: "70.5%"
      }}
    >
      <Tabs
        funnelData={props.funnelData}
        stackSeries={props.stackSeries}
        stackCategories={props.stackCategories}
        stackNonclickList={props.stackNonclickList}
        DrillDownTrends={props.DrillDownTrends}
        NotAllow={props.NotAllow}
        DrillAllTrends={props.DrillAllTrends}
        ResetAllTrends={props.ResetAllTrends}
        DrillDownAllTrends={props.DrillDownAllTrends}
        DrillUpAllTrends={props.DrillUpAllTrends}
        campData={props.campData}
        DrillAllCamp={props.DrillAllCamp}
        ResetAllCamp={props.ResetAllCamp}
        DrillUpAllCamp={props.DrillUpAllCamp}
        DrillDownCamp={props.DrillDownCamp}
        DrillDownAllCamp={props.DrillDownAllCamp}
        tabNames={props.tabNames}
        baseLineSeries={props.baseLineSeries}
        baseLineData={props.baseLineData}
        matchId={props.matchId}
        startdate={props.startdate}
        enddate={props.enddate}
        city={props.city}
        time={props.time}
        defaultKpiFilter={props.defaultKpiFilter}
        team={props.team}
        setActiveTab={props.setActiveTab}
        activeTab={props.activeTab}
        lineData={props.lineData}
        DrillUpCamp={props.DrillUpCamp}
        funnelChartData={props.funnelChartData}
        matchStackData={props.matchStackData}
        DrillDownMatch={props.DrillDownMatch}
        DrillAllMatch={props.DrillAllMatch}
        ResetAllMatch={props.ResetAllMatch}
        DrillDownAllMatch={props.DrillDownAllMatch}
        DrillUpAllMatch={props.DrillUpAllMatch}
      />
    </Box>
  );
};

export default React.memo(BottomSection);
