import React from "react";
import { Stack, Typography, Box, Button,useTheme, useMediaQuery } from "@mui/material";

import { Select, Option } from "./CustomSelect";
import DateInput from "./DateInput";
// import { Stack, Box, useTheme, useMediaQuery } from "@mui/material";
const Form = (props) => {
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));
  return (
    <Stack
      sx={{
        width:(isSmall||isMedium) ?"100%":"30%",
        height: "100%",
        backgroundColor: "bgColor",
        // backgroundColor:"red",
        borderRadius: "20px",
        padding: "1rem 1rem 0 1rem",
        position: "relative",
      }}
    >
      <Stack gap={3} sx={{ height: "90%" }}>
        {/* timeperiod start */}

        <Stack
          direction={"column"}
          gap={2}
          sx={{
            padding: "0.5rem 0 1rem 0",
            borderBottom: "1px solid black",
          }}
        >
          <Typography sx={{ color: "themeColor", fontSize: "0.75rem" }}>
            Time Period:{" "}
          </Typography>

          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography sx={{ color: "white", fontSize: "0.75rem" }}>
              From:{" "}
            </Typography>

            <DateInput
              type={"date"}
              value={props.defaultStartDate}
              onChange={props.setDefaultStartDate}
              max={props.defaultEndDate}
              setIsSubmit={props.setIsSubmit}
              setDATA={props.setDATA}
            />
            <Typography sx={{ color: "white", fontSize: "0.75rem" }}>
              To:{" "}
            </Typography>

            <DateInput
              type={"date"}
              value={props.defaultEndDate}
              onChange={props.setDefaultEndDate}
              min={props.defaultStartDate}
              setIsSubmit={props.setIsSubmit}
              setDATA={props.setDATA}
            />
          </Stack>
        </Stack>
        {/* timeperiod end */}

        {/* kpi and view start */}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ borderBottom: "1px solid black", padding: "1rem 0" }}
        >
          <Stack direction={"row"} gap={2}>
            <Typography sx={{ color: "themeColor", fontSize: "0.75rem" }}>
              Select KPI:{" "}
            </Typography>
            <Select
              value={props.defaultKPI}
              selected={props.defaultKPI}
              onChange={(e) => {
                props.setDefaultKPI(e.target.value);
                props.setIsSubmit(false);
                props.setDATA([]);
              }}
              sx={{
                backgroundColor: "bgColor",
                border: "1px solid #3E5056",
                borderRadius: "4px",
                color: "white",
                fontSize: "0.75rem",
                padding: "0.1rem 0.2rem",
              }}
            >
              {props.allKPI &&
                props.allKPI.map((kpi) => {
                  return (
                    <Option key={kpi.value} value={kpi.value}>
                      {kpi.label}
                    </Option>
                  );
                })}
            </Select>
          </Stack>
          {/* view options */}
          <Stack direction={"row"} gap={2}>
            <Typography sx={{ color: "themeColor", fontSize: "0.75rem" }}>
              View Options:{" "}
            </Typography>
            <Select
              value={props.defaultView}
              selected={props.defaultView}
              onChange={(e) => {
                props.setDefaultView(e.target.value);
                props.setIsSubmit(false);
                props.setDATA([]);
              }}
              sx={{
                backgroundColor: "bgColor",
                border: "1px solid #3E5056",
                borderRadius: "4px",
                color: "white",
                fontSize: "0.75rem",
                padding: "0.1rem 0.2rem",
              }}
            >
              {props.allViews &&
                props.allViews.map((view) => {
                  return (
                    <Option key={view.value} value={view.value}>
                      {view.label}
                    </Option>
                  );
                })}
            </Select>
          </Stack>
          {/* view options */}
        </Stack>

        {/* kpi and view end */}

        {/* ############################################## */}
        {/* optimize for */}

        <Stack sx={{ padding: "1rem 0" }} gap={3}>
          <Typography
            sx={{
              color: "themeColor",
              fontSize: "0.75rem",
              textTransform: "uppercase",
            }}
          >
            Optimize For
          </Typography>

          {/* fixed budget */}
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <input
              type="radio"
              className="form-check-input"
              checked={props.fixedBudget}
              onChange={() => {
                props.setFixedBudget(true);
                props.setFixedSpots(false);
                props.setIsSubmit(false);
                props.setDATA([]);
              }}
              style={{ cursor: "pointer", marginTop: 0 }}
            />
            <Typography sx={{ color: "white", fontSize: "0.75rem" }}>
              Fixed Budget
            </Typography>
            <input
              disabled={!props.fixedBudget ? true : false}
              value={props.fixedBudgetValue}
              onChange={(e) => {
                props.setFixedBudgetValue(e.target.value);
                props.setIsSubmit(false);
                props.setDATA([]);
              }}
              style={{
                width: 50,
                outline: "none",
                border: "1px solid #3E5056",
                backgroundColor: "#1c2427",
                borderRadius: "4px",
                color: "white",
                fontSize: "0.75rem",
                padding: "0.1rem 0.2rem",
                opacity: !props.fixedBudget ? 0.3 : 1,
              }}
            />
          </Box>
          {/* fixed budget */}

          {/* fixed spots */}
          <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <input
                type="radio"
                className="form-check-input"
                checked={props.fixedSpots}
                onChange={() => {
                  props.setFixedSpots(true);
                  props.setFixedBudget(false);
                  props.setIsSubmit(false);
                  props.setDATA([]);
                }}
                style={{ cursor: "pointer", marginTop: 0 }}
              />
              <Typography sx={{ color: "white", fontSize: "0.75rem" }}>
                Fixed Spots
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              // width: "70%",
            }}
          >
            <Typography
              sx={{ color: "white", fontSize: "0.75rem", fontWeight: 700 }}
            >
              Constraints
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ color: "white", fontSize: "0.75rem" }}>
                Minimum Target Matches:
              </Typography>
              <input
                disabled={props.fixedBudget ? true : false}
                value={props.min_match}
                onChange={(e) => {
                  props.setMin_match(e.target.value);
                  props.setIsSubmit(false);
                  props.setDATA([]);
                }}
                style={{
                  width: 50,
                  outline: "none",
                  border: "1px solid #3E5056",
                  backgroundColor: "#1c2427",
                  borderRadius: "4px",
                  color: "white",
                  fontSize: "0.75rem",
                  padding: "0.1rem 0.2rem",
                  opacity: props.fixedBudget ? 0.3 : 1,
                }}
              />{" "}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ color: "white", fontSize: "0.75rem" }}>
                Total TV Spots:
              </Typography>
              <input
                disabled={props.fixedBudget ? true : false}
                value={props.max_tv}
                onChange={(e) => {
                  props.setMax_tv(e.target.value);
                  props.setIsSubmit(false);
                  props.setDATA([]);
                }}
                style={{
                  width: 50,
                  outline: "none",
                  border: "1px solid #3E5056",
                  backgroundColor: "#1c2427",
                  borderRadius: "4px",
                  color: "white",
                  fontSize: "0.75rem",
                  padding: "0.1rem 0.2rem",
                  opacity: props.fixedBudget ? 0.3 : 1,
                }}
              />{" "}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ color: "white", fontSize: "0.75rem" }}>
                Total CTV Spots:
              </Typography>
              <input
                disabled={props.fixedBudget ? true : false}
                value={props.max_ctv}
                onChange={(e) => {
                  props.setMax_ctv(e.target.value);
                  props.setIsSubmit(false);
                  props.setDATA([]);
                }}
                style={{
                  width: 50,
                  outline: "none",
                  border: "1px solid #3E5056",
                  backgroundColor: "#1c2427",
                  borderRadius: "4px",
                  color: "white",
                  fontSize: "0.75rem",
                  padding: "0.1rem 0.2rem",
                  opacity: props.fixedBudget ? 0.3 : 1,
                }}
              />{" "}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ color: "white", fontSize: "0.75rem" }}>
                Maximum CTV Spots Per Match:
              </Typography>
              <input
                disabled={props.fixedBudget ? true : false}
                value={props.max_tvmatch}
                onChange={(e) => {
                  props.setMax_tvmatch(e.target.value);
                  props.setIsSubmit(false);
                  props.setDATA([]);
                }}
                style={{
                  width: 50,
                  outline: "none",
                  border: "1px solid #3E5056",
                  backgroundColor: "#1c2427",
                  borderRadius: "4px",
                  color: "white",
                  fontSize: "0.75rem",
                  padding: "0.1rem 0.2rem",
                  opacity: props.fixedBudget ? 0.3 : 1,
                }}
              />{" "}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ color: "white", fontSize: "0.75rem" }}>
                Maximum TV Spots Per Match:
              </Typography>
              <input
                disabled={props.fixedBudget ? true : false}
                value={props.max_ctvmatch}
                onChange={(e) => {
                  props.setMax_ctvmatch(e.target.value);
                  props.setIsSubmit(false);
                  props.setDATA([]);
                }}
                style={{
                  width: 50,
                  outline: "none",
                  border: "1px solid #3E5056",
                  backgroundColor: "#1c2427",
                  borderRadius: "4px",
                  color: "white",
                  fontSize: "0.75rem",
                  padding: "0.1rem 0.2rem",
                  opacity: props.fixedBudget ? 0.3 : 1,
                }}
              />{" "}
            </Box>
          </Box>
          {/* fixed spots */}
        </Stack>
      </Stack>
      {/* buttons */}
      <Stack
        direction={"row"}
        justifyContent={"center"}
        gap={4}
        sx={{
          // position: "absolute",
          // bottom: 0,
          padding: "1rem 0",
          // zIndex: 999
          // left: "50%",
          // transform: "translateX(-50%)",
          height: "10%",
          // border: "1px solid red",
        }}
      >
        <Button
          onClick={() => {
            props.setIsSubmit(true);
            props.setFetchCall(true);
          }}
          sx={{
            backgroundColor: "themeColor",
            color: "black",
            "&:hover": {
              backgroundColor: "themeColor",
              color: "black",
            },
          }}
        >
          Optimize
        </Button>
        <Button
          onClick={() => {
            // props.setIsSubmit(false);
            // props.setFetchCall(false);
            props.fromClearHandler();
            props.setDATA([]);
          }}
          sx={{
            backgroundColor: "inputBorderColor",
            color: "white",
            "&:hover": {
              backgroundColor: "inputBorderColor",
              color: "white",
            },
          }}
        >
          clear
        </Button>
      </Stack>
    </Stack>
  );
};

export default Form;
