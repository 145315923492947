import "./DatePicker.css";

const DateInput = ({
  type,
  value,
  onChange,
  min,
  max,
  setDisabledFlag,
  setFetchCall,
}) => {
  return (
    <input
      className="custom-date-picker"
      onChange={(e) => {
        onChange(e.target.value);
        setDisabledFlag(false);
        setFetchCall(false);
      }}
      style={{
        outline: "none",
        border: "1px solid #3E5056",
        backgroundColor: "#1c2427",
        padding: "0.1rem 0.4rem",
        fontSize: "0.85rem",
        color: "white",
        borderRadius: "5px",
      }}
      type={type}
      value={value}
      min={min}
      max={max}
    />
  );
};

export default DateInput;
