const findAllLevelChildren = (userInputKey, flatObject) => {
  let VALUE; // for user input key --> l-hco-gar-trad-tv
  let brandId; // flatObj[0] l-hco-gar-trad-tv --> l-hco-gar
  let brandList; // [l-hco, l]
  let channelList; // [trad-tv, trad]

  Object.keys(flatObject).forEach((item) => {
    if (item === userInputKey) {
      VALUE = flatObject[item];
      brandId = VALUE[0];
      brandList = brandId.split("-");
    }
  });

  const generateParentBrandNames = (brandId) => {
    const parts = brandId?.split("-");
    const result = [];

    for (let i = 1; i < parts?.length; i++) {
      const brandName = parts?.slice(0, parts.length - i).join("-");
      result.push(brandName);
    }

    return result;
  };

  brandList = generateParentBrandNames(brandId);

  const findChannelParent = (foundKey) => {
    let str = foundKey.replace(`${brandId}-`, "");
    const parts = str.split("-");
    const output = [];

    for (let i = parts.length - 1; i > 0; i--) {
      const subArray = parts.slice(0, i);
      output.push(subArray.join("-"));
    }

    return output;
  };

  channelList = findChannelParent(userInputKey);
  const channelID = userInputKey.replace(`${brandId}-`, "");
  channelList.unshift(channelID);
  brandList.unshift(brandId);
  // console.log("Brand ID: ---> ", brandId);
  // console.log("Brand List: ---> ", brandList);
  // console.log("Channel ID: ---> ", channelID);
  // console.log("Channel List: --->", channelList);

  let allChildrenForUserInputKey = []; // all childs for userinputkey

  // code for find the allchildren for userinputkey ............start.....
  const findAllChidlrenForUserInputKey = (flatObject, brandId, channelID) => {
    let arr = [];
    // let filterArr = [];
    Object.keys(flatObject).forEach((item) => {
      if (item.includes(brandId)) {
        arr.push(item);
      }
    });

    arr.forEach((channel) => {
      if (channel.includes(channelID)) {
        // console.log(channel);
        if (channel !== userInputKey) {
          allChildrenForUserInputKey.push(channel);
        }
      }
    });
  };

  findAllChidlrenForUserInputKey(flatObject, brandId, channelID);
  const channelParent = channelList[channelList.length - 1];
  // console.log("channel parent: ", channelParent);
  const result = allChildrenForUserInputKey.map((key) => {
    const channelIndex = key.lastIndexOf(channelID);
    // adding channel level
    const words = key.split("-");

    // Find the index of the "parent" word in the array
    const parentIndex = words.indexOf(channelParent);

    // Calculate the channel level by counting the words after "parent"
    const channelLevel = words.length - parentIndex - 1;

    //adding channel level ..end

    if (channelIndex !== -1) {
      const brand_level =
        key.slice(brandId.length + 1, channelIndex).split("-").length - 1;
      return { key, brand_level, channel_level: channelLevel };
    }

    return { key, brand_level: 0, channel_level: channelLevel };
  });

  function generateOutput(data) {
    const output = {};

    data.forEach((item) => {
      const brandLevel = item.brand_level.toString();
      const channelLevel = item.channel_level.toString();

      if (!output[brandLevel]) {
        output[brandLevel] = {};
      }

      if (!output[brandLevel][channelLevel]) {
        output[brandLevel][channelLevel] = [];
      }

      // push all the non-changed children
      Object.keys(flatObject).forEach((flatKey) => {
        if (flatKey === item.key) {
          if (
            flatObject[flatKey][3] === undefined ||
            flatObject[flatKey][3] === false
          ) {
            // console.log("non-changed children key: ", flatKey);
            output[brandLevel][channelLevel].push(item.key);
          }
        }
      });
      // changed check

      // output[brandLevel][channelLevel].push(item.key);
    });

    return output;
  }

  const output = generateOutput(result);
  // console.log("All Children: ", result);
  // console.log(output);

  return output;
};

export default findAllLevelChildren;
