import { Stack, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const Allocation = ({
  totalSpend,
  channelsSpend,
  userBarFillObject,
  allowEdit,
  checked,
  DATA,
}) => {
  const [avialable] = useState(DATA.spend);
  const [sum, setSum] = useState(0);
  // console.log("user fill bar sum: ", sum, avialable);

  useEffect(() => {
    let sum = 0;
    Object.entries(userBarFillObject).map((item) => {
      const [, value] = item;
      sum += value;
      return sum;
    });
    setSum(sum);
  }, [userBarFillObject]);

  return (
    <Stack
      direction={"row"}
      justifyContent={"center"}
      spacing={3}
      sx={{
        padding: "1rem 0",
        height: "100%",
        backgroundColor: "#1C2427",
        borderRadius: "1rem",
        width: checked ? "33%" : "23%",
      }}
    >
      <Stack sx={{ height: "100%" }} alignItems={"center"} spacing={1}>
        <Typography
          variant="h6"
          sx={{
            color: "white",
            fontWeight: 700,
          }}
        >
          Allocation
        </Typography>
        <Stack sx={{ height: "100%" }} spacing={1}>
          <Stack direction={"row"} sx={{ height: "100%" }} spacing={2}>
            {/* first stack */}
            <Stack textAlign={"center"} alignItems={"center"}>
              {allowEdit && (
                <Typography
                  variant="h6"
                  sx={{
                    color: "white",
                    fontWeight: 700,
                  }}
                >
                  Channel
                </Typography>
              )}
              <Stack
                sx={{
                  border: "1px solid gray",
                  borderRadius: "10px",
                  overflow: "hidden",
                  height: "100%",
                  width: 40,
                }}
              >
                {Object.keys(channelsSpend).map((channelName, index) => {
                  const [value, color] = channelsSpend[channelName];
                  const height = (value / totalSpend) * 100;
                  return (
                    <Box
                      key={index}
                      sx={{
                        backgroundColor: color,
                        height: `${height}%`,
                        width: 40,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        color: "white",
                        padding: "0 0.5rem",
                      }}
                    >
                      <Typography variant="body1" fontWeight={700}>
                        {value?.toFixed(2)}
                      </Typography>
                    </Box>
                  );
                })}
              </Stack>
            </Stack>

            {/* 2nd stack */}

            {allowEdit && (
              <Stack textAlign={"center"} alignItems={"center"}>
                {allowEdit && (
                  <Typography
                    variant="h6"
                    sx={{
                      color: "white",
                      fontWeight: 700,
                    }}
                  >
                    User
                  </Typography>
                )}
                <Stack
                  sx={{
                    border: "1px solid gray",
                    borderRadius: "10px",
                    overflow: "hidden",
                    height: "100%",
                    width: 40,
                    backgroundColor: "#1C2427",
                  }}
                >
                  {/* total */}

                  <Stack
                    sx={{
                      height: `${((avialable - sum) / avialable) * 100}%`,
                      width: 40,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                      color: "white",
                      padding: "0 0.5rem",
                      textAlign: "center",
                      // border: "1px solid red",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={700}
                      sx={{
                        position: "absolute",
                        zIndex: 22222222222,
                      }}
                    >
                      {(avialable - sum)?.toFixed(2)}
                    </Typography>
                  </Stack>
                  {/* ................... */}
                  {Object.entries(userBarFillObject).map(
                    ([color, value], index) => {
                      const height = (value / avialable) * 100;
                      // console.log("Height of filled box: ", height?.toFixed(2));
                      return (
                        <Box
                          key={index}
                          sx={{
                            backgroundColor: color,
                            height: `${height?.toFixed(2)}%`,
                            width: 40,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "bold",
                            color: "white",
                            padding: "0 0.5rem",
                          }}
                        >
                          <Typography variant="body1" fontWeight={700}>
                            {value !== 0 ? value.toFixed(2) : null}
                          </Typography>
                        </Box>
                      );
                    }
                  )}

                  {/* .............. */}
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>

      {/* end */}
    </Stack>
  );
};

export default Allocation;
