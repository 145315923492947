import React from 'react'
import Channel from './Channel';
import FooterOptimise from './FooterOptimise';


const TableData = (props) => {
    // console.log(props.AllowEdit, "props.AllowEdit")
    return (
        <div className="col-xs-12 col-md-12 col-lg-12 col-xl-7">
            <div className="card mb-0 optimizer-table-height">
                <div className="card-body">
                    <div className="table-responsive optimizer-table-responsive">
                        <table className="table table-borderless mb-0">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th className="text-left text-white data-spends">Channels</th>
                                    <th className="text-center">
                                        <select
                                            className="form-select"
                                            value={props.SelectedYear}
                                            onChange={props.YearHandler}
                                        >
                                            {props.PreviousData.map((item) => {
                                                return (
                                                    <option key={item.year} value={item.year}>
                                                        {item.year}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </th>
                                    <th className="text-center text-white"></th>
                                    <th className="text-center text-white data-spends">{props.NewData.year}</th>
                                    <th className="text-center text-white data-spends">Edit spends</th>
                                    <th className="text-center text-white">
                                        <div className="form-check form-switch m-0 f-right">
                                            <input
                                                type="checkbox"
                                                checked={props.AllowEdit}
                                                onChange={(e) => props.setAllowEdit(!props.AllowEdit)}
                                                className="form-check-input"
                                            />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                          

                            {
                                
                                JSON.stringify(props.Summary) !== "{}" ?
                                    <tbody>
                                        {
                                            Object.keys(props.Summary.channels).map((channel, index) => {
                                                return (
                                                    <Channel
                                                        key={index}
                                                        index={index}
                                                        Summary={props.Summary}
                                                        NewData={props.NewData}
                                                        NewFixedData={props.NewFixedData}
                                                        channel={channel}
                                                        AllowEdit={props.AllowEdit}

                                                        EditNewSpends={props.EditNewSpends}
                                                        EditNewSpendsOnBlur={props.EditNewSpendsOnBlur}
                                                        ResetSpends={props.ResetSpends}

                                                        ChannelExpand={props.ChannelExpand}
                                                        ChannelExpand1={props.ChannelExpand1}
                                                        handleExpandChannel={props.handleExpandChannel}
                                                        handleShrinkChannel={props.handleShrinkChannel}
                                                        handleExpandChannel1={props.handleExpandChannel1}
                                                        handleShrinkChannel1={props.handleShrinkChannel1}
                                                        Levels={props.Levels}
                                                        AddLevel={props.AddLevel}
                                                        RemoveLevel={props.RemoveLevel}
                                                        traverse={props.traverse}
                                                        flattened={props.flattened}
                                                        setIsExecuted={props.setIsExecuted}
                                                        setUserInputKey={props.setUserInputKey}
                                                    />
                                                )
                                            })
                                        }
                                    </tbody>
                                    : null
                            }

                            <FooterOptimise
                                setSaveResult={props.setSaveResult}
                                AllowEdit={props.AllowEdit}
                                Optimise={props.Optimise}
                                NewFixedData={props.NewFixedData}
                                NewData={props.NewData}
                                DownloadOptimizer={props.DownloadOptimizer}
                                setRe={props.setRe}
                            />

                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableData