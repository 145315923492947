import React from 'react'

const AllHeirarchyFilter = (props) => {

  // console.log("props.Fixed: ", props.Fixed);
  // console.log("props.ActiveFilters: ", props.ActiveFilters);

  // console.log("props.AllFilters: ", props.AllFilters);

  // console.log("props.Chartdata: ", props.Chartdata);

  const bgColor = (node) => {
    if (props.Chartdata.length === 1) {
      if (props.Chartdata[0].hasOwnProperty(node)) {
        if (props.Chartdata[0][node].Filter?.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    } else if (props.Chartdata.length === 2) {
      if (props.Chartdata[0].hasOwnProperty(node)) {
        if (props.Chartdata[0][node].Filter?.length > 0) {
          return true;
        } else {
          if (props.Chartdata[1].hasOwnProperty(node)) {
            if (props.Chartdata[1][node].Filter?.length > 0) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    }
  };

 

  return (
    <div className={props.OnlyHeirarchyFilter ? "col-xl-10" : "col-xl-5 "}>
      <div className="card my-1">
        <div className="card-body allFilter pb-0 pt-0">
          <h6 className="m-0 text-center cm-filter-title">Heirarchy Filter   </h6>
          <ul id="scroller" className="p-1 mx-1">
            {
              props.Fixed.map((item, index) => {
                return (
                  <li key={index} className={false ? "cm-filter-option-li-filter-active" : "cm-filter-option-li"}>
                    <span className="btn cm-drag-btn p-1 mx-2 active" title={item.display}>
                      <p className="m-0 text-left px-1">{item.id}</p>
                      <p className="m-0 px-1">
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      </p>
                    </span>
                  </li>
                )
              })
            }
            {
              props.ActiveFilters.map((item, index) => {
                return (
                  <li key={index} className={false ? "cm-filter-option-li-filter-active" : "cm-filter-option-li"} onClick={() => props.RemoveHeirarchyFilter(item)}>
                    <span className="btn cm-drag-btn p-1 mx-2 active" title={item.display_name}>
                      <p className="m-0 text-left px-1">{item.name}</p>
                      <p className="m-0 px-1">
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      </p>
                    </span>
                  </li>
                )
              })
            }
            {
              props.AllFilters.map((item, index) => {
                return (
                  (!props.IsInAllHeirarchyFilters(item)) ? <li key={index} className="cm-filter-option-li" onClick={() => props.AddHeirarchyFilter(index)}>
                    <span className="btn cm-drag-btn p-1 mx-2" title={item.display_name}>
                      <p className="m-0 text-left px-1">{item.name}</p>
                      <p className="m-0 px-1">
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </p>
                    </span>
                  </li> : null
                )
              })
            }
          </ul>
        </div>
      </div>
    </div>
  )
}

export default AllHeirarchyFilter;