import { useState, useEffect } from "react";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";

import axios from "../../../axios";
import Summary from "../Summary/Summary";

import Loader from "../../../Components/Loader";

const OptimizeForm = ({
  hierarchy,
  handler,
  setDeepDive,
  setOptimizationType,
  setOptimizationTypeValue,
  setSaveResponse,
  setGetBrandName,
  setGetKpiName,
  setGetTimeframe,
  setGetTimeperiod,
}) => {
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState("");
  const [kpi, setKPI] = useState("");
  const [allKPI, setAllKPI] = useState([]);
  const [timeFramesAndPeriod, setTimeFramesAndPeriod] = useState([]);
  const [timeFrameValue, setTimeFrameValue] = useState("");
  // console.log("timeFrameValue: ", timeFrameValue);
  const [timePeriodValue, setTimePeriodValue] = useState("");
  // console.log("timePeriodValue: ", timePeriodValue);

  const [percentageValue, setPercentageValue] = useState("");
  const [growth, setGrowth] = useState("");
  // console.log("growth: ", growth);

  const [sales, setSales] = useState("");
  const [budget, setBudget] = useState("");
  // console.log("budget: ", budget);

  const [loader, setLoader] = useState(false);
  const [hierarchyData, setHierarchyData] = useState([]);
  const [optimize, setOptimize] = useState(false);
  const [formCleared, setFormCleared] = useState(true);

  const [firstRadioChecked, setFirstRadioChecked] = useState(true);
  const [secondRadioChecked, setSecondRadioChecked] = useState(false);
  const [firstInputLabel, setFirstInputLabel] = useState("");
  const [secondInputLabel, setSecondInputLabel] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageFlag, setErrorMessageFlag] = useState(false);
  const [growthPercentageValue, setGrowthPercentageValue] = useState("");
  // console.log("growthPercentageValue: ", growthPercentageValue);

  // console.log(firstRadioChecked, secondRadioChecked);

  // const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  // console.log("Analyse: ", Analyse);

  // console.log("budget: ", budget);
  // console.log("growth: ", growth);

  useEffect(() => {
    if (firstRadioChecked) {
      setOptimizationType("budget");
      setOptimizationTypeValue(budget);
    } else {
      setOptimizationType("growth");
      setOptimizationTypeValue(growthPercentageValue);
    }
    setGetBrandName(brand);
    setGetTimeframe(timeFrameValue);
    setGetTimeperiod(timePeriodValue)
    setGetKpiName(kpi);
  }, [
    budget,
    growthPercentageValue,
    firstRadioChecked,
    secondRadioChecked,
    brand,
    kpi,
    timeFrameValue,
    timePeriodValue,
  ]);

  const Select = styled("select")(({ theme }) => ({
    outline: "none",
    backgroundColor: theme.palette.bgColor,
    color: "white",
    padding: "0.25rem",
    border: `1px solid ${theme.palette.inputBorderColor}`,
    borderRadius: "0.25rem",
    fontSize: "0.75rem",
    textTransform: "capitalize",
    // fontSize: theme.typography.h5.fontSize,
  }));

  const Option = styled("option")(({ theme }) => ({
    // fontSize: theme.typography.h5.fontSize,
  }));

  const firstRadioCheckedHandler = (e) => {
    setSecondRadioChecked(!secondRadioChecked);
    setFirstRadioChecked(!firstRadioChecked);
    // setErrorMessage("");
    // setErrorMessageFlag(false);
  };

  const secondRadioCheckedHandler = (e) => {
    setFirstRadioChecked(!firstRadioChecked);
    setSecondRadioChecked(!secondRadioChecked);
    // setErrorMessage("");
    // setErrorMessageFlag(false);
  };

  useEffect(() => {
    handler(hierarchyData);
  }, [hierarchyData, handler]);

  // console.log(hierarchyData);
  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      const response = await axios.post("get_optimiser_brand_kpi/", {});
      setBrands(response.data.data);
      setBrand(response.data.data[0]?.brand_short || "");
      setAllKPI(response.data.data[0]?.kpi || []);
      setKPI(response.data.data[0]?.kpi[0]?.kpi_short_code || "");
      setLoader(false);
    };

    if (formCleared) {
      fetchData();
      setFormCleared(false);
    }
  }, [formCleared]);

  const renderAllBrands = () => {
    if (brands.length > 0) {
      return brands.map((brand, index) => (
        <Option key={index} value={brand.brand_short}>
          {brand.brand_name}
        </Option>
      ));
    }
    return null;
  };

  const renderAllKPIDisplayNames = () => {
    if (allKPI.length > 0) {
      return allKPI.map((kpi) => (
        <Option key={kpi.kpi_short_code} value={kpi.kpi_short_code}>
          {kpi.kpi_display_name}
        </Option>
      ));
    }
    return null;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      if (brand !== "" && kpi !== "") {
        const response = await axios.post("get_opt_timeframe/", {
          brand,
          kpi,
        });
        setTimeFramesAndPeriod(response.data.data);
        setTimeFrameValue(response.data.data[0]?.key || "");
        setTimePeriodValue(response.data.data[0]?.dates[0]?.name || "");
        setSales(response.data.data[0]?.dates[0]?.sales || "");
        setBudget(response.data.data[0]?.dates[0]?.budget || "");
        setFirstInputLabel(`in ${response.data.data[0]?.dates[0].budget_unit}`);

        setSecondInputLabel(`in ${response.data.data[0]?.dates[0].sales_unit}`);

        setPercentageValue("");
        setGrowth("");
        setLoader(false);
      }
    };

    fetchData();
  }, [brand, kpi]);

  const handleFirstInputChange = (event) => {
    const selectedValue = event.target.value;
    setTimeFrameValue(selectedValue);

    // Set second input options based on selected value
    const matchingOption = timeFramesAndPeriod.find(
      (option) => option.key === selectedValue
    );
    if (matchingOption) {
      setTimePeriodValue(matchingOption.dates[0]?.name || "");
      setSales(matchingOption.dates[0]?.sales || "");
      setBudget(matchingOption.dates[0]?.budget || "");
      setFirstInputLabel(`in ${matchingOption.dates[0]?.budget_unit}`);
      setSecondInputLabel(`in ${matchingOption.dates[0]?.sales_unit} `);
    }

    setPercentageValue("");
    setGrowth("");
  };

  const handleSecondInputChange = (event) => {
    const selectedValue = event.target.value;
    setTimePeriodValue(selectedValue);

    // Update sales and budget based on selected time period
    const matchingOption = timeFramesAndPeriod.find(
      (option) => option.key === timeFrameValue
    );
    if (matchingOption) {
      const selectedDate = matchingOption.dates.find(
        (date) => date.name === selectedValue
      );
      if (selectedDate) {
        setSales(selectedDate.sales || "");
        setBudget(selectedDate.budget || "");
      }
    }
    setPercentageValue("");
    setGrowth("");
  };

  const handlePercentage = (e) => {
    let currentValue = e.target.value;
    let fixedGrowth = (currentValue * sales) / 100;
    // setPercentageValue(e.target.value);
    // setGrowth(fixedGrowth);
    if (/^\d*\.?\d*$/.test(currentValue)) {
      setPercentageValue(currentValue);
      setGrowthPercentageValue(parseFloat(currentValue));
      setGrowth(fixedGrowth);
    }
  };

  const handlerGrowth = (e) => {
    let currentValue = e.target.value;
    let fixedPercentageValue = (currentValue * 100) / sales;
    // setGrowth(e.target.value);
    setPercentageValue(fixedPercentageValue);
    if (/^\d*\.?\d*$/.test(currentValue)) {
      setGrowth(currentValue);
      setPercentageValue(fixedPercentageValue);
      setGrowthPercentageValue(parseFloat(fixedPercentageValue));
    }
  };
  const URL = "http://15.207.123.147:8080/hierarchy/";

  const payload_api = {
    brand: brand,
    kpi: kpi,
    changed: [],
    timeperiod: timePeriodValue,
    timeframe: timeFrameValue,
    budget: firstRadioChecked ? budget : growthPercentageValue,
    ddtag: "PrevYr",
    optimization_type: firstRadioChecked ? "budget" : "growth",
    result_name: "",
    theme: localStorage.getItem("CurrentTheme"),
  };


  const fetchData1 = async () => {
    const response = await axios.post("optimise/", {
      brand: brand,
      kpi: kpi,
      changed: [],
      timeperiod: timePeriodValue,
      timeframe: timeFrameValue,
      budget: firstRadioChecked ? budget : growthPercentageValue,
      ddtag: "PrevYr",
      optimization_type: firstRadioChecked ? "budget" : "growth",
      result_name: "",
      theme: localStorage.getItem("CurrentTheme"),
    });
    // setHierarchyData(response.data);
    setSaveResponse(response.data);
    // setLoader(false);
    // setOptimize(true);
  };
  const optimizeHandler = () => {
    if (firstRadioChecked && budget) {
      // console.log("first one")
      setLoader(true);
      fetchData1();
      setErrorMessage("");
      setErrorMessageFlag(false);
    } else if (firstRadioChecked && !budget) {
      // console.log("second one")
      setErrorMessageFlag(true);
      setErrorMessage("Please enter fixed budget");
    } else if (secondRadioChecked && growth) {
      // console.log("third one")
      setLoader(true);
      const fetchData = async () => {
        const response = await axios.get(URL);
        setHierarchyData(response.data);
        // setSaveResponse(response.data);
        setLoader(false);
        setOptimize(true);
      };
      fetchData();
      fetchData1();
      setErrorMessageFlag(false);
      setErrorMessage("");
    } else if (secondRadioChecked && !growth) {
      // console.log("fourth one")
      setErrorMessageFlag(true);
      setErrorMessage("Please enter target growth");
    }
  };

  const clearForm = (e) => {
    e.preventDefault();
    setFormCleared(true);
    setBrand("");
    setKPI("");
    setTimeFrameValue("");
    setTimePeriodValue("");
    setSales("");
    setBudget("");
    setPercentageValue("");
    setGrowth("");
    setOptimize(false);
    setErrorMessageFlag(false);
    setErrorMessage("");
    setFirstRadioChecked(true);
    setSecondRadioChecked(false);
  };

  return (
    <Stack justifyContent={"space-between"} sx={{ height: "100%", width: "100%" }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 700,
          color: "themeColor",
          height: "5%",
        }}
      >
        Growth Projections
      </Typography>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ height: "94%", width: "100%" }}
      >
        {loader ? <Loader /> : null}
        {/* form */}
        <Box
          sx={{
            backgroundColor: "bgColor",
            borderRadius: "1rem",
            padding: "2rem",
            width: "59%",
            height: "100%",
          }}
        >
          <Box sx={{ height: "100%", width: "100%" }}>
            {/* section 1 */}
            <Stack height={"25%"} gap={"1rem"}>
              <Typography variant="h6" sx={{ color: "themeColor" }}>
                SELECT YOUR BRAND
              </Typography>

              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack sx={{ width: "49%" }} gap={"0.5rem"}>
                  <Typography variant="body1" sx={{ color: "white" }}>
                    Select Brand
                  </Typography>
                  <Select
                    className="input"
                    value={brand}
                    onChange={(e) => {
                      setBrand(e.target.value);
                      setTimeFrameValue("");
                      setTimePeriodValue("");
                    }}
                  >
                    {renderAllBrands()}
                  </Select>
                </Stack>

                <Stack sx={{ width: "49%" }} gap={"0.5rem"}>
                  <Typography variant="body1" sx={{ color: "white" }}>
                    Select KPI
                  </Typography>
                  <Select
                    className="input"
                    value={kpi}
                    onChange={(e) => {
                      setKPI(e.target.value);
                      setTimeFrameValue("");
                      setTimePeriodValue("");
                    }}
                  >
                    {renderAllKPIDisplayNames()}
                  </Select>
                </Stack>
              </Stack>
            </Stack>
            <Divider
              sx={{
                backgroundColor: "white",
              }}
            />

            {/* sectoin 2 */}
            <Stack height={"25%"} gap={"1rem"}>
              <Typography variant="h6" sx={{ color: "themeColor" }}>
                TIME PARAMETER
              </Typography>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack sx={{ width: "49%" }} gap={"0.5rem"}>
                  <Typography variant="body1" sx={{ color: "white" }}>
                    Time Frame
                  </Typography>
                  <Select
                    value={timeFrameValue}
                    onChange={handleFirstInputChange}
                  >
                    {timeFramesAndPeriod.map((option) => (
                      <Option key={option.key} value={option.key}>
                        {option.value}
                      </Option>
                    ))}
                  </Select>
                </Stack>

                <Stack sx={{ width: "49%" }} gap={"0.5rem"}>
                  <Typography variant="body1" sx={{ color: "white" }}>
                    Time Period
                  </Typography>
                  <Select
                    value={timePeriodValue}
                    onChange={handleSecondInputChange}
                  >
                    {timeFrameValue &&
                      timeFramesAndPeriod
                        .find((option) => option.key === timeFrameValue)
                        ?.dates.map((date) => (
                          <Option key={date.name} value={date.name}>
                            {date.name}
                          </Option>
                        ))}
                  </Select>
                </Stack>
              </Stack>
            </Stack>
            <Divider
              sx={{
                backgroundColor: "white",
              }}
            />
            {/* sectioin 3 */}
            <Stack justifyContent={"space-between"} height={"50%"}>
              <Stack justifyContent={"space-between"} height={"100%"}>
                <Typography variant="h6" sx={{ color: "themeColor" }}>
                  OPTIMISE FOR
                </Typography>
                {/* 2nd item */}
                <Stack
                  justifyContent={"space-between"}
                  width={"55%"}
                  direction={"row"}
                  alignItems={"baseline"}
                >
                  <Stack
                    width={"40%"}
                    direction={"row"}
                    gap={"1rem"}
                    alignItems={"center"}
                  >
                    <input
                      type="radio"
                      name="amount"
                      id="amount"
                      checked={firstRadioChecked}
                      onChange={() => {
                        setPercentageValue(null);
                        setGrowth(null);
                        firstRadioCheckedHandler();
                      }}
                    />
                    <Typography variant="h6" sx={{ color: "white" }}>
                      To Fixed Budget
                    </Typography>
                  </Stack>
                  <Stack
                    width={"60%"}
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                      border: "1px solid #3E5056",
                      borderRadius: "0.15rem",
                      backgroundColor: !firstRadioChecked && "#272d3d",
                    }}
                  >
                    <input
                      disabled={!firstRadioChecked}
                      value={budget}
                      onChange={(e) => {
                        // setBudget(e.target.value)
                        if (/^\d*\.?\d*$/.test(e.target.value)) {
                          setBudget(e.target.value);
                        }
                      }}
                      placeholder="Fixed Budget"
                      style={{
                        width: "60%",
                        backgroundColor: !firstRadioChecked
                          ? "#272d3d"
                          : "#1c2427",
                        border: "none",
                        outline: "none",
                        color: "white",
                        fontSize: "0.75rem",
                        padding: "0.15rem 0 0.15rem 0.5rem",
                      }}
                    />
                    <Typography
                      variant="h6"
                      sx={{ color: "gray", marginRight: "1rem" }}
                    >
                      {firstInputLabel}
                    </Typography>
                  </Stack>
                </Stack>

                {/* 3rd item */}
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack
                    justifyContent={"space-between"}
                    width={"55%"}
                    direction={"row"}
                  >
                    <Stack
                      width={"40%"}
                      direction={"row"}
                      gap={"1rem"}
                      alignItems={"center"}
                    >
                      <input
                        type="radio"
                        name="amount"
                        checked={secondRadioChecked}
                        onChange={secondRadioCheckedHandler}
                      />
                      <Typography variant="h6" sx={{ color: "white" }}>
                        Target Growth
                      </Typography>
                    </Stack>
                    <Stack width={"60%"} direction={"row"}>
                      <Stack
                        width={"100%"}
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                          border: "1px solid #3E5056",
                          borderRadius: "0.15rem",
                          backgroundColor: !secondRadioChecked && "#272d3d",
                        }}
                      >
                        <input
                          style={{
                            width: "60%",
                            // backgroundColor: "#1c2427",
                            backgroundColor: !secondRadioChecked
                              ? "#272d3d"
                              : "#1c2427",
                            border: "none",
                            outline: "none",
                            color: "white",
                            fontSize: "0.75rem",
                            padding: "0.15rem 0 0.15rem 0.5rem",
                          }}
                          disabled={!secondRadioChecked}
                          value={percentageValue || ""}
                          onChange={handlePercentage}
                          placeholder="Target Growth"
                        />
                        <Typography
                          variant="h6"
                          sx={{ color: "gray", marginRight: "1rem" }}
                        >
                          {"in %"}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    width={"45%"}
                    // sx={{border: "1px solid red"}}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: "themeColor", margin: "0 1rem" }}
                    >
                      OR
                    </Typography>
                    <Stack
                      width={"100%"}
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      sx={{
                        border: "1px solid #3E5056",
                        borderRadius: "0.15rem",
                        backgroundColor: !secondRadioChecked && "#272d3d",
                      }}
                    >
                      <input
                        width={"60%"}
                        style={{
                          width: "60%",
                          // backgroundColor: "#1c2427",
                          backgroundColor: !secondRadioChecked
                            ? "#272d3d"
                            : "#1c2427",
                          border: "none",
                          outline: "none",
                          color: "white",
                          fontSize: "0.75rem",
                          padding: "0.15rem 0 0.15rem 0.5rem",
                        }}
                        disabled={!secondRadioChecked}
                        value={growth || ""}
                        placeholder="Target Growth Absolute"
                        onChange={handlerGrowth}
                      />
                      <Typography
                        variant="h6"
                        sx={{ color: "gray", marginRight: "1rem" }}
                      >
                        {secondInputLabel}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>

                {errorMessageFlag && (
                  <Typography variant="h6" sx={{ color: "red" }}>
                    {errorMessage}
                  </Typography>
                )}
                {/* 4th item */}
                <Stack direction={"row"} justifyContent={"center"}>
                  <Stack direction={"row"} gap={"1rem"}>
                    <Button
                      sx={{
                        width: "20%",
                        margin: "auto",
                        padding: "0.5 1rem",
                        border: "1px solid #d6ff41",
                        color: "#d6ff41",
                        "&:hover": {
                          backgroundColor: "#d6ff41",
                          color: "black",
                        },
                        textTransform: "capitalize",
                        fontSize: ".75rem",
                      }}
                      onClick={optimizeHandler}
                    >
                      Optimise
                    </Button>
                    <Button
                      sx={{
                        width: "20%",
                        margin: "auto",
                        padding: "0.5 1rem",
                        border: "1px solid #d6ff41",
                        color: "#d6ff41",
                        "&:hover": {
                          backgroundColor: "#d6ff41",
                          color: "black",
                        },
                        textTransform: "capitalize",
                        fontSize: ".75rem",
                      }}
                      onClick={clearForm}
                    >
                      Clear
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Box>

        {/* charts and summary */}
        <Box sx={{ width: "40%", height: "100%" }}>
          {optimize ? (
            <Summary hierarchy={hierarchy} setDeepDive={setDeepDive} />
          ) : null}
        </Box>
      </Stack>
    </Stack>
  );
};

export default OptimizeForm;
